import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getFindId, getSendEmailToken } from "../common/Action";
import logoTop from "../images/logo-top.png";
import logoBottom from "../images/logo-bottom.png";
import { t } from "i18next";

const FindIdPw: React.FC = () => {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [shown, setShown] = useState("display-n");
  const [shown1, setShown1] = useState("display-n");
  const [findBtn, setFindbtn] = useState("");

  const handleFindId = () => {
    setShown("display-ib");
    setFindbtn("display-n");
  };

  const handleFindPw = () => {
    setShown1("display-ib");
    setFindbtn("display-n");
  };

  const handleFindIdNow = async (): Promise<void> => {
    const str =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    const result = await getFindId(email);
    if (str.test(email) && result) {
      // alert("입력하신 이메일 주소로 아이디를 전송했습니다.");
      alert(t(`We've sent your ID to the email address you entered`));
      history.push("/login");
    } else {
      alert("이메일을 확인해주세요.");
    }
  };

  const handleFindPwNow = async (): Promise<void> => {
    const str =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    const result = await getSendEmailToken(email);
    if (str.test(email) && result) {
      // alert("입력하신 이메일 주소로 비밀번호 변경 링크를 전송했습니다.");
      alert(t(`We've sent a link to change your password to...`));
      history.push("/login");
    } else {
      // alert("이메일을 확인해주세요.");
      alert(t(`Please check your email`));
    }
  };

  return (
    <>
      <div className="container text-center">
        <div className="font-60 mt-60 mb-63">
          <img src={logoTop} className="login_logo_top ml-7" alt="logoTop" />
          <br />
          <img src={logoBottom} className="login_logo_bottom ml-30" alt="logoBottom" />
        </div>
        <button onClick={handleFindId} className={`focus find__id ${findBtn} font-700 font-20`}>
          {/* 아이디 찾기 */}
          {t(`Forgot your ID`)}
        </button>
        <br />
        <br />
        <button onClick={handleFindPw} className={`focus find__pw ${findBtn} font-700 font-20`}>
          {/* 비밀번호 찾기 */}
          {t(`Forgot password`)}
        </button>
        <div className={`sign-up-bottom text-left ${shown}`}>
          <p className="margin-0 sign__up__text font-700 font-30">
            {/* 아이디 찾기 */}
            {t(`Forgot your ID`)}
          </p>
          {/* <p className="margin-0 font-500 font-15 mb-12">이름</p>
          <input
            onChange={(e) => {
              setName(e.target.value);
            }}
            className="mb-12 font-18 pl-36 id-input"
            placeholder="이름을 입력하세요."
            value={name}
          /> */}
          <p className="margin-0 font-500 font-15 mb-12">
            {/* 이메일 */}
            {t(`Email`)}
          </p>
          <input
            onChange={(e) => setEmail(e.target.value)}
            className="mb-12 font-18 pl-36 id-input"
            // placeholder="이메일을 입력하세요."
            placeholder={t(`Please enter your email`)}
          />
          <br />
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              onClick={handleFindIdNow}
              className="mb-26 line-54 font-16 font-500 check-button mr-40"
            >
              {/* 아이디 찾기 */}
              {t(`Forgot your ID`)}
            </button>
            <Link to="/login">
              <button className="mb-26 white line-54 font-16 font-500 cancel-button">
                {/* 취소 */}
                {t(`Cancel`)}
              </button>
            </Link>
          </div>
          <br />
          <br />
        </div>
      </div>
      <div className="container text-center">
        <div className={`sign-up-bottom text-left ${shown1}`}>
          <p className="margin-0 sign__up__text font-700 font-30">
            {/* 밀번호 찾기 */}
            {t(`Forgot password`)}
          </p>
          {/* <p className="margin-0 font-500 font-15 mb-12">이름</p> */}
          {/* <input
            onChange={(e) => setName1(e.target.value)}
            className="mb-12 font-18 pl-36 id-input"
            placeholder="이름을 입력하세요."
            value={name1}
          />
          <p className="margin-0 font-500 font-15 mb-12">아이디</p>
          <input
            onChange={(e) => setId(e.target.value)}
            className="mb-12 font-18 pl-36 pw-input"
            placeholder="아이디를 입력하세요."
            value={id}
          /> */}
          <p className="margin-0 font-500 font-15 mb-12">
            {/* 이메일 */}
            {t(`Email`)}
          </p>
          <input
            onChange={(e) => setEmail(e.target.value)}
            className="mb-12 font-18 pl-36 pw-input"
            // placeholder="이메일을 입력하세요."
            placeholder={t(`Please enter your email`)}
          />
          <br />
          <br />

          <button
            onClick={handleFindPwNow}
            className=" mb-26 line-54 font-16 font-500 check-button mr-40"
          >
            {/* 비밀번호 찾기 */}
            {t(`Forgot password`)}
          </button>
          <Link to="/login">
            <button className=" mb-26 white line-54 font-16 font-500 cancel-button">
              {/* 취소 */}
              {t(`Cancel`)}
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default FindIdPw;
