import React, { useState, useEffect, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import ReactQuill from "react-quill";
import down_b from "../images/down_b.png";
import close from "../images/close.png";
import { useParams } from "react-router";
import {
  getFoldersOfChecklist,
  getStudents,
  postUploadImage,
  // putModifyChecklist,
  putModifyChecklist2,
} from "../common/Action";
import { formatStyle, quillStyle } from "../common/QuillStyle";
import { t } from "i18next";

export const PopupQuill = (props: any) => {
  const params: any = useParams();
  const modalRef = useRef<HTMLDivElement>(null);
  const { openModal, setOpenModal } = props;

  const fileRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<any[]>([]);
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const [checklist, setChecklist] = useState<any>({});
  const [studentsList, setStudentsList] = useState<boolean>(false);
  const [studentAll, setStudentAll] = useState<any>([]);
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [popupFolderSelect, setPopupFolderSelect] = useState<any>({
    title: "폴더 미지정",
  });
  const [foldersData, setFoldersData] = useState<any>([]);

  useEffect(() => {
    init();
  }, []);

  const handleCloseModal = (e: any) => {
    if (!modalRef.current?.contains(e.target)) setOpenModal(false);
  };

  const init = async (): Promise<void> => {
    setChecklist(props.checklistInfo);
    let tempFiles = [];
    for (let i = 0; i < props.checklistInfo.file.length; i++) {
      tempFiles.push({ file: {}, fileUrl: props.checklistInfo.file[i], fromData: true });
    }
    setFiles(tempFiles);

    let studentAllResult: any = await getStudents(params.classId);
    const foldersOfChecklist: any = await getFoldersOfChecklist(params.classId);

    for (let i = 0; i < studentAllResult?.length; i++) {
      for (let k = 0; k < props.cos?.length; k++) {
        if (studentAllResult[i]?.code === props.cos[k]?.code) {
          studentAllResult[i].checklistCheck = !props.cos[k].hide || props.cos[k].check;
          studentAllResult[i].checklistStudentId = props.cos[k]._id;
        }
      }
    }

    if (foldersOfChecklist.length !== 0) {
      const targetFolder = foldersOfChecklist.filter(
        (folderItem: any) => folderItem._id === props.folderId,
      );
      if (targetFolder.length !== 0) {
        setPopupFolderSelect(targetFolder[0]);
      }

      setFoldersData(foldersOfChecklist);
    }
    setStudentAll(studentAllResult);
  };

  const handleFile = (e: any) => {
    const filesArr = Object.keys(e.target.files);
    if (filesArr.length !== 0) {
      let fileTemp = [...files];
      for (let i in filesArr) {
        if (e.target.files[i].type.split("/")[0] === "image") {
          fileTemp.push({
            file: e.target.files[i],
            fileUrl: URL.createObjectURL(e.target.files[i]),
          });
        } else {
          alert("이미지만 첨부해주세요!");
        }
      }
      setFiles(fileTemp);
    } else {
      return;
    }
  };

  const handleStudentChecked = (i: number): void => {
    const temp = [...studentAll];
    temp[i].checklistCheck = !temp[i].checklistCheck;
    setStudentAll(temp);
  };

  const handleStudentsAllCheck = (state: boolean) => {
    const temp = [...studentAll];
    for (let i in temp) {
      temp[i].checklistCheck = state;
    }
    setStudentAll(temp);
  };

  const handleSubmit = async (): Promise<void> => {
    let tempUrls: string[] = [];
    if (files.length !== 0) {
      for (let i in files) {
        if (files[i].fromData) {
          tempUrls.push(files[i].fileUrl);
        } else {
          const formData = new FormData();
          formData.append("file", files[i].file);
          const getUrls: any = await postUploadImage(formData, 500);
          tempUrls.push(getUrls);
        }
      }
    }

    let tempCode = [];
    for (let i in studentAll) {
      if (studentAll[i].checklistCheck) {
        tempCode.push(studentAll[i].code);
      }
    }
    const data = {
      classId: checklist.classId,
      onFolder: popupFolderSelect.title !== "폴더 미지정" ? true : false,
      folderId: popupFolderSelect._id ? popupFolderSelect._id : "",
      id: checklist._id,
      title: checklist.title,
      description: checklist.description,
      file: tempUrls,
      studentCodeList: tempCode,
    };

    const putResult = await putModifyChecklist2(data);

    setOpenModal(false);
    props.init();
  };

  const handleDeleteFile = (targetFile: object) => {
    let temp = [...files];
    const findIdx = temp.findIndex((el: any) => el === targetFile);
    temp.splice(findIdx, 1);
    setFiles(temp);
  };

  return (
    <>
      {openModal && (
        <div className="popup">
          <div className="modal-overlay" onClick={(e) => handleCloseModal(e)} />
          <div ref={modalRef} className="popup-modal-wide scroll">
            <div>
              <p className="font-23 mb-26 text-center">항목 수정</p>
              <p className="color-light-black mb-8 font-15">{t(`Title`)}</p>

              <div className="display-f align-center mb-22">
                <input
                  value={checklist?.title}
                  onChange={(e) => {
                    setChecklist((prev: any) => {
                      return {
                        ...prev,
                        title: e.target.value,
                      };
                    });
                  }}
                  className="popup_input mr-18 pl-12 pr-12"
                  type="text"
                  maxLength={31}
                />
              </div>

              <div className="mb-22">
                <p className="color-light-black mb-8 font-15">폴더</p>
                {/* folder selection */}
                <div
                  onClick={() => setOpenSelection(true)}
                  style={{ alignItems: "center", marginTop: 10 }}
                  className="display-f"
                >
                  <div className="repeat-selectbar cursor">
                    <p
                      className={`${popupFolderSelect?.title === "폴더 미지정" ? "font-gray" : ""}`}
                    >
                      {popupFolderSelect?.title}
                    </p>

                    <img src={down_b} style={{ width: 18, height: "auto" }} />
                  </div>
                </div>

                {openSelection && (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setOpenSelection(false);
                    }}
                  >
                    <div className="repeat-selectbar-open" style={{ marginLeft: 0 }}>
                      <div
                        onClick={() => {
                          setPopupFolderSelect({ title: "폴더 미지정" });
                          setOpenSelection(false);
                        }}
                        className="repeat-select-item-n cursor font-gray"
                      >
                        폴더 미지정
                      </div>
                      {foldersData?.map((folder: any, i: number) => (
                        <div
                          key={i}
                          onClick={() => {
                            setPopupFolderSelect(folder);
                            setOpenSelection(false);
                          }}
                          className="repeat-select-item-n cursor"
                        >
                          {folder.title}
                        </div>
                      ))}
                    </div>
                  </OutsideClickHandler>
                )}
              </div>

              <p className="color-light-black font-15 mb-8">
                {/* 내용 */}
                {t(`Detail`)}
              </p>
              <ReactQuill
                formats={formatStyle}
                value={checklist.description || ""}
                onChange={(e) => {
                  setChecklist((prev: any) => {
                    return {
                      ...prev,
                      description: e,
                    };
                  });
                }}
                className="project-quill"
                modules={quillStyle}
              />
              <div className="display-f" style={{ width: "100%", flexWrap: "wrap" }}>
                {files.map((file: any, index: number) => (
                  <div className="position-r" style={{ width: "48%", marginRight: 10 }} key={index}>
                    <img
                      onClick={() => handleDeleteFile(file)}
                      src={close}
                      style={{ width: 15, height: "auto", top: 10, left: 10 }}
                      className="position-a cursor"
                    />
                    <img src={file.fileUrl} className="img-add mb-10" alt="file" />
                  </div>
                ))}
              </div>
              <div className="popup__additional__project mb-30">
                <input
                  ref={fileRef}
                  type="file"
                  className="display-n"
                  name="file"
                  multiple
                  onChange={(e: any) => handleFile(e)}
                />
                <button
                  onClick={() => {
                    fileRef.current !== null && fileRef.current.click();
                  }}
                  className="focus add__file__btn font-13 font-500 mr-11 mt-10"
                >
                  {t(`Attach`)}
                </button>

                <button
                  onClick={() => {
                    setFiles([]);
                    setFileUrls([]);
                  }}
                  className="focus add__file__btn font-13 font-500 mr-11 mt-10"
                >
                  {/* 파일 삭제 */}
                  {t(`Delete files`)}
                </button>

                <button
                  onClick={() => setStudentsList(true)}
                  className="focus target__student__btn font-13 font-500"
                >{`${t(`Select Recipient`)} (${
                  studentAll?.filter((item: any) => item.checklistCheck).length
                })`}</button>

                {studentsList === true && (
                  <div className="studentsList__popup">
                    <div className="studentsList__contents">
                      <div className="overflow-h scroll mb-30">
                        <p className="font-23 text-center mb-20 pt-20">대상학생 목록</p>
                        {studentAll
                          .sort((a: any, b: any) => a.num - b.num)
                          .map((data: any, index: number) => (
                            <div key={index} className="managing__options__contents position-r">
                              <p className="mt-15 mb-15 font-15">{data.name}</p>
                              <input
                                onChange={() => handleStudentChecked(index)}
                                type="checkbox"
                                id={JSON.stringify(index)}
                                className="display-n"
                              />
                              <div>
                                <label
                                  className="target_label cursor"
                                  htmlFor={JSON.stringify(index)}
                                >
                                  {data.checklistCheck && (
                                    <span className="studentList_check"></span>
                                  )}
                                </label>
                              </div>
                            </div>
                          ))}
                        <div>
                          <div className="text-center mt-20 mb-20 pb-20">
                            <button
                              onClick={() => handleStudentsAllCheck(true)}
                              className="focus popup__bottom__btn mr-14"
                            >
                              {t(`Select All`)}
                            </button>
                            <button
                              onClick={() => handleStudentsAllCheck(false)}
                              className="focus popup__bottom__btn mr-14"
                            >
                              모두해제
                            </button>
                            <button
                              onClick={() => setStudentsList(false)}
                              className="focus popup__bottom__btn mr-14"
                            >
                              {t(`Confirm`)}
                            </button>
                            <button
                              onClick={() => setStudentsList(false)}
                              className="focus popup__bottom__btn"
                            >
                              {t(`Cancel`)}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="text-center mb-20">
                <button onClick={handleSubmit} className="focus popup__bottom__btn mr-14">
                  {t(`Confirm`)}
                </button>
                <button onClick={() => setOpenModal(false)} className="focus popup__bottom__btn">
                  {t(`Cancel`)}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupQuill;
