import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import google_logo from "../images/google-logo.png";
import { getUserFromGoogle, loginGoogle } from "../common/Action";
import { useContext } from "react";
import { IMainContext } from "../interface/Interface";
import { MainContext } from "../common/Context";
import { useHistory } from "react-router-dom";
import { t } from "i18next";

const GoogleLoginButton = () => {
  const clientId = "86825325373-8hddcbciuslllb8iq4ghtqtujnd4dcnl.apps.googleusercontent.com";

  return (
    <>
      <GoogleOAuthProvider clientId={clientId}>
        {/* <GoogleLogin
          shape="rectangular"
          onSuccess={(res) => {
            console.log(res);
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        /> */}

        <CustomButton />
      </GoogleOAuthProvider>
    </>
  );
};

export default GoogleLoginButton;

const CustomButton = (): any => {
  const context = useContext<IMainContext>(MainContext);
  const history = useHistory();
  const Login = useGoogleLogin({
    onSuccess: async (tokenRes: any) => {
      console.log("tokenRes", tokenRes);
      const result: any = await loginGoogle({ token: tokenRes.access_token });

      console.log(result);
      if (result.message === "exist email") {
        return alert(
          "이 메일 주소로 이미 다했니 회원가입이 되어 있습니다. 아이디 비번 찾기를 하고, 상단의 아이디/비번 입력 방식으로 로그인하세요.",
        );
      }
      context.handleStateChange("isUser", true);
      localStorage.setItem("token", result);
      context.handleGetUserInfo();
      history.push("/");

      //   googleLogin(tokenRes.access_token);
    },
    onError: () => {
      console.log("login Failed");
    },
  });

  //   const googleLogin = async (accessToken: string) => {
  //     const data: any = await getUserFromGoogle(accessToken);
  //     console.log(data);
  //   };

  return (
    <button className="google-login-btn" onClick={() => Login()}>
      <div className="display-f align-center" style={{ justifyContent: "space-between" }}>
        <img src={google_logo} style={{ width: 18, height: "auto" }} />
        {/* 구글 로그인 */}
        <p className="font-500"> {t(`Google Login`)}</p>
        <div></div>
      </div>
    </button>
  );
};
