// import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
// import logo from "../images/logo-top.png";
// import { useHistory, useParams } from "react-router-dom";
// import {
//   getPaymentDetailId,
//   getPortonePayment,
//   postAddSerialCode,
//   postEmail,
//   postEmailAdmin,
//   postPaidAfterPrivatePurchase,
//   putPayment,
// } from "../common/Action";
// import { currency, generateRandomString, timeFormat11, timeformatKR } from "../common/Method";
// import { IMainContext } from "../interface/Interface";
// import { MainContext } from "../common/Context";
// import moment from "moment";
// import { t } from "i18next";

// const Buy = () => {
//   const params: any = useParams();
//   const history = useHistory();
//   const [payData, setPayData] = useState<any>({});
//   const [payStatus, setPayStatus] = useState<string>("pending");
//   const [receiptUrl, setReceiptUrl] = useState<string>("");

//   useLayoutEffect(() => {
//     init();
//   }, []);

//   const init = async () => {
//     const { data }: any = await getPaymentDetailId({
//       id: params.trackingid,
//       uid: params.uid,
//     });

//     if (!data) {
//       // alert("유효하지 않은 결제입니다.");
//       alert(t(`This payment is not valid`));
//       history.go(-1);
//       return;
//     }
//     if (data.state === "pending") setPayStatus("pending");
//     if (data.state === "completed") {
//       setPayStatus("completed");
//       setReceiptUrl(data.receipt_url);
//     }

//     setPayData(data);
//   };

//   async function onClickPayment() {
//     // /* 1. 가맹점 식별하기 */
//     const { IMP }: any = window;
//     IMP.init("imp41427313");
//     /* 2. 결제 데이터 정의하기 */
//     const data = {
//       pg: "nice_v2.IM0017205m", // PG사
//       pay_method: "card", // 결제수단
//       merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
//       // amount: 1000,
//       amount: payData.totalAmount, // 결제금액
//       name: `다했니 무제한 이용권(2024학년도 특별가)`, // 주문명
//       buyer_name: `${payData.type === "1인 이용권" ? payData.payerName : payData.team}`, // 구매자 이름
//       buyer_tel: `${payData.leaderPhone}`, // 구매자 전화번호
//       buyer_email: `${payData.payerEmail}`, // 구매자 이메일
//       // m_redirect_url: `https://version9-1.d3evdb8segvma7.amplifyapp.com/buy/${params.trackingid}/${params.uid}`,
//       m_redirect_url: `https://dahandin.com/buy/${params.trackingid}/${params.uid}`,
//       // buyer_addr: "신사동 661-16", // 구매자 주소
//       // buyer_postcode: "06018", // 구매자 우편번호
//       notice_url: `https://api.dahandin.com/api/buy?trackingid=${params.trackingid}&uid=${params.uid}`,
//       popup: true,
//     };
//     /* 4. 결제 창 호출하기 */
//     IMP.request_pay(data, callback);
//   }

//   /* 3. 콜백 함수 정의하기 */
//   const callback = async (response: any) => {
//     // 웹훅으로 변경하기 (response가 들어오지 않는 상황에 대비)
//     // 1. 휍훅으로 상태값이 body.status==="paid" || body.status==="cancelled" 들어가면 쿼리에 trakingid와 uid로 우리쪽 payments data 조회, body.imp_uid로 포트원 결제내역 조회
//     // 2. 우리쪽 디비에 payresult.response 업데이트
//     // 3. payData.type==="학교 단체 이용권" || payData.type==="개인 이용권" 나눠서 처리해야함....
//     // 4. 백엔드에서 status ---> paid 주면 데이터상태값 바꾸기
//     // 5. body.status==="cancelled" -> 취소의 경우 body.imp_uid로 찾아서 업데이트... 하면 될까...?

//     const payResult: any = await getPortonePayment(response.imp_uid);

//     if (!response.error_code && !response.error_msg) {
//       setPayStatus("completed");
//       setReceiptUrl(payResult.response.receipt_url);
//       const generateSerialCode = generateRandomString();
//       await putPayment({
//         id: payData._id,
//         uid: payData.uid,
//         // 취소시 최초결제를 구분하기 위해 필드 추가
//         paidAt: payResult.response.paid_at,
//         ...payResult.response,
//         state: "completed",
//         startingDate: Date.now(),
//         isSerialCode: payData.type === "학교 단체 이용권" ? true : false,
//         isPersonal: payData.type === "학교 단체 이용권" ? false : true,
//         serialCode: payData.type === "학교 단체 이용권" ? generateSerialCode : null,
//       });
//       if (payData.type === "학교 단체 이용권") {
//         // 시리얼 코드 생성
//         await handleAddSerialCode(generateSerialCode);
//       } else {
//         // 개인 결제시 바로 등록되는 userInfo 변경 API
//         postPaidAfterPrivatePurchase({ userId: payData.userId });
//         // alert("결제가 완료되었습니다. 영수증을 확인해 주세요.");
//         alert(t(`Your payment has been completed Please check the receipt`));
//       }
//     } else {
//       // alert(`결제실패: ${response.error_code}: ${response.error_msg}`);
//       alert(`${t(`Payment failed`)}: ${response.error_code}: ${response.error_msg}`);
//     }
//   };

//   const handleAddSerialCode = async (code: string): Promise<void> => {
//     const dueDate = payData.dueDate ? payData.dueDate : timeformatKR("2025-02-28") + 86400000 - 1;
//     const submitForm = {
//       startingDate: Date.now(),
//       dueDate: dueDate,
//       lead: payData.lead,
//       leaderPhone: payData.leaderPhone.replace(/-/gi, ""),
//       team: payData.team,
//       fileSizeLimit: payData.fileSizeLimit ? payData.fileSizeLimit : 999 * 1000000000,
//       count: payData.count,
//       serialCode: code,
//       admin: false,
//       payerName: payData.payerName,
//       payerEmail: payData.payerEmail,
//       // payerEmail: "edujiwon@naver.com",
//     };

//     const result: any = await postAddSerialCode({
//       ...submitForm,
//     });

//     // 메일전송...
//     // if (result) {
//     // TODO:: 고유번역
//     if (localStorage.getItem("language") === "ko") {
//       // 한국어
//       const emailResult: any = await postEmailAdmin({
//         email: submitForm.payerEmail,
//         subject: `[다했니] ${submitForm.team} 시리얼넘버 안내`,
//         html: `<div style="padding: 10px 10px 20px 10px; border: 1px solid #979797; width:600px"><p style="background-color:black; font-size:12px; color:blue;">* 발신 전용 메일주소입니다. 문의는 edujiwon@naver.com</p><h3 style="color: black;">귀 교의 시리얼 넘버는 아래와 같습니다.</h3><p style="font-size:20px; color:blue; font-weight:600;">${
//           submitForm.serialCode
//         }</p><p>이 시리얼 넘버를 선생님들께 안내드려서 개별 다했니 창에 입력하도록 안내해주세요.</p><p>${
//           submitForm.count
//         }인 결제하였으므로,</p><p>${
//           parseInt(submitForm.count) + 1
//         }번째 계정에 위 시리얼넘버를 입력할 시, 만료되어 적용이 불가합니다.</p><p>따라서, 위 시리얼 넘버를 타인에게 공유하지 않도록 유의하여 주세요.
//         </p><br/><p style="color: red;">시리얼 넘버가 적용되지 않는다면 아래로 연락주세요.</p><p style="color: red;">010-4663-4063</p><p style="color: red;">edujiwon@naver.com</p><img src="https://dahandin.com/static/media/qr_pay.80559297c858970b9e6a.jpeg" style="width: 120px; height: auto;"/>`,
//       });
//     } else {
//       // 영어번역본
//       const emailResult: any = await postEmailAdmin({
//         email: submitForm.payerEmail,
//         subject: `[다했니] ${submitForm.team} Serial Number Guide`,
//         html: `<div style="padding: 10px 10px 20px 10px; border: 1px solid #979797; width:600px"><p style="background-color:black; font-size:12px; color:blue;">* This is an outgoing-only email address. Questions can be directed to edujiwon@naver.com</p><h3 style="color: black;">Your school's serial number is shown below.</h3><p style="font-size:20px; color:blue; font-weight:600;">${
//           submitForm.serialCode
//         }</p><p>Give these serial numbers to your teachers to enter into their own apps.</p><p>
//         Since you paid for ${
//           submitForm.count
//         } people,</p><p>if you enter the above serial number in the${
//           parseInt(submitForm.count) + 1
//         }th account,it will expire and cannot be applied.</p><p>Therefore, please be careful not to share the above serial number with others.
//         </p><br/><p style="color: red;">If the serial number does not apply, please contact me below.</p><p style="color: red;">010-4663-4063</p><p style="color: red;">edujiwon@naver.com</p><img src="https://dahandin.com/static/media/qr_pay.80559297c858970b9e6a.jpeg" style="width: 120px; height: auto;"/>`,
//       });
//     }

//     // console.log(emailResult);
//     // alert("결제가 완료되었습니다. 영수증을 확인해 주세요.");
//     // alert("해당 메일로 시리얼코드가 발송되었습니다.");
//     alert(t(`Your payment has been completed Please check the receipt`));
//     alert(t(`A serial code has been sent to that email`));
//     // }
//   };

//   return (
//     <>
//       <div className="buy-container">
//         <div className="display-f align-center justify-center">
//           <img src={logo} alt="main-logo" style={{ width: 120, height: "auto" }} />
//         </div>

//         {payStatus === "pending" && (
//           <h3 style={{ margin: 0 }}>
//             {/* 결제 대기 */}
//             {t(`Waiting for payment`)}
//           </h3>
//         )}
//         {payStatus === "completed" && (
//           <h3 className="color-red" style={{ margin: 0 }}>
//             {/* 결제 완료 */}
//             {t(`Payment completed`)}
//           </h3>
//         )}
//         <h2 className="margin-0">
//           {/* 상품명: 다했니 무제한 이용권 (2024학년도 특별가) */}
//           {t(`Product Name Unlimited ticket...`)}
//         </h2>

//         <div className="mt-20 mb-20">
//           <p className="font-16">
//             {/* 업무 담당자: {payData.lead} */}
//             {t(`A person in charge of business`)}: {payData.lead}
//           </p>
//           <p className="font-16 mt-4">
//             {/* 결제자: {payData.payerName} */}
//             {t(`Payer`)}: {payData.payerName}
//           </p>
//         </div>

//         {!payData.fromAdmin && (
//           <p className="font-16">
//             {/* 이용 기간: (결제일) ~ 2025. 02. 28. */}
//             {t(`Term of use...`)} ~ 2025. 02. 28.
//           </p>
//         )}
//         {payData.fromAdmin && (
//           <p className="font-16">
//             {/* 이용 기간: (결제일) ~{moment(payData.dueDate).format("YYYY년 MM월 DD일")} */}
//             {t(`Term of use...`)} ~{moment(payData.dueDate).format("YYYY년 MM월 DD일")}
//           </p>
//         )}
//         {/* {moment(context.userInfo.paidInfo.dueDate).format("YYYY년 MM월 DD일 HH:mm:ss")} */}
//         {!payData.fromAdmin && (
//           <p className="font-16 mt-8">
//             {/* 결제 금액(단가):{" "} */}
//             {t(`Payment amount unit price`)}:{" "}
//             <span className="font-600 font-18">
//               {payData.type === "1인 이용권" ? "99,000" : "66,000"} 원
//             </span>
//             <span className="font-14">
//               {/* (부가세 포함) */}({t(`VAT included`)})
//             </span>
//           </p>
//         )}
//         <p className="font-16 mt-8">
//           {/* 상품수량: <span className="font-600">{parseInt(payData.count)}개</span> */}
//           {t(`Product quantity`)}:{" "}
//           <span className="font-600">
//             {parseInt(payData.count)}
//             {/*  TODO:: 고유번역 */}
//             {localStorage.getItem("language") === "ko" ? "개" : ""}
//           </span>
//         </p>
//         <p className="font-16 mt-8">
//           {/* 총 결제 금액:{" "} */}
//           {t(`Total payment amount`)}:{" "}
//           <span className="font-600 font-18 color-red">{currency(payData.totalAmount)}원</span>
//           <span className="font-14">
//             {/* (부가세 포함) */}
//             {t(`VAT included`)}
//           </span>
//         </p>

//         <div className="text-center">
//           {payStatus === "pending" && (
//             <button onClick={onClickPayment} className="mt-20 btn-buy">
//               {/* 카드결제 */}
//               {t(`Pay by card`)}
//             </button>
//           )}
//           {payStatus === "completed" && (
//             <div style={{ width: "100%" }} className="btn-buy mt-20">
//               <a href={receiptUrl} target={"_blank"} className="cursor">
//                 {/* 영수증 보기 */}
//                 {t(`Viewing receipts`)}
//               </a>
//             </div>
//           )}
//         </div>
//       </div>
//       <div className="buy-container-m text-center">
//         <h2>
//           {/* PC에서 진행해 주세요. */}
//           {t(`Please do this on your PC`)}
//         </h2>
//       </div>
//     </>
//   );
// };

// export default Buy;

import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import logo from "../images/logo-top.png";
import { useHistory, useParams } from "react-router-dom";
import {
  getPaymentDetailId,
  getPortonePayment,
  postAddSerialCode,
  postEmail,
  postEmailAdmin,
  postPaidAfterPrivatePurchase,
  putPayment,
} from "../common/Action";
import { currency, generateRandomString, timeFormat11, timeformatKR } from "../common/Method";
import { IMainContext } from "../interface/Interface";
import { MainContext } from "../common/Context";
import moment from "moment";
import { t } from "i18next";

const Buy = () => {
  const params: any = useParams();
  const history = useHistory();
  const [payData, setPayData] = useState<any>({});
  const [payStatus, setPayStatus] = useState<string>("pending");
  const [receiptUrl, setReceiptUrl] = useState<string>("");

  useLayoutEffect(() => {
    init();
  }, []);

  const init = async () => {
    const { data }: any = await getPaymentDetailId({
      id: params.trackingid,
      uid: params.uid,
    });

    if (!data) {
      // alert("유효하지 않은 결제입니다.");
      alert(t(`This payment is not valid`));
      history.go(-1);
      return;
    }
    if (data.state === "pending") setPayStatus("pending");
    if (data.state === "completed") {
      setPayStatus("completed");
      setReceiptUrl(data.receipt_url);
    }

    setPayData(data);
  };

  async function onClickPayment() {
    // /* 1. 가맹점 식별하기 */
    const { IMP }: any = window;
    IMP.init("imp41427313");
    /* 2. 결제 데이터 정의하기 */
    const data = {
      pg: "nice_v2.IM0017205m", // PG사
      pay_method: "card", // 결제수단
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      // amount: 1000,
      amount: payData.totalAmount, // 결제금액
      name: `다했니 무제한 이용권(2024학년도 특별가)`, // 주문명
      buyer_name: `${payData.type === "1인 이용권" ? payData.payerName : payData.team}`, // 구매자 이름
      buyer_tel: `${payData.leaderPhone}`, // 구매자 전화번호
      buyer_email: `${payData.payerEmail}`, // 구매자 이메일
      // m_redirect_url: `https://version9-1.d3evdb8segvma7.amplifyapp.com/buy/${params.trackingid}/${params.uid}`,
      m_redirect_url: `https://dahandin.com/buy/${params.trackingid}/${params.uid}`,
      // buyer_addr: "신사동 661-16", // 구매자 주소
      // buyer_postcode: "06018", // 구매자 우편번호
      notice_url: `https://api.dahandin.com/api/buy?trackingid=${params.trackingid}&uid=${params.uid}`,
      popup: true,
    };
    /* 4. 결제 창 호출하기 */
    IMP.request_pay(data, callback);
  }

  /* 3. 콜백 함수 정의하기 */
  const callback = async (response: any) => {
    // 웹훅으로 변경하기 (response가 들어오지 않는 상황에 대비)
    // 1. 휍훅으로 상태값이 body.status==="paid" || body.status==="cancelled" 들어가면 쿼리에 trakingid와 uid로 우리쪽 payments data 조회, body.imp_uid로 포트원 결제내역 조회
    // 2. 우리쪽 디비에 payresult.response 업데이트
    // 3. payData.type==="학교 단체 이용권" || payData.type==="개인 이용권" 나눠서 처리해야함....
    // 4. 백엔드에서 status ---> paid 주면 데이터상태값 바꾸기
    // 5. body.status==="cancelled" -> 취소의 경우 body.imp_uid로 찾아서 업데이트... 하면 될까...?

    const payResult: any = await getPortonePayment(response.imp_uid);

    if (!response.error_code && !response.error_msg) {
      setPayStatus("completed");
      setReceiptUrl(payResult.response.receipt_url);
      const generateSerialCode = generateRandomString();
      await putPayment({
        id: payData._id,
        uid: payData.uid,
        // 취소시 최초결제를 구분하기 위해 필드 추가
        paidAt: payResult.response.paid_at,
        ...payResult.response,
        state: "completed",
        startingDate: Date.now(),
        isSerialCode: payData.type === "학교 단체 이용권" ? true : false,
        isPersonal: payData.type === "학교 단체 이용권" ? false : true,
        serialCode: payData.type === "학교 단체 이용권" ? generateSerialCode : null,
      });
      if (payData.type === "학교 단체 이용권") {
        // 시리얼 코드 생성
        await handleAddSerialCode(generateSerialCode);
      } else {
        // 개인 결제시 바로 등록되는 userInfo 변경 API
        postPaidAfterPrivatePurchase({ userId: payData.userId });
        // alert("결제가 완료되었습니다. 영수증을 확인해 주세요.");
        alert(t(`Your payment has been completed Please check the receipt`));
      }
    } else {
      // alert(`결제실패: ${response.error_code}: ${response.error_msg}`);
      alert(`${t(`Payment failed`)}: ${response.error_code}: ${response.error_msg}`);
    }
  };

  const handleAddSerialCode = async (code: string): Promise<void> => {
    const dueDate = payData.dueDate ? payData.dueDate : timeformatKR("2025-02-28") + 86400000 - 1;
    const submitForm = {
      startingDate: Date.now(),
      dueDate: dueDate,
      lead: payData.lead,
      leaderPhone: payData.leaderPhone.replace(/-/gi, ""),
      team: payData.team,
      fileSizeLimit: payData.fileSizeLimit ? payData.fileSizeLimit : 999 * 1000000000,
      count: payData.count,
      serialCode: code,
      admin: false,
      payerName: payData.payerName,
      payerEmail: payData.payerEmail,
      // payerEmail: "edujiwon@naver.com",
    };

    const result: any = await postAddSerialCode({
      ...submitForm,
    });

    // 메일전송...
    // if (result) {
    // TODO:: 고유번역
    if (localStorage.getItem("language") === "ko") {
      // 한국어
      const emailResult: any = await postEmailAdmin({
        email: submitForm.payerEmail,
        subject: `[다했니] ${submitForm.team} 시리얼넘버 안내`,
        html: `<div style="padding: 10px 10px 20px 10px; border: 1px solid #979797; width:600px"><p style="background-color:black; font-size:12px; color:blue;">* 발신 전용 메일주소입니다. 문의는 edujiwon@naver.com</p><h3 style="color: black;">귀 교의 시리얼 넘버는 아래와 같습니다.</h3><p style="font-size:20px; color:blue; font-weight:600;">${
          submitForm.serialCode
        }</p><p>이 시리얼 넘버를 선생님들께 안내드려서 개별 다했니 창에 입력하도록 안내해주세요.</p><p>${
          submitForm.count
        }인 결제하였으므로,</p><p>${
          parseInt(submitForm.count) + 1
        }번째 계정에 위 시리얼넘버를 입력할 시, 만료되어 적용이 불가합니다.</p><p>따라서, 위 시리얼 넘버를 타인에게 공유하지 않도록 유의하여 주세요.
        </p><br/><p style="color: red;">시리얼 넘버가 적용되지 않는다면 아래로 연락주세요.</p><p style="color: red;">010-4663-4063</p><p style="color: red;">edujiwon@naver.com</p><img src="https://dahandin.com/static/media/qr_pay.80559297c858970b9e6a.jpeg" style="width: 120px; height: auto;"/>`,
      });
    } else {
      // 영어번역본
      const emailResult: any = await postEmailAdmin({
        email: submitForm.payerEmail,
        subject: `[다했니] ${submitForm.team} Serial Number Guide`,
        html: `<div style="padding: 10px 10px 20px 10px; border: 1px solid #979797; width:600px"><p style="background-color:black; font-size:12px; color:blue;">* This is an outgoing-only email address. Questions can be directed to edujiwon@naver.com</p><h3 style="color: black;">Your school's serial number is shown below.</h3><p style="font-size:20px; color:blue; font-weight:600;">${
          submitForm.serialCode
        }</p><p>Give these serial numbers to your teachers to enter into their own apps.</p><p>
        Since you paid for ${
          submitForm.count
        } people,</p><p>if you enter the above serial number in the${
          parseInt(submitForm.count) + 1
        }th account,it will expire and cannot be applied.</p><p>Therefore, please be careful not to share the above serial number with others.
        </p><br/><p style="color: red;">If the serial number does not apply, please contact me below.</p><p style="color: red;">010-4663-4063</p><p style="color: red;">edujiwon@naver.com</p><img src="https://dahandin.com/static/media/qr_pay.80559297c858970b9e6a.jpeg" style="width: 120px; height: auto;"/>`,
      });
    }

    // console.log(emailResult);
    // alert("결제가 완료되었습니다. 영수증을 확인해 주세요.");
    // alert("해당 메일로 시리얼코드가 발송되었습니다.");
    alert(t(`Your payment has been completed Please check the receipt`));
    alert(t(`A serial code has been sent to that email`));
    // }
  };

  return (
    <>
      <div className="buy-container">
        <div className="display-f align-center justify-center">
          <img src={logo} alt="main-logo" style={{ width: 120, height: "auto" }} />
        </div>

        {payStatus === "pending" && (
          <h3 style={{ margin: 0 }}>
            {/* 결제 대기 */}
            {t(`Waiting for payment`)}
          </h3>
        )}
        {payStatus === "completed" && (
          <h3 className="color-red" style={{ margin: 0 }}>
            {/* 결제 완료 */}
            {t(`Payment completed`)}
          </h3>
        )}
        <h2 className="margin-0">
          {/* 상품명: 다했니 무제한 이용권 (2024학년도 특별가) */}
          {t(`Product Name Unlimited ticket...`)}
        </h2>

        <div className="mt-20 mb-20">
          <p className="font-16">
            {/* 업무 담당자: {payData.lead} */}
            {t(`A person in charge of business`)}: {payData.lead}
          </p>
          <p className="font-16 mt-4">
            {/* 결제자: {payData.payerName} */}
            {t(`Payer`)}: {payData.payerName}
          </p>
        </div>

        {!payData.fromAdmin && (
          <p className="font-16">
            {/* 이용 기간: (결제일) ~ 2025. 02. 28. */}
            {t(`Term of use...`)} ~ 2025. 02. 28.
          </p>
        )}
        {payData.fromAdmin && (
          <p className="font-16">
            {/* 이용 기간: (결제일) ~{moment(payData.dueDate).format("YYYY년 MM월 DD일")} */}
            {t(`Term of use...`)} ~{moment(payData.dueDate).format("YYYY년 MM월 DD일")}
          </p>
        )}
        {/* {moment(context.userInfo.paidInfo.dueDate).format("YYYY년 MM월 DD일 HH:mm:ss")} */}
        {!payData.fromAdmin && (
          <p className="font-16 mt-8">
            {/* 결제 금액(단가):{" "} */}
            {t(`Payment amount unit price`)}:{" "}
            <span className="font-600 font-18">
              {payData.type === "1인 이용권" ? "49500" : "33,000"} 원
            </span>
            <span className="font-14">
              {/* (부가세 포함) */}({t(`VAT included`)})
            </span>
          </p>
        )}
        <p className="font-16 mt-8">
          {/* 상품수량: <span className="font-600">{parseInt(payData.count)}개</span> */}
          {t(`Product quantity`)}:{" "}
          <span className="font-600">
            {parseInt(payData.count)}
            {/*  TODO:: 고유번역 */}
            {localStorage.getItem("language") === "ko" ? "개" : ""}
          </span>
        </p>
        <p className="font-16 mt-8">
          {/* 총 결제 금액:{" "} */}
          {t(`Total payment amount`)}:{" "}
          <span className="font-600 font-18 color-red">{currency(payData.totalAmount)}원</span>
          <span className="font-14">
            {/* (부가세 포함) */}
            {t(`VAT included`)}
          </span>
        </p>

        <div className="text-center">
          {payStatus === "pending" && (
            <button onClick={onClickPayment} className="mt-20 btn-buy">
              {/* 카드결제 */}
              {t(`Pay by card`)}
            </button>
          )}
          {payStatus === "completed" && (
            <div style={{ width: "100%" }} className="btn-buy mt-20">
              <a href={receiptUrl} target={"_blank"} className="cursor">
                {/* 영수증 보기 */}
                {t(`Viewing receipts`)}
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="buy-container-m text-center">
        <h2>
          {/* PC에서 진행해 주세요. */}
          {t(`Please do this on your PC`)}
        </h2>
      </div>
    </>
  );
};

export default Buy;
