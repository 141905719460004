import React, { useContext, useEffect, useState } from "react";
import PopupAlbum from "../component/PopupAlbum";
import { Link, Route, useParams, useHistory, useLocation } from "react-router-dom";
import {
  getByToken,
  getEachProjectInfo,
  getProject,
  postNotification,
  postUpdate,
  putConfirmProject,
  putRejectProject,
  getProjectOfStudents,
  postNotificationAll,
} from "../common/Action";
import lock from "../images/lock-b.png";
import cookie1G from "../images/cookie1G.png";
import cookie2G from "../images/cookie2G.png";
import cookie3G from "../images/cookie3G.png";
import cookie1S from "../images/cookie1S.png";
import cookie2S from "../images/cookie2S.png";
import cookie3S from "../images/cookie3S.png";
import arrow from "../images/arrow-left.png";
import file_icon from "../images/file_icon.png";
import moment from "moment";
import { consoleLog, time1, timeFormat11 } from "../common/Method";
import { IMainContext } from "../interface/Interface";
import { MainContext } from "../common/Context";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import HiddenProjectView from "../component/HiddenProjectView";
import { t } from "i18next";

export default function RepeatDetail(): JSX.Element {
  const context = useContext<IMainContext>(MainContext);
  const params: any = useParams();
  const history: any = useHistory();
  const location: any = useLocation();
  const [repeatData, setRepeatData] = useState<any>({});
  const [confirmProjectPopup, setConfirmProjectPopup] = useState<boolean>(false);
  const [confirmType, setConfirmType] = useState<string>("cookie");
  const [cookieSelected, setCookieSelected] = useState<number>(1);
  const [feedback, setFeedback] = useState<string>("");
  const [score, setScore] = useState<string>("");
  const [targetIdx, setTargetIdx] = useState<number>(-1);
  const [rejectReason, setRejectReason] = useState<string>("");
  const [album, setAlbum] = useState<boolean>(false);
  const [albumUrl, setAlbumUrl] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [studentName, setStudentName] = useState<string>("");
  const [projectTitle, setProjectTitle] = useState<string>("");
  const [fileTest, setFileTest] = useState<any[]>([]);
  const [resourceLoading, setResourceLoading] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(false);
  const [albumData, setAlbumData] = useState<any>({});
  const [fileData, setFileData] = useState<any>([]);
  const [hiddenPopup, setHiddenPopup] = useState<boolean>(false);
  const [projectOfStudentData, setProjectOfStudentData] = useState<any>({});

  // 과제 일괄 검사 btn
  const [confirmBulk, setConfirmBulk] = useState<boolean>(false);
  const [bulkInfo, setBulkInfo] = useState<any>({
    cookie: 0,
    score: "",
    feedback: "",
  });
  const [studentAllCheck, setStudentAllCheck] = useState<boolean>(false);

  useEffect(() => {
    init();
    // console.log(repeatData);
    // console.log(params);
  }, []);

  const init = async (): Promise<void> => {
    if (location.pathname.includes("hiddens")) setHidden(true);
    const result: any = await getEachProjectInfo(params.posId);
    const getProjectResult: any = await getProject(params.id);
    setProjectTitle(getProjectResult?.title);

    if (result.repeatType === "date") {
      result.repeatData.sort((a: any, b: any) => a.alertDate - b.alertDate);
    }

    setStudentName(result?.studentInfo[0]?.name);
    setRepeatData(result);

    let temp: any = [];
    for (let i = 0; i < result.repeatData.length; i++) {
      for (let k = 0; k < result.repeatData[i].fileUrls.length; k++) {
        temp.push(result.repeatData[i].fileUrls[k].uri);
      }
    }
    setFileTest(temp);
  };

  const handleDownloadAll = async () => {
    return alert("이 기능은 현재 점검중입니다. 자료를 하나씩 다운받아주세요.");
    if (resourceLoading) return;
    if (fileTest.length === 0) return alert("다운받을 파일이 없습니다.");

    setResourceLoading(true);
    const fileResult = await convertURLtoFile(fileTest);
    downloadZip(fileResult);
  };

  const downloadZip = (fileResult: any) => {
    let zip = new JSZip();
    let folder: any = zip.folder(`${studentName}`);

    for (let i = 0; i < fileResult.length; i++) {
      folder.file(fileResult[i].name, fileResult[i]);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${studentName}.zip`);
    });

    setResourceLoading(false);
  };

  const convertURLtoFile = async (urlArr: string[]) => {
    let fileArray = [];
    const fetchPromise = [];
    const dataPromise = [];

    for (let i = 0; i < urlArr.length; i++) {
      fetchPromise.push(fetch(urlArr[i]));
    }

    const fetchPromiseArr = await Promise.all(fetchPromise);
    for (let i = 0; i < fetchPromiseArr.length; i++) {
      dataPromise.push(fetchPromiseArr[i].blob());
    }
    const dataPromiseArr = await Promise.all(dataPromise);

    for (let i = 0; i < urlArr.length; i++) {
      const ext = urlArr[i].split(".").pop(); // urlArr[i] 구조에 맞게 수정할 것
      const filename = urlArr[i].split("/").pop(); // urlArr[i] 구조에 맞게 수정할 것
      const metadata = { type: `image/${ext}` };
      fileArray.push(new File([dataPromiseArr[i]], filename!, metadata));
    }

    return fileArray;
  };

  const handleClickOutside = (e: any): void => {
    setConfirmProjectPopup(false);
  };

  const handleConfirmProjectType = (num: number) => {
    if (num === 1) {
      setConfirmType("cookie");
      setCookieSelected(1);
    } else if (num === 2) {
      setConfirmType("score");
    } else if (num === 3) {
      setConfirmType("reject");
    }
  };

  const handleConfirmProject = async (): Promise<void> => {
    if (hidden) return alert("숨김상태에서는 과제의 열람만 가능합니다.");
    context.handleStateChange("class", "");
    let cookie = 0;
    let tempScore = "";
    if (confirmType === "cookie" && cookieSelected !== 0) cookie = cookieSelected;
    if (confirmType === "score" && score !== "") tempScore = score;
    if (confirmType === "reject") {
      const confirm = window.confirm(
        `반려하면 학생의 자료가 삭제되며, 복구가 되지 않습니다. 진행하시겠습니까?`,
      );
      if (!confirm) return;
      await putRejectProject({
        id: repeatData._id,
        repeat: true,
        repeatType: repeatData.repeatType,
        targetIndex: targetIdx,
        rejectReason: rejectReason === "" ? "_" : rejectReason,
        targetDate: repeatData.repeatData[targetIdx].alertDate,
      });

      let body = {
        to: repeatData.studentInfo[0].deviceToken2,
        title: "과제가 반려되었습니다. 📝",
        body: "다시 보내주세요.",
        data: {
          code: repeatData.studentInfo[0].code,
          type: "project",
        },
      };

      postNotification(body);
      alert("과제가 반려되었습니다.");
    } else {
      const result: any = await putConfirmProject({
        id: repeatData._id,
        repeat: true,
        repeatType: repeatData.repeatType,
        targetIndex: targetIdx,
        confirmType: confirmType,
        confirmCookies: cookie,
        confirmScore: tempScore,
        feedback: feedback,
        targetDate: repeatData.repeatData[targetIdx].alertDate,
      });

      if (result.constructor == Array) {
        let pushArr: any = [];
        result.map((pushItem: any, i: number) => {
          pushArr.push({
            token: repeatData.studentInfo[0].deviceToken2,
            notification: {
              title: `🎖${pushItem.title} 뱃지🎖`,
              body: `축하합니다! 확인해보세요!`,
            },
            data: {
              code: repeatData.studentInfo[0].code,
              type: "project",
            },
          });
        });

        postNotificationAll(pushArr);
      }

      let body = {
        to: repeatData.studentInfo[0].deviceToken2,
        title: confirmType === "cookie" ? "쿠키 도착 🍪" : "과제 확인 📝",
        body:
          confirmType === "cookie"
            ? "쿠키가 도착했어요! 확인해보세요!"
            : "과제를 확인하셨습니다! 확인해보세요!",
        data: {
          code: repeatData.studentInfo[0]?.code,
          type: "project",
        },
      };
      postNotification(body);
    }
    setConfirmProjectPopup(false);
    setConfirmType("cookie");
    setFeedback("");
    setScore("");
    init();
  };

  const handlePostPush = async (): Promise<void> => {
    const confirm = window.confirm(`${studentName} 학생에게 푸쉬 알람을 보냅니다`);

    if (confirm) {
      let body = {
        to: repeatData.studentInfo[0].deviceToken2,
        title: `${repeatData.title}릃 하세요!`,
        body: "아직 안 한 과제가 있어요!",
        data: {
          code: repeatData.studentInfo[0].code,
          type: "project",
        },
      };
      await postNotification(body);
      alert("푸쉬 알람을 보냈습니다.");
    } else {
      return;
    }
  };

  const handleAlbum = (data: any): void => {
    setAlbumData(data);
    setAlbum((prev) => !prev);
    if (!data.isVideo) {
      setAlbumUrl(data.uri);
      setVideoUrl("");
    } else {
      setVideoUrl(videoUrl === "" ? data.uri : "");
      setAlbumUrl("");
    }
  };

  const handleCheckPos = (e: any, data: any) => {
    e.stopPropagation();
    let fileLimit = 0;
    if (context.userInfo.fileSizeLimit === 0 || !context.userInfo.fileSizeLimit) {
      fileLimit = 100000000;
    } else {
      fileLimit = context.userInfo?.fileSizeLimit;
    }
    if (fileLimit < context.fileSize.originalFileSize) {
      return alert(
        "제한 용량을 초과하셨습니다. '과제 삭제' 또는 '학급 삭제'를 통해 저장 용량을 조절하시거나, '결제 관리' 페이지에서 저장 용량 업그레이드 후 이용해 주세요.",
      );
    }

    let tempData = [...repeatData.repeatData];
    const findIdx = tempData.findIndex((el) => el === data);

    tempData[findIdx].checked = !tempData[findIdx].checked;
    setRepeatData({ ...repeatData, repeatData: tempData });
  };

  const handleStudentAllCheck = () => {
    let temp = [...repeatData.repeatData];

    for (let i in temp) {
      temp[i].checked = studentAllCheck ? false : true;
    }

    setRepeatData({ ...repeatData, repeatData: temp });
    setStudentAllCheck((prev: boolean) => !prev);
  };

  const onObserverAllCheck = () => {
    let allCheck = true;
    let checkCount = 0;
    let openLength = 0;

    const repeatDataTemp = repeatData.repeatData;
    for (let i in repeatDataTemp) {
      if (repeatDataTemp[i].open) openLength++;
    }
    for (let i in repeatDataTemp) {
      if (repeatDataTemp[i].open && repeatDataTemp[i].checked) checkCount++;
    }

    if (checkCount === openLength) allCheck = true;
    else allCheck = false;

    return allCheck;
  };

  const handleChangeBulkInfo = (type: string, value: string | number) => {
    if (type == "cookie" && value !== 0 && value === bulkInfo.cookie) {
      setBulkInfo((prev: any) => {
        return {
          ...prev,
          cookie: 0,
        };
      });
    } else {
      setBulkInfo((prev: any) => {
        return {
          ...prev,
          [type]: value,
        };
      });
    }
  };

  const handleConfirmPosBulk = async (): Promise<void> => {
    let cookie = 0;
    let tempScore = "";
    // if (bulkInfo.cookie !== 0 && bulkInfo.score !== "")
    //   return alert("과제 확인시 쿠키나 성적 중 한 가지만 선택 가능합니다.");
    if (bulkInfo.cookie !== 0) cookie = bulkInfo.cookie;
    if (bulkInfo.score !== "") tempScore = bulkInfo.score;

    let tempRepeatProject = repeatData?.repeatData?.filter((el: any) => el.checked && el.open);
    if (tempRepeatProject.length === 0) return alert("선택된  과제가 없습니다.");

    for (let i = 0; i < repeatData?.repeatData?.length; i++) {
      if (repeatData.repeatData[i]?.checked && repeatData.repeatData[i].open) {
        const confirmResult: any = await putConfirmProject({
          id: repeatData._id,
          repeat: true,
          repeatType: repeatData.repeatType,
          targetIndex: i,
          confirmType: bulkInfo.cookie !== 0 ? "cookie" : "score",
          confirmCookies: cookie,
          confirmScore: tempScore,
          feedback: bulkInfo.feedback,
          targetDate: repeatData.repeatData[i].alertDate,
        });

        if (confirmResult.constructor == Array) {
          let pushArr: any = [];
          confirmResult.map((pushItem: any, i: number) => {
            pushArr.push({
              token: repeatData.studentInfo[0].deviceToken2,
              // to: "ExponentPushToken[AyHls_EO3cbqCORV1ffmCV]",
              notification: {
                title: `🎖${pushItem.title} 뱃지🎖`,
                body: `축하합니다! 확인해보세요!`,
              },
              data: {
                code: repeatData.studentInfo[0].code,
                type: "project",
              },
            });
          });
          postNotificationAll(pushArr);
        }

        let body = {
          to: repeatData.studentInfo[0].deviceToken2,
          title: confirmType === "cookie" ? "쿠키 도착 🍪" : "과제 확인 📝",
          body:
            confirmType === "cookie"
              ? "쿠키가 도착했어요! 확인해보세요!"
              : "과제를 확인하셨습니다! 확인해보세요!",
          data: {
            code: repeatData.studentInfo[0].code,
            type: "project",
          },
        };

        postNotification(body);
      }
    }

    context.handleStateChange("easyConfirmPos", false);
    context.handleStateChange("class", "");
    setConfirmBulk(false);
    setBulkInfo({
      cookie: 0,
      score: "",
      feedback: "",
    });

    init();
  };

  const handleCancelConfirmBulk = () => {
    context.handleStateChange("easyConfirmPos", false);

    let temp = [...repeatData.repeatData];
    for (let i in temp) {
      temp[i].checked = false;
    }

    setBulkInfo({
      cookie: 0,
      score: "",
      feedback: "",
    });
    setConfirmBulk(false);
    setRepeatData({ ...repeatData, repeatData: temp });
  };

  return (
    <>
      <div className="col-md-12 category_container">
        {album && (
          <PopupAlbum
            enable={album}
            albumData={albumData}
            setAlbumData={setAlbumData}
            fileData={repeatData?.repeatData[targetIdx]?.fileUrls}
            handleAlbum={handleAlbum}
            setAlbumUrl={setAlbumUrl}
            setVideoUrl={setVideoUrl}
            albumUrl={albumUrl}
            videoUrl={videoUrl}
          />
        )}
        <div className="display-f justify-between align-center mb-33 pl-10 pr-10">
          <div className="display-f align-center jusify-center">
            <img
              className="cursor"
              onClick={() => history.goBack()}
              src={arrow}
              style={{ width: 40, marginRight: 15 }}
            />
            <p className="font-23 font-700">
              <span>{projectTitle}</span> - <span style={{ fontSize: 18 }}>({studentName})</span>
            </p>
          </div>
          <div>
            {/* <button className="project_control_btn mr-28">모든 자료 다운받기</button> */}
          </div>

          {!hidden && !confirmBulk && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                onClick={() => {
                  setConfirmBulk(true);
                  context.handleStateChange("easyConfirmPos", true);
                }}
                className="push_all_btn mr-10 border-red text-red font-500 bg-white"
              >
                일괄 검사
              </button>
              <button
                onClick={handlePostPush}
                className="push_all_btn font-500"
                style={{ marginRight: 10 }}
              >
                push 보내기
              </button>
              <button
                onClick={handleDownloadAll}
                className="project_control_btn font-500"
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                {!resourceLoading && "모든 자료 다운받기"}
                {resourceLoading && <div className="spinner3"></div>}
              </button>
            </div>
          )}
        </div>
        {/* 과제 일괄 확인의 경우 */}
        {confirmBulk && (
          <div style={{ marginTop: -30 }}>
            <div className="display-f flex-1 mb-10 align-center">
              <div className="flex-1 display-f align-center">
                <div className="display-f align-center position-r">
                  <div onClick={handleStudentAllCheck} className="check cursor mr-5">
                    {onObserverAllCheck() && (
                      <span
                        className="check__inside position-a bg-checked"
                        style={{ left: 5, top: 5 }}
                      />
                    )}
                  </div>
                </div>
                <p onClick={handleStudentAllCheck} className="white cursor">
                  전체 학생 선택
                </p>
              </div>
              <div className="flex-2">
                <div className="display-f align-center justify-center">
                  <>
                    <button
                      onClick={() => handleChangeBulkInfo("cookie", 1)}
                      className={`${
                        bulkInfo.cookie !== 1 && "cookie_border_none"
                      } cookie_circle cursor display-f-center mr-17`}
                    >
                      {bulkInfo.cookie === 1 ? (
                        <img className="cookie1" src={cookie1S} alt="cookie1S" />
                      ) : (
                        <img className="cookie1" src={cookie1G} alt="cookie1G" />
                      )}
                    </button>
                    <button
                      onClick={() => handleChangeBulkInfo("cookie", 2)}
                      className={`${
                        bulkInfo.cookie !== 2 && "cookie_border_none"
                      } cookie_circle cursor display-f-center mr-17`}
                    >
                      {bulkInfo.cookie === 2 ? (
                        <img className="cookie2" src={cookie2S} alt="cookie2S" />
                      ) : (
                        <img className="cookie2" src={cookie2G} alt="cookie2G" />
                      )}
                    </button>
                    <button
                      onClick={() => handleChangeBulkInfo("cookie", 3)}
                      className={`${
                        bulkInfo.cookie !== 3 && "cookie_border_none"
                      } cookie_circle cursor display-f-center mr-17`}
                    >
                      {bulkInfo.cookie === 3 ? (
                        <img className="cookie3" src={cookie3S} alt="cookie3S" />
                      ) : (
                        <img className="cookie3" src={cookie3G} alt="cookie3G" />
                      )}
                    </button>

                    <div className="mr-20 display-f align-center">
                      <h2>또는</h2>
                      <input
                        onChange={(e: any) => handleChangeBulkInfo("score", e.target.value)}
                        value={bulkInfo.score}
                        placeholder="성적 입력"
                        className="add_cookie_input ml-10"
                        style={{ width: 130 }}
                      />
                    </div>
                  </>
                </div>
              </div>
              <div className="flex-1 display-f justify-end">
                <button
                  onClick={handleConfirmPosBulk}
                  style={{ width: 80, height: 30, backgroundColor: "#fff" }}
                  className="display-ib mr-10 select_btn text-red border-red font-500"
                >
                  {t(`Save`)}
                </button>
                <button
                  className="select_btn display-ib"
                  style={{ width: 80, height: 30 }}
                  onClick={handleCancelConfirmBulk}
                >
                  {t(`Cancel`)}
                </button>
              </div>
            </div>
            <div className="mb-20">
              <input
                onChange={(e: any) => handleChangeBulkInfo("feedback", e.target.value)}
                value={bulkInfo.feedback}
                className="easy_check_cookie text-center"
                placeholder={t(`Please briefly note`)}
              />
            </div>
          </div>
        )}

        {repeatData?.repeatData?.map((item: any, index: number) => {
          // 미제출, 반려
          if (
            (item.open && item.state === "") ||
            (item.open && !item.state) ||
            (item.open && item.state === "reject")
          ) {
            return (
              <div key={index} className="display-ib pl-10 pr-10 position-r">
                <div
                  onClick={(e: any) => {
                    if (hidden) {
                      let fileLimit = 0;
                      if (context.userInfo.fileSizeLimit === 0 || !context.userInfo.fileSizeLimit) {
                        fileLimit = 100000000;
                      } else {
                        fileLimit = context.userInfo?.fileSizeLimit;
                      }
                      if (fileLimit < context.fileSize.originalFileSize) {
                        return alert(
                          "제한 용량을 초과하셨습니다. '과제 삭제' 또는 '학급 삭제'를 통해 저장 용량을 조절하시거나, '결제 관리' 페이지에서 저장 용량 업그레이드 후 이용해 주세요.",
                        );
                      }
                      // setConfirmProjectPopup(true);
                      setHiddenPopup(true);
                      setTargetIdx(index);
                      setFeedback(item?.feedback);
                      setProjectOfStudentData(item);
                      setRejectReason(item?.reject === "_" ? "" : item.reject);

                      if (item.state === "" && item.reject !== "") {
                        setConfirmType("reject");
                        setRejectReason(item.reject === "_" ? "" : item.reject);
                      }
                    }
                    if (confirmBulk) {
                      handleCheckPos(e, item);
                    }
                  }}
                  className="card2 project_inner_card background-yellow direction-column cursor"
                  style={{ justifyContent: "space-between" }}
                >
                  {repeatData.repeatType === "count" && (
                    <p className="font-18 color-dark font-700">{index + 1}</p>
                  )}
                  {repeatData.repeatType === "date" && (
                    <p className="font-16 color-dark font-700">{time1(item.alertDate).slice(2)}</p>
                  )}
                  {item.submissionTime && (
                    <p className="font-12 text-center">{timeFormat11(item.submissionTime)}</p>
                  )}
                  {confirmBulk && (
                    <div
                      onClick={(e: any) => handleCheckPos(e, item)}
                      className="position-a check cursor"
                    >
                      {item.checked && <span className="check__inside position-a bg-checked" />}
                    </div>
                  )}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      // margin: repeatData.repeatType === "date" && !hidden ? "0 0 -8px 0" : "",
                    }}
                  >
                    <p className={`unsubmit-text`}>
                      {/* 미제출 */}
                      {t(`Unsubmitted`)}
                    </p>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {!hidden && !confirmBulk && (
                      <button
                        onClick={(e: any) => {
                          let fileLimit = 0;
                          if (
                            context.userInfo.fileSizeLimit === 0 ||
                            !context.userInfo.fileSizeLimit
                          ) {
                            fileLimit = 100000000;
                          } else {
                            fileLimit = context.userInfo?.fileSizeLimit;
                          }
                          if (fileLimit < context.fileSize.originalFileSize) {
                            return alert(
                              "제한 용량을 초과하셨습니다. '과제 삭제' 또는 '학급 삭제'를 통해 저장 용량을 조절하시거나, '결제 관리' 페이지에서 저장 용량 업그레이드 후 이용해 주세요.",
                            );
                          }

                          if (confirmBulk) {
                            return handleCheckPos(e, item);
                          }

                          setConfirmProjectPopup(true);
                          setTargetIdx(index);
                          setFeedback(item?.feedback);
                          setRejectReason(item?.reject === "_" ? "" : item.reject);

                          if (item.state === "" && item.reject !== "") {
                            setConfirmType("reject");
                            setRejectReason(item.reject === "_" ? "" : item.reject);
                          }
                        }}
                        className="card_check_btn font-10"
                      >
                        {/* 낸것으로 하기 */}
                        {t(`Checked that it was paid`)}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          }
          // 확인중
          if (item.open && item.state === "check") {
            return (
              <div
                key={index}
                className="display-ib pl-10 pr-10 position-r"
                style={{ verticalAlign: "top" }}
              >
                <button
                  onClick={(e: any) => {
                    // setConfirmProjectPopup(true);
                    let fileLimit = 0;
                    if (context.userInfo.fileSizeLimit === 0 || !context.userInfo.fileSizeLimit) {
                      fileLimit = 100000000;
                    } else {
                      fileLimit = context.userInfo?.fileSizeLimit;
                    }
                    if (fileLimit < context.fileSize.originalFileSize) {
                      return alert(
                        "제한 용량을 초과하셨습니다. '과제 삭제' 또는 '학급 삭제'를 통해 저장 용량을 조절하시거나, '결제 관리' 페이지에서 저장 용량 업그레이드 후 이용해 주세요.",
                      );
                    }

                    if (confirmBulk) {
                      return handleCheckPos(e, item);
                    }

                    if (hidden) {
                      setHiddenPopup(true);
                      setProjectOfStudentData(item);
                    } else {
                      setConfirmProjectPopup(true);
                    }
                    setTargetIdx(index);
                    setFeedback(item.feedback);
                    setRejectReason(item.reject);
                  }}
                  className="card2 project_inner_card3 background-red direction-column cursor state_check_wrapper"
                >
                  {repeatData.repeatType === "count" && (
                    <p className="font-16 color-dark font-700">{index + 1}</p>
                  )}
                  {repeatData.repeatType === "date" && (
                    <p className="font-16 color-dark font-700">{time1(item.alertDate).slice(2)}</p>
                  )}
                  {confirmBulk && (
                    <div
                      onClick={(e: any) => handleCheckPos(e, item)}
                      className="position-a check cursor"
                    >
                      {item.checked && <span className="check__inside position-a bg-checked" />}
                    </div>
                  )}

                  <div
                    style={{
                      width: "100%",
                      margin: repeatData.repeatType === "date" ? "0 0 -8px 0" : "0 0",
                    }}
                  >
                    {item.submissionTime && (
                      <div style={{ width: "100%" }}>
                        <p className="font-12 text-center">{timeFormat11(item.submissionTime)}</p>
                      </div>
                    )}
                    <p
                      className={`unsubmit-text color-red ${
                        repeatData.repeatType === "date" ? "mb-5" : ""
                      } ${repeatData.repeatType === "date" && "unsubmit-repeat-date"}`}
                    >
                      확인전
                    </p>
                  </div>

                  {!confirmBulk && (
                    <div className="state_check_btn_wrapper">
                      <button
                        onClick={(e: any) => {
                          let fileLimit = 0;
                          if (
                            context.userInfo.fileSizeLimit === 0 ||
                            !context.userInfo.fileSizeLimit
                          ) {
                            fileLimit = 100000000;
                          } else {
                            fileLimit = context.userInfo?.fileSizeLimit;
                          }
                          if (fileLimit < context.fileSize.originalFileSize) {
                            return alert(
                              "제한 용량을 초과하셨습니다. '과제 삭제' 또는 '학급 삭제'를 통해 저장 용량을 조절하시거나, '결제 관리' 페이지에서 저장 용량 업그레이드 후 이용해 주세요.",
                            );
                          }

                          if (hidden) {
                            setHiddenPopup(true);
                            setProjectOfStudentData(item);
                          } else {
                            setConfirmProjectPopup(true);
                          }
                          setTargetIdx(index);
                          setFeedback(item.feedback);
                          setRejectReason(item.reject);
                        }}
                        className="card_check_btn font-10"
                      >
                        확인하기
                      </button>
                    </div>
                  )}
                </button>
              </div>
            );
          }
          // 확인 완료 쿠키지급
          if (item.open && item.state === "complete" && item.cookie !== 0) {
            return (
              <div
                onClick={(e: any) => {
                  let fileLimit = 0;
                  if (context.userInfo.fileSizeLimit === 0 || !context.userInfo.fileSizeLimit) {
                    fileLimit = 100000000;
                  } else {
                    fileLimit = context.userInfo?.fileSizeLimit;
                  }
                  if (fileLimit < context.fileSize.originalFileSize) {
                    return alert(
                      "제한 용량을 초과하셨습니다. '과제 삭제' 또는 '학급 삭제'를 통해 저장 용량을 조절하시거나, '결제 관리' 페이지에서 저장 용량 업그레이드 후 이용해 주세요.",
                    );
                  }

                  if (confirmBulk) {
                    return handleCheckPos(e, item);
                  }

                  if (hidden) {
                    setHiddenPopup(true);
                    setProjectOfStudentData(item);
                  } else {
                    setConfirmProjectPopup(true);
                  }
                  setTargetIdx(index);
                  setConfirmType("cookie");
                  setCookieSelected(item.cookie);
                  setFeedback(item.feedback);
                  setRejectReason(item.reject);
                }}
                key={index}
                className="display-ib pl-10 pr-10 position-r"
              >
                <div className="card2 project_inner_card background-gray direction-column cursor">
                  {repeatData.repeatType === "count" && (
                    <p className="font-20 color-dark font-700">{index + 1}</p>
                  )}
                  {repeatData.repeatType === "date" && (
                    <p className="font-16 color-dark font-700">{time1(item.alertDate).slice(2)}</p>
                  )}
                  {confirmBulk && (
                    <div
                      onClick={(e: any) => handleCheckPos(e, item)}
                      className="position-a check cursor"
                    >
                      {item.checked && <span className="check__inside position-a bg-checked" />}
                    </div>
                  )}

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {item.cookie === 1 && (
                      <img src={cookie1S} style={{ width: 52, height: "auto" }} />
                    )}
                    {item.cookie === 2 && (
                      <img src={cookie2S} style={{ width: 52, height: "auto" }} />
                    )}
                    {item.cookie === 3 && (
                      <img src={cookie3S} style={{ width: 52, height: "auto" }} />
                    )}
                  </div>

                  <p className="font-12 text-center">
                    {item.submissionTime && timeFormat11(item.submissionTime)}
                  </p>
                </div>
              </div>
            );
          }
          // 확인 완료 성적
          if (
            item.open &&
            item.state === "complete" &&
            ((item.cookie === 0 && item.score !== "") || (item.cookie === 0 && item.score === ""))
          ) {
            return (
              <div
                onClick={(e: any) => {
                  let fileLimit = 0;
                  if (context.userInfo.fileSizeLimit === 0 || !context.userInfo.fileSizeLimit) {
                    fileLimit = 100000000;
                  } else {
                    fileLimit = context.userInfo?.fileSizeLimit;
                  }
                  if (fileLimit < context.fileSize.originalFileSize) {
                    return alert(
                      "제한 용량을 초과하셨습니다. '과제 삭제' 또는 '학급 삭제'를 통해 저장 용량을 조절하시거나, '결제 관리' 페이지에서 저장 용량 업그레이드 후 이용해 주세요.",
                    );
                  }

                  if (confirmBulk) {
                    return handleCheckPos(e, item);
                  }

                  setTargetIdx(index);
                  setConfirmType("score");
                  // setConfirmProjectPopup(true);
                  if (hidden) {
                    setHiddenPopup(true);
                    setProjectOfStudentData(item);
                  } else {
                    setConfirmProjectPopup(true);
                  }
                  setScore(item.score);
                  setFeedback(item.feedback);
                  setRejectReason(item.reject);
                }}
                key={index}
                className="display-ib pl-10 pr-10 position-r"
              >
                <div className="card2 project_inner_card background-gray direction-column cursor">
                  {repeatData.repeatType === "count" && (
                    <p className="font-20 color-dark font-700">{index + 1}</p>
                  )}
                  {repeatData.repeatType === "date" && (
                    <p className="font-16 color-dark font-700">{time1(item.alertDate).slice(2)}</p>
                  )}
                  {confirmBulk && (
                    <div
                      onClick={(e: any) => handleCheckPos(e, item)}
                      className="position-a check cursor"
                    >
                      {item.checked && <span className="check__inside position-a bg-checked" />}
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                    }}
                  >
                    <p className="color-red font-30 font-700">{item.score}</p>
                  </div>

                  <p className="font-12 text-center">
                    {item.submissionTime && timeFormat11(item.submissionTime)}
                  </p>
                </div>
              </div>
            );
          }

          if (!item.open) {
            return (
              <div key={index} className="display-ib pl-10 pr-10 position-r">
                <div className="card2 project_inner_card background-yellow direction-column cursor">
                  {repeatData.repeatType === "count" && (
                    <p className="font-16 color-dark font-700">{index + 1}</p>
                  )}
                  {repeatData.repeatType === "date" && (
                    <p className="font-16 color-dark font-700">{time1(item.alertDate).slice(2)}</p>
                  )}
                  {/* <p>{new Date().getTime() > item.alertDate}</p> */}
                  {/* <p>{item.alertDate}</p> */}
                  <div
                    className={
                      repeatData.repeatType === "count" ? "repeat-lock" : "repeat-lock-date"
                    }
                  >
                    <img src={lock} className="img-lock" />
                    {/* <p>{JSON.stringify(item)}</p> */}
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>

      {/*숨긴과제 읽기전용 팝업 */}
      {hiddenPopup && (
        <HiddenProjectView
          rejectReason={rejectReason}
          score={score}
          feedback={feedback}
          cookieSelected={cookieSelected}
          confirmType={confirmType}
          handleAlbum={handleAlbum}
          handleClickOutside={() => setHiddenPopup(false)}
          projectOfStudentData={projectOfStudentData}
        />
      )}

      {/* confirm project popup */}
      {confirmProjectPopup && (
        <div className="popup">
          <div className="modal-overlay" onClick={(e: any) => handleClickOutside(e)}></div>
          <div className="projectOfStudentPopup scroll color-light-black">
            <p className="font-20 color-light-black mb-10 text-center">{repeatData.name}</p>

            <div>
              {repeatData?.repeatData[targetIdx]?.fileUrls?.map((aFile: any, index: number) => (
                <>
                  {(aFile.fileType === "image" ||
                    aFile.fileType === "video" ||
                    !aFile.fileType) && (
                    <div
                      key={index}
                      style={{
                        width: "49%",
                        verticalAlign: "top",
                        display: "inline-block",
                        marginRight: index % 2 === 0 ? 10 : 0,
                        marginBottom: 10,
                      }}
                    >
                      {!aFile.isVideo && (
                        <img
                          className="cursor"
                          onClick={() => handleAlbum(aFile)}
                          src={aFile.uri}
                          style={{ width: "100%", height: "auto", verticalAlign: "top" }}
                        />
                      )}
                      {aFile.isVideo && (
                        <video
                          controls
                          style={{ verticalAlign: "top" }}
                          onClick={() => handleAlbum(aFile)}
                          src={aFile.uri}
                          className="background-img student_submitted_image mb-10 cursor"
                          autoPlay={true}
                        ></video>
                      )}
                      {/* <p className="txt_line">{aFile.fileName}</p> */}
                      <p
                        onClick={() => window.open(aFile.uri)}
                        className="download_text cursor"
                        style={{ textAlign: "center" }}
                      >
                        다운받기
                      </p>
                    </div>
                  )}
                </>
              ))}
              {repeatData?.repeatData[targetIdx]?.fileUrls?.filter(
                (el: any, i: number) =>
                  el.fileType !== "image" && el.fileType !== "video" && el.fileType,
              ).length !== 0 && (
                <div style={{ marginBottom: 10 }}>
                  <p style={{ fontWeight: 500, marginBottom: 10 }}>
                    첨부파일
                    <span style={{ fontSize: 12 }}>(아래 파일명을 클릭하면 다운로드됩니다.)</span>
                  </p>
                </div>
              )}

              {repeatData?.repeatData[targetIdx]?.fileUrls?.map((aFile: any, index: number) => (
                <>
                  {aFile.fileType !== "image" && aFile.fileType !== "video" && aFile.fileType && (
                    <div
                      onClick={() => window.open(aFile.uri)}
                      key={index}
                      className="file_ex_container cursor"
                    >
                      <img src={file_icon} style={{ width: 25, height: "auto", marginRight: 5 }} />
                      <p>{aFile.fileName}</p>
                    </div>
                  )}
                </>
              ))}
            </div>

            {/* <div className="display-f align-center flex-wrap">
              {repeatData?.repeatData[targetIdx]?.fileUrls?.map((data: any, index: number) => (
                <div
                  key={index}
                  className="display-f download_item mb-10 align-center"
                  style={{
                    width: "49%",
                    marginRight: index % 2 === 1 ? 0 : 5,
                    marginLeft: index % 2 === 0 ? 0 : 5,
                    flexDirection: "column",
                  }}
                >
                  <div>
                    {data.isVideo ? (
                      <>
                        <video
                          controls
                          onClick={() => handleAlbum(data)}
                          src={data.uri}
                          className="background-img student_submitted_image mb-10 cursor"
                          autoPlay={true}
                        ></video>
                      </>
                    ) : (
                      <div
                        onClick={() => handleAlbum(data)}
                        // style={{ backgroundImage: `url(${data.uri})` }}
                        className="mb-10 cursor"
                      >
                        <img src={data.uri} alt="studentImg" className="img-response" />
                      </div>
                    )}
                  </div>

                  <p onClick={() => window.open(data.uri)} className="download_text cursor">
                    다운받기
                  </p>
                </div>
              ))}
            </div> */}

            <div className="project_list mb-7">
              <p>
                {repeatData.repeatData[targetIdx].studentBody
                  ?.split("\n")
                  .map((line: any, i: number) => {
                    return (
                      <div key={i}>
                        {line}
                        <br />
                      </div>
                    );
                  })}
              </p>
            </div>

            {repeatData?.repeatData[targetIdx]?.submissionTime && (
              <p className="mb-7 font-bold">
                {timeFormat11(repeatData?.repeatData[targetIdx]?.submissionTime)} 제출
              </p>
            )}

            {confirmType !== "reject" && (
              <div className="display-f  align-center mb-20">
                <p className="font-15 font-500 mr-33">피드백</p>
                <textarea
                  onChange={(e) => setFeedback(e.target.value)}
                  value={feedback}
                  className="feedback__text"
                  name="feedback"
                  cols={20}
                ></textarea>
              </div>
            )}

            <div className="display-f justify-between align-center mb-28">
              <button
                onClick={() => handleConfirmProjectType(1)}
                className={`focus project__sign__btn light-black ${
                  confirmType === "cookie" ? "confirm_selected" : "cookie"
                }`}
              >
                쿠키
              </button>
              <button
                onClick={() => handleConfirmProjectType(2)}
                className={`focus project__sign__btn light-black ${
                  confirmType === "score" ? "confirm_selected" : "score"
                }`}
              >
                성적
              </button>
              <button
                onClick={() => handleConfirmProjectType(3)}
                className={`focus project__sign__btn light-black project_reject_btn ${
                  confirmType === "reject" ? "confirm_reject_selected" : "reject"
                }`}
              >
                반려
              </button>
            </div>

            <div className="mb-40">
              {confirmType === "cookie" && (
                <div className="display-f align-center">
                  <button
                    onClick={() => setCookieSelected(1)}
                    className={`${
                      cookieSelected !== 1 && "cookie_border_none"
                    } cookie_circle cursor display-f-center mr-17`}
                  >
                    {cookieSelected === 1 ? (
                      <img className="cookie1" src={cookie1S} alt="cookie1S" />
                    ) : (
                      <img className="cookie1" src={cookie1G} alt="cookie1G" />
                    )}
                  </button>
                  <button
                    onClick={() => setCookieSelected(2)}
                    className={`${
                      cookieSelected !== 2 && "cookie_border_none"
                    } cookie_circle cursor display-f-center mr-17`}
                  >
                    {cookieSelected === 2 ? (
                      <img className="cookie2" src={cookie2S} alt="cookie2S" />
                    ) : (
                      <img className="cookie2" src={cookie2G} alt="cookie2G" />
                    )}
                  </button>
                  <button
                    onClick={() => setCookieSelected(3)}
                    className={`${
                      cookieSelected !== 3 && "cookie_border_none"
                    } cookie_circle cursor display-f-center`}
                  >
                    {cookieSelected === 3 ? (
                      <img className="cookie3" src={cookie3S} alt="cookie3S" />
                    ) : (
                      <img className="cookie3" src={cookie3G} alt="cookie3G" />
                    )}
                  </button>
                </div>
              )}
              {confirmType === "score" && (
                <div className="display-f align-center">
                  <p className="mr-23 font-500 font-15">성적 입력</p>
                  <input
                    value={score}
                    maxLength={4}
                    onChange={(e: any) => setScore(e.target.value)}
                    className="score_input"
                    type="text"
                  />
                </div>
              )}
              {confirmType === "reject" && (
                <>
                  <div className="display-f align-center justify-between">
                    <p className="font-500 font-15">반려 사유</p>
                    <textarea
                      value={rejectReason}
                      onChange={(e: any) => setRejectReason(e.target.value)}
                      className="reject__reason__text"
                      name="rejectReason"
                    />
                  </div>
                </>
              )}
            </div>

            <div className="display-f-center">
              <button onClick={handleConfirmProject} className="popup__bottom__btn mr-14 ">
                {t(`Confirm`)}
              </button>
              <button
                onClick={() => {
                  setConfirmProjectPopup(false);
                  setConfirmType("cookie");
                  setScore("");
                  setCookieSelected(1);
                  setRejectReason("");
                  setFeedback("");
                }}
                className="popup__bottom__btn"
              >
                {t(`Cancel`)}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
