import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import { IProject, IStudent } from "../interface/Interface";
import { Link, NavLink, useHistory, useParams } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import ReactQuill from "react-quill";
import search from "../images/search.png";
import down from "../images/down.png";
import bin from "../images/bin.png";
import down_b from "../images/down_b.png";
import empty_folder from "../images/empty-folder.png";
import fill_folder from "../images/folder.png";
import back from "../images/arrow-left.png";
import {
  deletelist,
  getChecklistFolders,
  getChecklistOnFolder,
  getCheckLists,
  getFoldersOfChecklist,
  getStudents,
  postChecklist,
  postChecklistFolder,
  postUploadImage,
  putHideChecklist,
  putModifyFoldersTitle,
} from "../common/Action";
import { formatStyle, quillStyle } from "../common/QuillStyle";
import Modal from "../component/Modal";
import { consoleLog } from "../common/Method";
import { t } from "i18next";

const CheckListFolder: React.FC = () => {
  const params: any = useParams();
  const history: any = useHistory();
  const fileRef = useRef<HTMLInputElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [cardData, setCardData] = useState<IProject[]>([]);
  const [students, setStudents] = useState<IStudent[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [filter, setFilter] = useState<string[]>([
    t(`Unmarked`), // 미확인 순
    t(`Newest to Oldest`), // 최신 순
    t(`Alphabetical (A-Z)`), // 가나다 순
  ]);
  const [filterSelected, setFilterSelected] = useState<string>(t(`Unmarked`)); // 미확인 순
  const [text, setText] = useState<string>("");
  const [checklistTitle, setChecklistTtile] = useState<string>("");
  const [files, setFiles] = useState<any[]>([]);
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const [filterPopup, setFilterPopup] = useState<boolean>(false);
  const [checklistEdit, setChecklistEdit] = useState<boolean>(false);
  const [allSelected, setAllselected] = useState<boolean>(false);
  const [studentsList, setStudentsList] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>(false);
  const [blockSubmit, setBlockSubmit] = useState<boolean>(false);
  const [studentCheckWait, setStudentCheckWait] = useState<boolean>(false);
  const [folderTitle, setFolderTitle] = useState<string>("");
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [popupFolderSelect, setPopupFolderSelect] = useState<any>({
    title: t(`Unspecified folder`),
  }); // 폴더 미지정
  const [foldersData, setFoldersData] = useState<any>([]);
  const [hidden, setHidden] = useState<boolean>(false);
  const [changeFolderTitle, setChangeFolderTitle] = useState<boolean>(false);

  useEffect(() => {
    init();
    return () => {};
  }, []);

  useEffect(() => {
    if (popup === false) {
      setText("");
      setChecklistTtile("");
      setFiles([]);
      setFileUrls([]);
    }
  }, [popup]);

  const init = async (): Promise<void> => {
    // params.folderId에 해당하는 아이디 가져와서 checklist 조회하기
    let folderResult: any = [];
    const pathname = history.location.pathname;
    if (pathname.includes("hiddens")) {
      setHidden(true);
      folderResult = await getChecklistOnFolder(params.classId, params.folderId, true);
    } else {
      folderResult = await getChecklistOnFolder(params.classId, params.folderId);
    }
    let studentResult: any = await getStudents(params.classId);
    const foldersResult: any = await getFoldersOfChecklist(params.classId);

    if (foldersResult.length !== 0) {
      const targetFolder = foldersResult?.filter(
        (folderItem: any, i: number) => folderItem._id === params.folderId,
      );
      setFolderTitle(targetFolder[0].title);
      setPopupFolderSelect(targetFolder[0]);
      setFoldersData(foldersResult);
    }

    for (let i in studentResult) {
      studentResult[i].checked = true;
    }
    setCardData(folderResult);
    setStudents(studentResult);
  };

  const handleSelectAll = (): void => {
    const temp = [...cardData];
    for (let i = 0; i < temp.length; i++) {
      if (!allSelected) {
        temp[i].checked = true;
      } else {
        temp[i].checked = false;
      }
    }
    setAllselected((prev) => !prev);
    setCardData(temp);
  };

  const handleSearch = (e: any): void => {
    if (e.target.value === "") {
      const temp = [...cardData];
      for (let i in temp) {
        temp[i].searchHide = false;
      }
      setCardData(temp);
    } else {
      let temp: any = [...cardData];
      for (let i in temp) {
        if (
          temp[i].title.includes(e.target.value) ||
          temp[i].description.includes(e.target.value)
        ) {
          temp[i].searchHide = false;
        } else {
          temp[i].searchHide = true;
        }
      }

      setCardData(temp);
    }
    setSearchInput(e.target.value);
  };

  const handleFilterSelect = async (index: number): Promise<void> => {
    let temp = "";
    temp = filter[index];
    setFilterSelected(temp);
    let filterData: any = [];

    if (filter[index] === t(`Unmarked`)) {
      // 미확인 순
      filterData = await getCheckLists(params.classId, "status", -1);
    }
    if (filter[index] === t(`Newest to Oldest`)) {
      // 최신 순
      filterData = await getCheckLists(params.classId, "created", 1);
    }
    if (filter[index] === t(`Alphabetical (A-Z)`)) {
      // 가나다 순
      filterData = await getCheckLists(params.classId, "title", 1);
    }

    setCardData(filterData);
    setFilterPopup(false);
  };

  const handleCheckCard = (index: number): void => {
    const temp = [...cardData];
    for (let i = 0; i < temp.length; i++) {
      if (index === i) {
        temp[index].checked = !temp[index].checked;
      }
    }
    setCardData(temp);
  };

  const handleFile = (e: any) => {
    if (e.target.files) {
      if (e.target.files[0].type.split("/")[0] === "image") {
        let fileTemp = [...files];
        fileTemp.push(e.target.files[0]);
        setFiles(fileTemp);
        let fileUrlTemp = [...fileUrls];
        fileUrlTemp.push(URL.createObjectURL(e.target.files[0]));
        setFileUrls(fileUrlTemp);
      } else {
        // alert("이미지만 첨부해주세요!");
        alert(t(`Please only attach images`));
      }
    } else {
      return;
    }
  };

  const handleSubmit = async (): Promise<void> => {
    let tempUrls: string[] = [];
    if (files.length !== 0) {
      for (let i in files) {
        const formData = new FormData();
        formData.append("file", files[i]);
        const getUrls: any = await postUploadImage(formData, 500);
        tempUrls.push(getUrls);
      }
    }
    let tempCode: string[] = [];
    for (let i in students) {
      if (students[i].checked) {
        tempCode.push(students[i].code);
      }
    }
    const data = {
      onFolder: popupFolderSelect.title !== t(`Unspecified folder`) ? true : false, // !== 폴더 미지정
      folderId: popupFolderSelect._id ? popupFolderSelect._id : "",
      classId: params.classId,
      title: checklistTitle,
      description: text,
      file: tempUrls,
      studentCodeList: tempCode,
    };
    const postChecklistResult: any = await postChecklist(data);
    setPopup(false);
    init();
  };

  const handleStudentChecked = (i: number): void => {
    setStudentCheckWait(true);
    const temp = [...students];
    temp[i].checked = !temp[i].checked;
    setStudents(temp);
    setStudentCheckWait(false);
  };

  const handleStudentsAllCheck = (state: boolean) => {
    setStudentCheckWait(true);
    const temp = [...students];
    for (let i in temp) {
      temp[i].checked = state;
    }
    setStudents(temp);
    setStudentCheckWait(false);
  };

  const handleClickOutside = (e: any) => {
    if (popupRef.current !== null) {
      if (!popupRef.current.contains(e.target)) setPopup(false);
    }
  };

  const handleDeleteChecklist = async (): Promise<void> => {
    let checklistIds = [];
    let folderIds: any = [];

    for (let i in cardData) {
      if (cardData[i].checked) {
        checklistIds.push(cardData[i]._id);
      }
    }

    const hideResult = await putHideChecklist({
      checklistIds,
      folderIds,
      hide: true,
      classId: params.classId,
      inFolder: true,
      folderId: params.folderId,
    });

    setChecklistEdit(false);
    init();
  };

  const handleCloseModal = (event: any): void => {
    event.preventDefault();
    setPopup(false);
  };

  const handleModifyFolderName = async (): Promise<void> => {
    const modifyResult = await putModifyFoldersTitle({
      _id: params.folderId,
      title: folderTitle,
    });

    if (modifyResult) setChangeFolderTitle(false);
  };

  return (
    <div className="col-md-12 category_container">
      {/* header */}

      {checklistEdit ? (
        <div className="display-f justify-between align-center mb-33 pl-10 pr-10">
          <div className="display-f align-center">
            <button onClick={handleSelectAll} className="select_all_btn mr-10 position-r">
              {allSelected && (
                <span className={`check__inside select_all position-a bg-checked`}></span>
              )}
            </button>
            <p className="font-16 mt-9 mb-10">{t(`Select All`)}</p>
          </div>
          <div></div>

          <div>
            <button onClick={handleDeleteChecklist} className="select_submit_btn mr-13">
              {/* 숨기기 */}
              {t(`Hide`)}
            </button>
            <button onClick={() => setChecklistEdit(false)} className="select_submit_btn">
              {/* 취소 */}
              {t(`Cancel`)}
            </button>
          </div>
        </div>
      ) : (
        <div className="display-f justify-between align-center mb-33 pl-10 pr-10">
          <div className="display-f align-center" style={{ width: 528 }}>
            <img
              onClick={() => history.goBack()}
              src={back}
              className="mr-20 cursor"
              style={{ marginLeft: -10, width: 35, height: "auto" }}
            />
            {cardData.length !== 0 && (
              <img
                style={{ width: 28, height: "auto", marginRight: 5 }}
                src={fill_folder}
                alt="bin"
              />
            )}
            {cardData.length === 0 && (
              <img
                style={{ width: 28, height: "auto", marginRight: 5 }}
                src={empty_folder}
                alt="bin"
              />
            )}
            {!changeFolderTitle && (
              <h2
                onClick={() => setChangeFolderTitle((prev: boolean) => !prev)}
                className="mr-40 cursor"
              >
                {folderTitle}
              </h2>
            )}
            {changeFolderTitle && (
              <div>
                <input
                  // placeholder="폴더명을 입력해 주세요."
                  placeholder={t(`Please specify a folder name`)}
                  className="folder_title_input"
                  value={folderTitle}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setFolderTitle(event.target.value)
                  }
                />
                <button
                  onClick={handleModifyFolderName}
                  className="stack_tag_btn border-none white"
                  style={{ backgroundColor: "#336633" }}
                >
                  {/* 저장 */}
                  {t(`Save`)}
                </button>
              </div>
            )}
          </div>

          <div className="display-f align-center">
            <div className="display-f position-r">
              <form
                className="mr-10 position-r"
                // onSubmit={(e: any) => handleSearch(e)}
                action={`/projects/${searchInput}`}
              >
                <img
                  // onClick={(e: any) => handleSearch(e)}
                  className="search_icon position-a cursor"
                  src={search}
                  alt="search"
                />
                <input
                  value={searchInput}
                  onChange={(e) => handleSearch(e)}
                  type="text"
                  className="category_searchInput pl-50 pr-10"
                />
              </form>
              {/* <OutsideClickHandler
                onOutsideClick={() => {
                  setFilterPopup(false);
                }}
              >
                <button
                  onClick={() => setFilterPopup((prev) => !prev)}
                  className="filter_btn mr-10 text-left pl-17 pr-12 display-f justify-between align-center"
                >
                  {filterSelected} <img className="filter_icon" src={down} alt="down" />
                </button>
                {filterPopup && (
                  <div className="position-a filter_contents">
                    {filter.map((data: string, index: number) => (
                      <div
                        onClick={() => handleFilterSelect(index)}
                        className="filter_inner cursor"
                        key={index}
                      >
                        <p className="pl-17 pt-11 pb-11 font-14">{data}</p>
                      </div>
                    ))}
                  </div>
                )}
              </OutsideClickHandler> */}
            </div>
            {!hidden && (
              <div>
                <button onClick={() => setChecklistEdit(true)} className="select_btn mr-28">
                  {/* 수정 */}
                  {t(`Edit`)}
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* card */}

      <div className="display-f flex-wrap">
        {cardData &&
          cardData.map((data: any, index: number) => {
            if (checklistEdit) {
              return (
                <div key={index} className="display-ib pl-10 pr-10 position-r cursor">
                  <button
                    onClick={() => handleCheckCard(index)}
                    style={{ border: "none", cursor: "pointer" }}
                    className={`card_checklist_normal project_inner_card padding-0 justify-center pl-15 pr-15 ${data?.status}`}
                  >
                    <div className="display-b mb-9 font-15 color-dark font-700 text-center word-break">
                      {data.title}
                    </div>
                    <span className="position-a check cursor">
                      {data.checked && (
                        <span className={`check__inside position-a bg-checked`}></span>
                      )}
                    </span>
                  </button>
                </div>
              );
            }

            if (!checklistEdit && !data.searchHide && !data.isFolder) {
              return (
                <Link
                  key={index}
                  to={`/page/${
                    history.location.pathname.includes("hiddens") ? "hiddens/" : ""
                  }checklistdetail/${params.classId}/${data._id}`}
                >
                  <div key={index} className="display-ib pl-10 pr-10">
                    <div
                      className={`card_checklist_normal project_inner_card justify-center padding-0 pl-15 pr-15 ${data.status}`}
                    >
                      <div
                        className={`word-break display-b mb-9 font-15 font-700 text-center color-${
                          data.color === "green" ? "white" : "dark"
                        }`}
                      >
                        {data.title}
                      </div>
                    </div>
                  </div>
                </Link>
              );
            }

            if (!checklistEdit && data.isFolder) {
              return (
                <Link key={index} to={`/page/folder/checklist/${params.classId}/${data._id}`}>
                  <div key={index} className="display-ib pl-10 pr-10">
                    <div
                      className={`border-14 card_checklist project_inner_card justify-center padding-0 pl-15 pr-15 ${data.status} green`}
                    >
                      <div
                        className={`word-break display-b mb-9 font-15 font-700 text-center color-${
                          data.color === "green" ? "white" : "dark"
                        }`}
                      >
                        {data.title}
                      </div>
                    </div>
                  </div>
                </Link>
              );
            }
          })}

        {!checklistEdit && !hidden && (
          <>
            <div className="display-ib pl-10 pr-10">
              <div
                onClick={() => setPopup(true)}
                // onClick={() => setFolderModal(true)}
                className="card_checklist_normal project_inner_card padding-0 justify-center project_card"
              >
                <p className="font-34 font-900">+</p>
              </div>
            </div>
          </>
        )}
      </div>

      {/* popup */}
      {popup && (
        <div className="popup">
          <div className="modal-overlay" onClick={handleCloseModal}></div>
          <div ref={popupRef} className="project_add_popup scroll">
            <p className="font-23 mb-26 text-center">{t(`Create Checklist`)}</p>
            <p className="color-light-black mb-8 font-15">{t(`Title`)}</p>
            <div className="display-f align-center mb-22">
              <input
                value={checklistTitle}
                onChange={(e: any) => setChecklistTtile(e.target.value)}
                className="popup_input mr-18 pl-12 pr-12"
                type="text"
                maxLength={31}
              />
            </div>

            <div className="mb-22">
              <p className="color-light-black mb-8 font-15">{t(`Folder`)}</p>
              {/* folder selection */}

              <div
                onClick={() => setOpenSelection(true)}
                style={{ alignItems: "center", marginTop: 10 }}
                className="display-f"
              >
                <div className="repeat-selectbar cursor">
                  <p className={`${popupFolderSelect?.title === "폴더 미지정" ? "font-gray" : ""}`}>
                    {popupFolderSelect?.title}
                  </p>

                  <img src={down_b} style={{ width: 18, height: "auto" }} />
                </div>
              </div>

              {openSelection && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setOpenSelection(false);
                  }}
                >
                  <div className="repeat-selectbar-open" style={{ marginLeft: 0 }}>
                    <div
                      onClick={() => {
                        setPopupFolderSelect({ title: t(`Unspecified folder`) }); // 폴더 미지정
                        setOpenSelection(false);
                      }}
                      className="repeat-select-item-n cursor font-gray"
                    >
                      {/* 폴더 미지정 */}
                      {t(`Unspecified folder`)}
                    </div>
                    {foldersData?.map((folder: any, i: number) => (
                      <div
                        key={i}
                        onClick={() => {
                          setPopupFolderSelect(folder);
                          setOpenSelection(false);
                        }}
                        className="repeat-select-item-n cursor"
                      >
                        {folder.title}
                      </div>
                    ))}
                  </div>
                </OutsideClickHandler>
              )}
            </div>

            <p className="color-light-black font-15 mb-8">
              {/* 내용 */}
              {t(`Detail`)}
            </p>
            <ReactQuill
              value={text}
              onChange={(e) => setText(e)}
              className="project-quill"
              modules={quillStyle}
              formats={formatStyle}
            />
            {fileUrls.map((data: string, index: number) => (
              <img key={index} src={data} className="img-add mb-10" alt="file" />
            ))}
            <div className="popup__additional__project mb-30">
              <input
                ref={fileRef}
                type="file"
                className="display-n"
                name="file"
                onChange={(e: any) => handleFile(e)}
              />
              <button
                onClick={() => {
                  fileRef.current !== null && fileRef.current.click();
                }}
                className="focus add__file__btn font-13 font-500 mr-11 mt-10"
              >
                {/* 파일 첨부 */}
                {t(`Attach`)}
              </button>

              {files && (
                <button
                  onClick={() => {
                    setFiles([]);
                    setFileUrls([]);
                  }}
                  className="focus add__file__btn font-13 font-500 mr-11 mt-10"
                >
                  {/* 파일 삭제 */}
                  {t(`Delete files`)}
                </button>
              )}

              <button
                onClick={() => setStudentsList(true)}
                className="focus target__student__btn font-13 font-500"
              >{`${t(`Select Recipient`)} (${
                students.filter((item: IStudent) => item.checked).length
              })`}</button>

              {studentsList === true && (
                <div className="studentsList__popup">
                  <div className="studentsList__contents">
                    <div className="overflow-h scroll mb-30">
                      <p className="font-23 text-center mb-20 pt-20">대상학생 목록</p>
                      {students
                        .sort((a: any, b: any) => a.num - b.num)
                        .map((data: IStudent, index: number) => (
                          <div key={index} className="managing__options__contents position-r">
                            <p className="mt-15 mb-15 font-15">{data.name}</p>
                            <input
                              onChange={() => handleStudentChecked(index)}
                              // checked={data.target}
                              type="checkbox"
                              id={JSON.stringify(index)}
                              className="display-n"
                            />
                            <div>
                              <label
                                className="target_label cursor"
                                htmlFor={JSON.stringify(index)}
                              >
                                {data.checked && <span className="studentList_check"></span>}
                              </label>
                            </div>
                          </div>
                        ))}
                      <div>
                        <div className="text-center mt-20 mb-20 pb-20">
                          <button
                            onClick={() => handleStudentsAllCheck(true)}
                            className="focus popup__bottom__btn mr-14"
                          >
                            {/* 모두 선택 */}
                            {t(`Select All`)}
                          </button>
                          <button
                            onClick={() => handleStudentsAllCheck(false)}
                            className="focus popup__bottom__btn mr-14"
                          >
                            {/* 모두해제 */}
                            {t(`Deselect all`)}
                          </button>
                          <button
                            onClick={() => setStudentsList(false)}
                            className="focus popup__bottom__btn mr-14"
                          >
                            {/* 확인 */}
                            {t(`Confirm`)}
                          </button>
                          <button
                            onClick={() => setStudentsList(false)}
                            className="focus popup__bottom__btn"
                          >
                            {/* 취소 */}
                            {t(`Cancel`)}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="text-center mb-20">
              <button onClick={handleSubmit} className="focus popup__bottom__btn mr-14">
                {/* 확인 */}
                {t(`Confirm`)}
              </button>
              <button onClick={() => setPopup(false)} className="focus popup__bottom__btn">
                {/* 취소 */}
                {t(`Cancel`)}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckListFolder;
