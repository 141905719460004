import React, { useState, useEffect, useRef, useContext, useLayoutEffect } from "react";
import {
  IStudent,
  IProject,
  IProjectOfStudent,
  IMainContext,
  IStudentInfo,
} from "../interface/Interface";
import OutsideClickHandler from "react-outside-click-handler";
import down from "../images/down.png";
import cookie1 from "../images/cookie1.png";
import cookie2 from "../images/cookie2.png";
import cookie3 from "../images/cookie3.png";
import cookie1S from "../images/cookie1S.png";
import cookie2S from "../images/cookie2S.png";
import cookie3S from "../images/cookie3S.png";
import cookie1G from "../images/cookie1G.png";
import cookie2G from "../images/cookie2G.png";
import cookie3G from "../images/cookie3G.png";
import check from "../images/check.png";
import check2 from "../images/check2.png";
import copy from "../images/copy.png";
import search from "../images/search.png";
import hide from "../images/hide.png";
import {
  getCategorys,
  getCheckistForStudentPage,
  getEachStudentInfo,
  getEachStudentProject,
  postNotification,
  postNotificationAll,
  postStackRecord,
  putCancelCheckCos,
  putCheckCos,
  putConfirmProject,
  putCookies,
  putEachBs,
  putModifyCos,
  putModifyStudentInfo,
  putRejectProject,
} from "../common/Action";
import { useParams } from "react-router";
import PopupAlbum from "../component/PopupAlbum";
import { MainContext } from "../common/Context";
import { sortedImageFileArr, timeFormat11 } from "../common/Method";
import { Link } from "react-router-dom";
import HiddenProjectView from "../component/HiddenProjectView";
import file_icon from "../images/file_icon.png";
import { t } from "i18next";
import moment from "moment";

const Student: React.FC = () => {
  const context = useContext<IMainContext>(MainContext);
  const params: any = useParams();
  const copyInput = useRef<HTMLInputElement>(null);
  const studentAddPopupRef = useRef<HTMLDivElement>(null);
  const popupGenderRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const checklistPopupRef = useRef<HTMLDivElement>(null);
  const useCookiePopupRef = useRef<HTMLDivElement>(null);
  const [studentData, setStudentData] = useState<any>({});
  const [projectData, setProjectData] = useState<any[]>([]);
  const [projectOfStudentData, setProjectOfStudentData] = useState<any>({});
  const [filter, setFilter] = useState<string[]>([
    t(`Unmarked`), // 미확인 순
    t(`Order of non-submission`), // 미제출 순
    t(`Oldest to Newest`), // 생성 날짜 순
    t(`Alphabetical (A-Z)`), // 가나다 순
  ]);
  const [filterSelected, setFilterSelected] = useState<string>(t(`Oldest to Newest`));
  const [filterPopup, setFilterPopup] = useState<boolean>(false);
  const [studentAddPopup, setStudentAddPopup] = useState<boolean>(false);
  const [useCookiePopup, setUseCookiePopup] = useState<boolean>(false);
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
  const [popupStudentNameInput, setPopupStudentNameInput] = useState<string>("");
  const [popupStudentNumberInput, setPopupStudentNumberInput] = useState<string>("");
  const [popupTransterInput, setPopupTransferInput] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");
  const [confirmType, setConfirmType] = useState<string>("");
  const [gender, setGender] = useState<string>("남자");
  const [genderDropped, setGenderDropped] = useState<boolean>(false);
  const [genderDropdown, setGetnderDropdown] = useState<string[]>(["남자", "여자"]);
  const [confirmProjectPopup, setConfirmProjectPopup] = useState<boolean>(false);
  const [cookieSelected, setCookieSelected] = useState<number>(0);
  const [totalCookie, setTotalCookie] = useState<number>(0);
  const [restCookies, setRestCookies] = useState<number>(0);
  const [usedCookie, setUsedCookie] = useState<number>(0);
  const [usingCookie, setUsingCookie] = useState<string>("");
  const [score, setScore] = useState<string>("");
  const [rejectReason, setRejectReason] = useState<string>("");
  const [album, setAlbum] = useState<boolean>(false);
  const [albumUrl, setAlbumUrl] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [studentBodyFalse, setStudentBodyFalse] = useState<boolean>(false);
  const [selectedProjectTitle, setSelectedProjectTitle] = useState<string>("");
  const [badgesArr, setBadgesArr] = useState<any>([]);
  const [badgeId, setBadgeId] = useState<string>("");
  const [checklistPopup, setChecklistPopup] = useState<boolean>(false);
  const [selectedChecklist, setSelectedChecklist] = useState<any>({});
  const [save, setSave] = useState<boolean>(false);
  const [badgeStudent, setBadgeStudent] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [albumData, setAlbumData] = useState<any>({});
  const [tags, setTags] = useState<any[]>([]);
  const [filterModal, setFilterModal] = useState<boolean>(false);
  const [projectDataOnly, setProjectDataOnly] = useState<any>([]);
  const [tagsResult, setTagsResult] = useState<any>([]);
  const [originalProjectsData, setOriginalProjectsData] = useState<any>([]);
  const [checklistData, setChecklistData] = useState<any>([]);
  const [hidden, setHidden] = useState<boolean>(false);
  const [hiddenPopup, setHiddenPopup] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [phone, setPhone] = useState<any>("");
  const [initialFn, setInitialFn] = useState<boolean>(false);

  // 쿠키쓰기 설명
  const [reportBody, setReportBody] = useState<string>("");

  useEffect(() => {
    init();
    // return () => {
    //   filterHandler();
    // };
  }, []);

  const init = async (): Promise<void> => {
    let studentResult: any = await getEachStudentInfo(params.studentId);

    if (studentResult[0]?.code) {
      let showHiddens = context.showHiddensStudentPage ? true : false;
      const projectsResult: any = await getEachStudentProject(
        studentResult[0].code,
        "created",
        1,
        showHiddens,
      );
      const tagsResult: any = await getCategorys(params.classId);
      if (tagsResult.length !== 0) {
        setTags(tagsResult[0]?.tags);
      }

      for (let i in projectsResult) {
        let complete = 0;
        if (projectsResult[i].repeat) {
          for (let k in projectsResult[i].repeatData) {
            if (projectsResult[i].repeatData[k].state === "complete") {
              complete += 1;
            }
          }
        }

        projectsResult[i].completeCount = complete;
      }

      let checklistResult: any = [];
      if (context.showHiddenChecklistsStudentPage) {
        checklistResult = await getCheckistForStudentPage(studentResult[0].code);
        checklistResult = checklistResult.filter((el: any) => !el.checklistHide);

        for (let i in checklistResult) {
          checklistResult[i].type = "checklist";
        }
        setChecklistData(checklistResult);
      }

      setPhone(studentResult[0]?.phone);
      setPopupTransferInput(studentResult[0].transfer);
      setProjectData(() => {
        return [...projectsResult, ...checklistResult];
      });
      setOriginalProjectsData(() => {
        return [...projectsResult];
      });
      setProjectDataOnly(projectsResult);
      setPopupStudentNameInput(studentResult[0].name);
      setPopupStudentNumberInput(studentResult[0].num);
      setGender(studentResult[0].gender);
      setStudentData(studentResult[0]);
      setRestCookies(
        Number(studentResult[0]?.cookie) -
          Number(studentResult[0]?.usedCookie ? studentResult[0].usedCookie : 0),
      );
    }
    setInitialFn(true);
  };

  useEffect(() => {
    filterHandler();
  }, [searchInput, tags, context.showHiddensStudentPage, context.showHiddenChecklistsStudentPage]);

  const filterHandler = async (): Promise<any> => {
    if (!initialFn) return;

    let originArr = [...originalProjectsData];

    const addHiddensResult: any = await getEachStudentProject(
      studentData?.code,
      "created",
      1,
      context.showHiddensStudentPage,
    );
    for (let i in addHiddensResult) {
      let complete = 0;
      if (addHiddensResult[i].repeat) {
        for (let k in addHiddensResult[i].repeatData) {
          if (addHiddensResult[i].repeatData[k].state === "complete") {
            complete += 1;
          }
        }
      }
      addHiddensResult[i].completeCount = complete;
    }

    originArr = [...addHiddensResult];

    const tempTags = [...tags];
    const selectedTags = tempTags.filter((item: any) => item.selected);
    if (selectedTags.length !== 0) {
      let tempArr: any = new Set();
      const flatTags: any = [];
      for (let i = 0; i < originArr?.length; i++) {
        for (let k = 0; k < originArr[i]?.projectInfo[0]?.tags?.length; k++) {
          flatTags.push({
            i: i,
            tagName: tags[originArr[i]?.projectInfo[0]?.tags[k]]?.tagName,
          });
        }
      }

      for (let i = 0; i < flatTags?.length; i++) {
        for (let k = 0; k < selectedTags?.length; k++) {
          if (flatTags[i]?.tagName === selectedTags[k]?.tagName) {
            tempArr.add(originArr[flatTags[i]?.i]);
          }
        }
      }

      originArr = [...tempArr];
    }

    if (context.showHiddenChecklistsStudentPage) {
      let checklistResult: any = await getCheckistForStudentPage(studentData.code);
      checklistResult = checklistResult.filter((el: any) => !el.checklistHide);
      for (let i in checklistResult) {
        checklistResult[i].type = "checklist";
      }

      originArr = [...originArr, ...checklistResult];
      setChecklistData(checklistResult);
    }

    if (searchInput !== "") {
      let temp = [];
      for (let i = 0; i < originArr.length; i++) {
        if (
          (!originArr[i].type && originArr[i].projectInfo[0]?.title.includes(searchInput)) ||
          (originArr[i].type === "checklist" &&
            originArr[i].checklistInfo[0]?.title.includes(searchInput))
        ) {
          temp.push(originArr[i]);
        }
      }
      originArr = [...temp];
    }

    setProjectData(originArr);
  };

  const handleFilterSelect = async (index: number): Promise<void> => {
    let temp = "";
    temp = filter[index];
    setFilterSelected(temp);
    let projectsResult: any = [];
    const checklistResult: any = await getCheckistForStudentPage(studentData.code);

    if (filter[index] === t(`Unmarked`)) {
      // 미확인 순
      projectsResult = await getEachStudentProject(
        studentData.code,
        "confirmOrder",
        1,
        context.showHiddensStudentPage ? true : false,
      );
    } else if (filter[index] === t(`Order of non-submission`)) {
      // 미제출 순
      projectsResult = await getEachStudentProject(
        studentData.code,
        "state",
        1,
        context.showHiddensStudentPage ? true : false,
      );
    } else if (filter[index] === t(`Oldest to Newest`)) {
      // 생성 날짜 순
      projectsResult = await getEachStudentProject(
        studentData.code,
        "created",
        1,
        context.showHiddensStudentPage ? true : false,
      );
    } else if (filter[index] === t(`Alphabetical (A-Z)`)) {
      // 가나다 순
      projectsResult = await getEachStudentProject(
        studentData.code,
        "projectInfo.title",
        1,
        context.showHiddensStudentPage ? true : false,
      );
    }

    for (let i in checklistResult) {
      checklistResult[i].type = "checklist";
    }
    for (let i in projectsResult) {
      let complete = 0;
      if (projectsResult[i].repeat) {
        for (let k in projectsResult[i].repeatData) {
          if (projectsResult[i].repeatData[k].state === "complete") {
            complete += 1;
          }
        }
      }

      projectsResult[i].completeCount = complete;
    }

    if (context.showHiddenChecklistsStudentPage) {
      setProjectData((prev: any) => {
        return [...projectsResult, ...checklistResult];
      });
    } else {
      setProjectData((prev: any) => {
        return [...projectsResult];
      });
    }
    setFilterPopup(false);
  };

  const handleClickOutside = (e: { target: HTMLDivElement }): void => {
    if (checklistPopupRef.current !== null) {
      if (!checklistPopupRef.current.contains(e.target)) setChecklistPopup(false);
    }

    if (studentAddPopupRef.current !== null) {
      if (!studentAddPopupRef.current.contains(e.target)) setStudentAddPopup(false);
    }
    if (popupGenderRef.current !== null) {
      if (!popupGenderRef.current.contains(e.target)) setGenderDropped(false);
    }
    if (useCookiePopupRef.current !== null) {
      if (!useCookiePopupRef.current.contains(e.target)) setUseCookiePopup(false);
    }

    if (popupRef.current !== null) {
      if (!popupRef.current.contains(e.target)) {
        setConfirmProjectPopup(false);
        setHiddenPopup(false);
        setConfirmType("");
        setFeedback("");
      }
    }
  };

  const handleGenderSelect = (index: number): void => {
    let temp = "";
    for (let i = 0; i < genderDropdown.length; i++) {
      if (i === index) {
        temp = genderDropdown[index];
      }
    }
    setGender(temp);
    setGenderDropped(false);
  };

  const handleEditStudent = async (): Promise<void> => {
    const body: IStudentInfo = {
      code: studentData.code,
      name: popupStudentNameInput,
      num: popupStudentNumberInput,
      // gender: gender,
      // transfer: popupTransterInput,
      phone,
    };

    const result = await putModifyStudentInfo(body);
    if (result) {
      init();
      setStudentAddPopup(false);
    }
  };

  const handleProjectPopup = async (data: any, index: number): Promise<void> => {
    let fileLimit = 0;
    if (context.userInfo.fileSizeLimit === 0 || !context.userInfo.fileSizeLimit) {
      fileLimit = 100000000;
    } else {
      fileLimit = context.userInfo?.fileSizeLimit;
    }
    if (fileLimit < context.fileSize.originalFileSize) {
      return alert(
        "제한 용량을 초과하셨습니다. '과제 삭제' 또는 '학급 삭제'를 통해 저장 용량을 조절하시거나, '결제 관리' 페이지에서 저장 용량 업그레이드 후 이용해 주세요.",
      );
    }
    // 과제 팝업 오픈시 숨긴과제인지 확인
    setHidden(data?.projectInfo[0]?.hide);
    let tempFilUrls = sortedImageFileArr(data.fileUrls);
    setProjectOfStudentData({ ...data, fileUrls: tempFilUrls });
    // console.log(data);
    // // if (result[0].feedback !== "") setFeedback(result[0].feedback);
    if (data.state === "" && data.reject === "") {
      setConfirmType("cookie");
      setScore("");
      setFeedback("");
      setCookieSelected(1);
      // setRejectReason("");
      setRejectReason(data.reject === "_" ? "" : data.reject);
    }
    if (data.state === "check") {
      setScore("");
      setFeedback("");
      setConfirmType("cookie");
      setCookieSelected(1);
      setRejectReason(data.reject === "_" ? "" : data.reject);
    }
    if (data.state === "complete" && data.cookie === 0) {
      setScore(data.score);
      setConfirmType("score");
      setFeedback(data.feedback);
      setRejectReason(data.reject === "_" ? "" : data.reject);
    }
    if (data.state === "complete" && data.cookie !== 0) {
      setCookieSelected(data.cookie);
      setConfirmType("cookie");
      setFeedback(data.feedback);
      setRejectReason(data.reject === "_" ? "" : data.reject);
    }
    if (data.state === "" && data.reject !== "") {
      setRejectReason(data.reject === "_" ? "" : data.reject);
      setConfirmType("reject");
    }
    if (data?.projectInfo[0]?.hide) {
      setHiddenPopup(true);
    } else {
      setConfirmProjectPopup(true);
    }
  };

  const handleCancelConfirmPopup = (): void => {
    setConfirmProjectPopup(false);
    setConfirmType("");
    setScore("");
    setCookieSelected(0);
    setRejectReason("");
    setFeedback("");
  };

  const handleAlbum = (data: any): void => {
    setAlbumData(data);
    setAlbum((prev) => !prev);
    if (!data.isVideo) {
      setAlbumUrl(data.uri);
      setVideoUrl("");
    } else {
      setVideoUrl(videoUrl === "" ? data.uri : "");
      setAlbumUrl("");
    }
  };

  const handleConfirmProject = async (): Promise<void> => {
    if (hidden) return alert("숨긴 과제는 읽기만 가능합니다.");
    let cookie = 0;
    let tempScore = "";

    if (confirmType === "cookie" && cookieSelected !== 0) {
      cookie = cookieSelected;
      context.handleStateChange("class", "");
    }

    if (confirmType === "score" && score !== "") tempScore = score;
    if (confirmType === "reject") {
      const confirm = window.confirm(
        `반려하면 학생의 자료가 삭제되며, 복구가 되지 않습니다. 진행하시겠습니까?`,
      );
      if (!confirm) return;
      await putRejectProject({
        id: projectOfStudentData._id,
        repeat: false,
        rejectReason,
      });
      let body = {
        to: studentData.deviceToken2,
        title: "과제가 반려되었습니다. 📝",
        body: "다시 보내주세요.",
        data: {
          code: studentData.code,
          type: "project",
        },
      };
      postNotification(body);
      alert("과제가 반려되었습니댜.");
    } else {
      const result: any = await putConfirmProject({
        token: localStorage.getItem("token"),
        id: projectOfStudentData._id,
        repeat: false,
        confirmType,
        confirmScore: score,
        confirmCookies: cookie,
        feedback: feedback,
      });

      if (result.constructor == Array) {
        let pushArr: any = [];
        result.map((pushItem: any, i: number) => {
          pushArr.push({
            token: studentData.deviceToken2,
            notification: {
              title: `🎖${pushItem.title} 뱃지🎖`,
              body: `축하합니다! 확인해보세요!`,
            },
            data: {
              code: studentData.code,
              type: "project",
            },
          });
        });

        // 푸시알림 여러개 날리는 api
        postNotificationAll(pushArr);
      }

      let body = {
        to: studentData.deviceToken2,
        title: confirmType === "cookie" ? "쿠키 도착 🍪" : "과제 확인 📝",
        body:
          confirmType === "cookie"
            ? "쿠키가 도착했어요! 확인해보세요!"
            : "과제를 확인하셨습니다! 확인해보세요!",
        data: {
          code: studentData.code,
          type: "project",
        },
      };
      postNotification(body);
    }

    context.handleStateChange("class", "");
    setConfirmProjectPopup(false);
    setConfirmType("");
    setFeedback("");
    init();
  };

  const handleConfirmTypeChange = (type: number): void => {
    if (type === 1) {
      if (projectOfStudentData.state === "complete" && projectOfStudentData.score === "") {
        setConfirmType("cookie");
        setCookieSelected(projectOfStudentData.cookie ? projectOfStudentData.cookie : 0);
      } else {
        setConfirmType("cookie");
        setCookieSelected(1);
      }
    } else if (type === 2) {
      if (projectOfStudentData.state === "complete" && projectOfStudentData.score !== "") {
        setConfirmType("score");
        setScore(projectOfStudentData.score ? projectOfStudentData.score : "");
      } else {
        setConfirmType("score");
        setScore("");
      }
    } else if (type === 3) {
      if (projectOfStudentData.state === "" && projectOfStudentData.reject !== "") {
        setConfirmType("reject");
        // setRejectReason(projectOfStudentData.reject === "_" ? "" : projectOfStudentData.reject);
      } else {
        setConfirmType("reject");
      }
    }
  };

  const handlePushNotification = async (title?: string): Promise<void> => {
    const filteredProjectsData = projectData.filter(
      (data) => data.type !== "checklist" && data.state !== "complete",
    );
    if (filteredProjectsData.length === 0) return alert("완료하지 않은 과제가 없습니다.");

    const confirm = window.confirm(`${studentData.name} 학생에게 푸쉬 알람을 보냅니다`);
    if (confirm) {
      let body = {
        to: studentData.deviceToken2,
        title: title ? title : "과제를 확인해주세요!",
        body: "아직 안 한 과제가 있어요!",
        data: {
          type: "project",
          code: studentData.code,
        },
      };

      postNotification(body);
      alert("푸쉬 알림을 보냈습니다.");
    } else {
      return;
    }
  };

  const handleUsingCookies = async () => {
    const cookieMore = isNaN(Number(usingCookie)) ? 0 : Number(usingCookie);

    const updateCookies: any = await postStackRecord({
      studentsCode: [studentData.code],
      body: {
        reportBody,
        tags: [],
        classId: params.classId,
        reportDate: moment().valueOf(),
        increaseCookie: cookieMore > 0 ? -cookieMore : cookieMore,
        studentsCode: [studentData.code],
        type: "useCookies",
        useCookie: cookieMore > 0 ? true : false,
      },

      // "쿠키쓰기"일 경우
      useCookie: cookieMore > 0 ? true : false,
      increaseCookie: cookieMore > 0 ? -cookieMore : cookieMore,
    });

    // const updateCookies = await putCookies({
    //   code: studentData.code,
    //   cookie: Number(usingCookie),
    // });

    // console.log(updateCookies);
    if (Object.keys(updateCookies).length !== 0) {
      setUseCookiePopup(false);
      // alert("쿠키가 변경되었습니다.");
    }

    setUsingCookie("");
    setReportBody("");
    init();
  };

  const handleUseCookie = (e: any): void => {
    let minimum = 0;
    let maximum = 0;
    let remains: number =
      Number(studentData.cookie) - (studentData.usedCookie ? studentData.usedCookie : 0);

    if (remains < 0) {
      if (-studentData.usedCookie < remains) {
        minimum = -studentData.usedCookie;
      } else {
        minimum = remains;
      }
      maximum = studentData.cookie;
    } else {
      minimum = -(studentData.usedCookie ? studentData.usedCookie : 0);
      maximum = remains;
    }

    const numParse = parseInt(e.nativeEvent.data);
    if (e.nativeEvent.data === null || e.nativeEvent.data === "-") {
      setUsingCookie(e.target.value);
    }

    if (!isNaN(numParse) && e.target.value >= minimum && e.target.value <= maximum) {
      setUsingCookie(e.target.value);
    }
  };

  const calcCookie = (): number => {
    const n =
      Number(studentData.cookie) -
      (studentData.usedCookie ? studentData.usedCookie : 0) -
      (Number(usingCookie) ? Number(usingCookie) : 0);
    // if (n < 0) {
    //   return "남은 쿠키가 없습니다.";
    // } else {
    return n;
    // }
  };

  const handleEasyCheck = async (data: any, e: any): Promise<void> => {
    e.stopPropagation();
    await putCheckCos(data._id, "complete");
    init();
  };

  const handleUnSubmit = async (): Promise<void> => {
    await putCancelCheckCos(selectedChecklist._id);
    setChecklistPopup(false);
    init();
  };

  const handleSave = async (): Promise<void> => {
    const update: any = await putModifyCos({
      id: selectedChecklist._id,
      description: selectedChecklist.description,
    });

    if (update) setSave(true);

    setTimeout(() => {
      setSave(false);
    }, 500);

    init();
  };

  const handleSubmitChecklist = async (): Promise<void> => {
    let state = selectedChecklist.state === "" ? "complete" : selectedChecklist.state;
    await putCheckCos(selectedChecklist._id, state);
    handleSave();
    setChecklistPopup(false);
    init();
  };

  const handleAddBadge = async (badge: any, i: number): Promise<void> => {
    if (loading) return;
    setLoading(true);

    if (badge.type === "auto") {
      alert("자동지급 뱃지입니다.");
    } else {
      let tempBadges: any = { ...studentData };
      tempBadges.studentBadgeInfo[i] = !studentData.studentBadgeInfo[i];

      await putEachBs({
        code: studentData.code,
        badges: tempBadges.studentBadgeInfo,
      });

      if (tempBadges.studentBadgeInfo[i]) {
        const body = {
          to: studentData.deviceToken2,
          title: `🎖 ${studentData.badgeInfo[i].title}뱃지🎖`,
          body: "축하합니다! 확인해보세요!",
          data: {
            code: studentData.code,
            type: "project",
          },
        };
        postNotification(body);
      }

      setStudentData(tempBadges);
    }

    setLoading(false);
  };

  const handleFilterTag = (tag: any) => {
    const tempTags = [...tags];
    const findTargetTagIdx: number = tempTags.findIndex((el: any) => el === tag);
    tempTags[findTargetTagIdx].selected = !tags[findTargetTagIdx].selected;

    setTags(tempTags);
  };

  const handleAddHiddens = async () => {
    context.handleStateChange("showHiddensStudentPage", !context.showHiddensStudentPage);
  };

  const handleAddChecklist = async () => {
    context.handleStateChange(
      "showHiddenChecklistsStudentPage",
      !context.showHiddenChecklistsStudentPage,
    );
  };

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const replace = (content: any) => {
    const convertContent = content.replace(urlRegex, function (url: any) {
      return '<a style="color: blue" href="' + url + '" target="_blank">' + url + "</a>";
    });

    const htmlArr: any = [];
    convertContent.split("\n").forEach(function (text: any) {
      const textHtml = "<p>" + text + "</p>";
      htmlArr.push(textHtml);
    });

    return { __html: htmlArr.join("") };
  };

  if (studentData.length === 0) return <></>;

  return (
    <div className="col-md-12 category_container">
      {album && (
        <PopupAlbum
          enable={album}
          albumData={albumData}
          setAlbumData={setAlbumData}
          fileData={projectOfStudentData?.fileUrls}
          handleAlbum={handleAlbum}
          setAlbumUrl={setAlbumUrl}
          setVideoUrl={setVideoUrl}
          albumUrl={albumUrl}
          videoUrl={videoUrl}
        />
      )}
      <div
        className="display-f justify-between align-center mb-15 pl-10 pr-10"
        style={{ flexWrap: "wrap" }}
      >
        <div className="display-f" style={{ flexWrap: "wrap", alignItems: "center" }}>
          <button onClick={() => setStudentAddPopup(true)} className="btn_name mr-10">
            <p className="font-23 font-700">
              {studentData.name} ({studentData.num}번)
            </p>

            {/* <div className="display-f mt-10">
              <button
                className="btn_small mr-10"
                style={{
                  border: "1px solid #3366cc",
                  backgroundColor: "#fff",
                  color: "#3366cc",
                  fontWeight: 500,
                }}
              >
                기록
              </button>
              <button onClick={() => setStudentAddPopup(true)} className="btn_small mr-20">
                학생 정보 수정
              </button>
            </div> */}
          </button>

          {/* badges */}
          <div className="display-f">
            {studentData?.badgeInfo?.map((item: any, i: number) => (
              <div key={i}>
                {item.imgUrl === "" && <div key={i} className="badge-item-container cursor" />}
                {item.imgUrl !== "" && !studentData?.studentBadgeInfo[i] && (
                  <button
                    onClick={() => handleAddBadge(item, i)}
                    className="badge-item-container cursor"
                    style={{
                      backgroundColor: "rgba(0,0,0,0.5)",
                      padding: 0,
                      position: "relative",
                    }}
                  >
                    {loading && (
                      <div
                        className="spinner2"
                        style={{ position: "absolute", top: 15, left: 15, zIndex: 100 }}
                      />
                    )}
                    <img
                      src={item.imgUrl}
                      style={{
                        width: "100%",
                        height: "100%",
                        filter: "brightness(45%)",
                        objectFit: "contain",
                      }}
                    />
                  </button>
                )}
                {item.imgUrl !== "" && studentData?.studentBadgeInfo[i] && (
                  <button
                    onClick={() => handleAddBadge(item, i)}
                    className="badge-item-container cursor"
                    style={{
                      padding: 0,
                    }}
                  >
                    <img
                      src={item.imgUrl}
                      className="badge-item-img cursor"
                      style={{ objectFit: "contain" }}
                    />
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>

        <div>
          <Link to={`/page/studentreport/${params.classId}/${params.studentId}/tab1`}>
            <button className="btn_blue mr-10">
              {/* 학생 리포트 */}
              <p>{t(`Student's report`)}</p>
            </button>
          </Link>

          {/* <button className="project_control_btn mr-10">
              <p>숨긴 과제 함께 보기/불러오기</p>
            </button> */}

          {/* <button className="project_control_btn">
            <p>전체 자료 다운</p>
          </button> */}
        </div>

        <div
          className="display-f student_top"
          style={{
            flexWrap: "wrap",

            alignItems: "center",
          }}
        >
          <div className="display-f">
            <form className="mr-10 position-r">
              <img className="search_icon position-a cursor" src={search} alt="search" />
              <input
                value={searchInput || undefined}
                onChange={(e: any) => setSearchInput(e.target.value)}
                type="text"
                className="category_searchInput pl-50 pr-10"
              />
            </form>

            <div className="position-r">
              <OutsideClickHandler onOutsideClick={() => setFilterPopup(false)}>
                <button
                  onClick={() => setFilterPopup((prev) => !prev)}
                  className="filter_btn text-left pl-17 pr-12 display-f justify-between align-center mr-10"
                >
                  {filterSelected} <img className="filter_icon" src={down} alt="down" />
                </button>
                {filterPopup && (
                  <div className="position-a filter_contents student_filter">
                    {filter.map((data: string, index: number) => (
                      <div
                        onClick={() => handleFilterSelect(index)}
                        className="filter_inner cursor"
                        key={index}
                      >
                        <p className="pl-17 pt-11 pb-11 font-14">{data}</p>
                      </div>
                    ))}
                  </div>
                )}
              </OutsideClickHandler>
            </div>

            {/* <button onClick={() => setStudentAddPopup(true)} className="project_control_btn mr-18">
            학생 정보 수정
          </button> */}
            <button onClick={() => handlePushNotification()} className="push_all_btn font-500">
              {t(`Send Push Alarms`)}
            </button>
          </div>

          {/* </div> */}
        </div>
      </div>

      <div
        className="display-f pl-10 mb-15 align-center"
        style={{ justifyContent: "space-between", flexWrap: "wrap" }}
      >
        <div className="display-f align-center">
          <img
            onClick={() => {
              if (copyInput.current !== null) {
                const el = copyInput.current;
                el.select();
                document.execCommand("copy");
                alert("초대 코드가 복사 완료되었습니다.");
              }
            }}
            className="mr-5 img_copy cursor"
            src={copy}
            style={{ width: 20, height: "auto" }}
          />

          <div
            onClick={() => {
              if (copyInput.current !== null) {
                const el = copyInput.current;
                el.select();
                document.execCommand("copy");
                alert("초대 코드가 복사 완료되었습니다.");
              }
            }}
            className="btn_copy"
          >
            <input
              type="text"
              value={studentData.code}
              ref={copyInput}
              readOnly
              className="font-25 copy_input display-ib cursor"
            />
          </div>

          <div className="display-f justify-center align-center" style={{ padding: "0 10px" }}>
            <img src={cookie1} style={{ width: 40, height: 40, marginRight: 10 }} />
            <div>
              <p style={{ fontSize: 18, fontWeight: "bold" }}>
                {/* 남은 쿠키 restCookies 개 */}
                {t(`remaining cookies`)} : {restCookies}
                {t(`pcs.(Pieces)`)}
              </p>
              <p>
                {/* 받은 쿠키 studentData.cookie 개 */}
                {t(`received cookies`)} : 총 {studentData.cookie}
                {t(`pcs.(Pieces)`)}
              </p>
            </div>
          </div>

          <button
            onClick={() => {
              setUseCookiePopup(true);
              // handleCountCookies();
            }}
            className="project_cookie_btn"
          >
            {t(`Use cookies`)}
          </button>
        </div>

        <div className="mr-10 display-f" style={{ width: "45%", justifyContent: "flex-end" }}>
          <div
            className="mr-10"
            style={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}
          >
            <div className="display-f align-center" style={{ height: 30 }}>
              <div className="display-f">
                <input
                  onChange={handleAddHiddens}
                  type="checkbox"
                  id={JSON.stringify("showHiddenProjects")}
                  className="display-n"
                />
                <div>
                  <label
                    className="target_label_y mr-5 cursor"
                    htmlFor={JSON.stringify("showHiddenProjects")}
                  >
                    {context.showHiddensStudentPage && <span className="studentList_check"></span>}
                  </label>
                </div>
              </div>
              <p className="cursor" onClick={handleAddHiddens}>
                {/* 숨긴과제 함께보기 */}
                {t(`See the hidden assignments`)}
              </p>
            </div>
            <div className="display-f align-center" style={{ height: 30 }}>
              <input
                onChange={handleAddChecklist}
                type="checkbox"
                id={JSON.stringify("showHiddenChecklists")}
                className="display-n"
              />
              <div>
                <label
                  className="target_label_g mr-5 cursor"
                  htmlFor={JSON.stringify("showHiddenChecklists")}
                >
                  {context.showHiddenChecklistsStudentPage && (
                    <span className="studentList_check"></span>
                  )}
                </label>
              </div>
              <p className="cursor" onClick={handleAddChecklist}>
                {t(`Show Checklist`)}
              </p>
            </div>
          </div>

          {tags.length !== 0 && (
            <div style={{ width: "60%" }}>
              {/* {tags?.map((tag: any, i: number) => (
                <>
                  {!tag.tagName && (
                    <button
                      onClick={() => {}}
                      className={`tag_btn ${i % 5 === 4 ? "" : "mr-10"} ${i > 4 ? "mt-10" : ""}`}
                    >
                      <p className="white">+</p>
                    </button>
                  )}
                  {tag.tagName && (
                    <button
                      onClick={() => handleFilterTag(tag)}
                      className={`tag_btn_fill mr-10 ${tag.selected ? "tag_btn_selected" : ""}   
                      } ${i > 4 ? "mt-10" : ""}`}
                    >
                      {tag.tagName}
                    </button>
                  )}
                </>
              ))} */}

              {tags?.map((tag: any, i: number) => (
                <>
                  {!tag.tagName && (
                    <button
                      onClick={() => {
                        // handleClickTag(tag, i);
                      }}
                      className={`tag_btn_block gray ${i % 5 === 4 ? "" : "mr-10"} ${
                        i > 4 ? "mt-10" : ""
                      }`}
                    >
                      <p style={{ color: "#ececec" }}>+</p>
                    </button>
                  )}
                  {tag.tagName && (
                    <button
                      onClick={() => {
                        handleFilterTag(tag);
                      }}
                      className={`tag_btn_fill ${tag.selected ? "tag_btn_selected" : ""} ${
                        i % 5 === 4 ? "" : "mr-10"
                      } ${i > 4 ? "mt-10" : ""}`}
                    >
                      {tag.tagName}
                    </button>
                  )}
                </>
              ))}
            </div>
          )}
        </div>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {projectData.map((data: any, index: number): any => {
          if (data.repeat) {
            return (
              <Link
                to={`/page/${data.projectInfo[0]?.hide ? "hiddens/" : ""}project/${
                  params.classId
                }/${data.projectId}/${data._id}`}
                key={index}
                className="display-f pl-10 pr-10 position-r"
              >
                {/* <p>{JSON.stringify(data)}</p> */}
                <div
                  className={`card project_in_student_repeat direction-column cursor border-${
                    data.borderState
                  } ${data.state === "" ? "yellow" : data.state === "check" ? "red" : "gray"}`}
                >
                  {data?.projectInfo[0]?.hide && (
                    <img src={hide} style={{ width: 20, height: 20 }} alt="hide" />
                  )}
                  <div className="pr-15 pl-15 text-overflow font-15 color-dark font-700 text-center">
                    {data.projectInfo[0].title}
                  </div>
                  <p style={{ fontSize: 24, fontWeight: "bold" }}>
                    {data.completeCount} / {data.repeatData.length}
                  </p>
                  {!data?.projectInfo[0]?.hide && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handlePushNotification(data.title);
                      }}
                      className="card_check_btn font-10 background-red-btn white"
                    >
                      Push
                    </button>
                  )}
                </div>
              </Link>
            );
          }

          if ((!data.type && data.state === "") || data.state === "reject") {
            return (
              <div key={index} className="display-f pl-10 pr-10 position-r">
                {data?.projectInfo[0]?.hide && (
                  <img
                    className="mr-10"
                    src={hide}
                    style={{ width: 16, height: 16, position: "absolute", top: 5, left: 15 }}
                    alt="hide"
                  />
                )}
                <div
                  onClick={() => {
                    handleProjectPopup(data, index);
                  }}
                  className={`card project_in_student background-yellow direction-column cursor`}
                  style={{
                    justifyContent: data?.projectInfo[0]?.hide ? "space-between" : "",
                    paddingBottom: 15,
                  }}
                >
                  <div
                    className="pr-15 pl-15 mb-9 text-overflow font-15 color-dark font-700 text-center"
                    style={{ height: 43 }}
                  >
                    {data.projectInfo[0]?.title}

                    <p className="text-center font-12 mt-5 font-bold">
                      {data.submissionTime && timeFormat11(data.submissionTime)}
                    </p>
                  </div>
                  <p
                    className="color-yellow font-500"
                    style={{ paddingBottom: data?.projectInfo[0]?.hide ? 10 : 0 }}
                  >
                    {/* 미제출 */}
                    {t(`Unsubmitted`)}
                  </p>
                  <p className="text-center font-12 mt-5 font-bold" style={{ marginBottom: 5 }}>
                    {data.submissionTime && timeFormat11(data.submissionTime)}
                  </p>
                  {!data?.projectInfo[0]?.hide && (
                    <button
                      onClick={() => handleProjectPopup(data, index)}
                      className="card_check_btn font-10 mb-8"
                    >
                      {/* 낸것으로 하기 */}
                      {t(`Checked that it was paid`)}
                    </button>
                  )}
                  {data?.projectInfo[0]?.hide && (
                    <button
                      onClick={() => handleProjectPopup(data, index)}
                      className="card_check_btn_t font-10 mb-8"
                    ></button>
                  )}
                  {!data?.projectInfo[0]?.hide && (
                    <button
                      onClick={(e: any) => {
                        e.stopPropagation();
                        handlePushNotification(data.title);
                      }}
                      className="card_check_btn font-10 background-red-btn white"
                    >
                      Push
                    </button>
                  )}
                </div>
              </div>
            );
          }
          if (!data.type && data.state === "check") {
            return (
              <div key={index} className="display-f pl-10 pr-10 position-r">
                {data?.projectInfo[0]?.hide && (
                  <img
                    className="mr-10"
                    src={hide}
                    style={{ width: 16, height: 16, position: "absolute", top: 5, left: 15 }}
                    alt="hide"
                  />
                )}
                <div
                  onClick={() => handleProjectPopup(data, index)}
                  className="card project_in_student background-red direction-column check_card"
                >
                  <div
                    className="pr-15 pl-15 direction-column justify-between display-f"
                    style={{ paddingBottom: 17, height: "100%" }}
                  >
                    <div>
                      <div className="text-overflow font-15 color-dark font-700 text-center">
                        {data.projectInfo[0].title}
                      </div>
                    </div>

                    <div className="text-center" style={{ margin: "18px 0 8px 0" }}>
                      <p className="color-red font-500">확인전</p>
                    </div>

                    <p className="text-center font-12 mt-5 font-bold">
                      {data.submissionTime && timeFormat11(data.submissionTime)}
                    </p>
                    <div className="text-center">
                      {!data.projectInfo[0]?.hide && (
                        <button className="card_check_btn font-10" style={{ textAlign: "center" }}>
                          확인하기
                        </button>
                      )}
                    </div>

                    {data.projectInfo[0]?.hide && <div style={{ height: 25 }}></div>}
                  </div>
                </div>
              </div>
            );
          }
          if (!data.type && data.state === "complete" && data.score !== "") {
            return (
              <div key={index} className="display-f pl-10 pr-10 position-r">
                {data?.projectInfo[0]?.hide && (
                  <img
                    className="mr-10"
                    src={hide}
                    style={{ width: 16, height: 16, position: "absolute", top: 5, left: 15 }}
                    alt="hide"
                  />
                )}
                <div
                  onClick={() => handleProjectPopup(data, index)}
                  className="card project_in_student background-gray direction-column"
                >
                  <div
                    className="pr-15 pl-15 display-f direction-column"
                    style={{ paddingBottom: 17, height: "100%" }}
                  >
                    <div style={{ flex: 1 }}>
                      <div className="text-overflow font-15 color-dark font-700 text-center">
                        {data.projectInfo[0].title}
                      </div>
                    </div>

                    <div style={{ flex: 1 }}>
                      {data.score !== "" && (
                        <p className="color-red font-30 font-700 text-center">{data.score}</p>
                      )}
                    </div>

                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                      }}
                    >
                      <p className="text-center font-12 mt-5 font-bold">
                        {data.submissionTime && timeFormat11(data.submissionTime)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          if (!data.type && data.state === "complete") {
            return (
              <div key={index} className="display-f pl-10 pr-10 position-r">
                {data?.projectInfo[0]?.hide && (
                  <img
                    className="mr-10"
                    src={hide}
                    style={{ width: 16, height: 16, position: "absolute", top: 5, left: 15 }}
                    alt="hide"
                  />
                )}
                <div
                  style={{ display: "flex", justifyContent: "space-between", paddingBottom: 17 }}
                  onClick={() => handleProjectPopup(data, index)}
                  className="card project_in_student background-gray direction-column font-bold"
                >
                  <div className="pr-15 pl-15 mb-15 text-overflow font-15 color-dark font-700 text-center">
                    {data.projectInfo[0].title}
                  </div>
                  {data.cookie === 1 && <img className="cookie" src={cookie1} alt="cookie1" />}
                  {data.cookie === 2 && <img className="cookie" src={cookie2} alt="cookie2" />}
                  {data.cookie === 3 && <img className="cookie" src={cookie3} alt="cookie3" />}

                  <p className="text-center font-12 mt-5">
                    {data.submissionTime && timeFormat11(data.submissionTime)}
                  </p>
                </div>
              </div>
            );
          }
          if (data.type === "checklist" && data.state === "") {
            return (
              <div key={index} className="display-f pl-10 pr-10 position-r">
                <div
                  onClick={(e: any) => {
                    e.preventDefault();
                    setChecklistPopup(true);
                    setSelectedChecklist(data);
                  }}
                  className="card project_in_student background-green direction-column cursor-none"
                >
                  <div
                    className="pr-15 pl-15 mb-27 text-overflow font-15 color-dark font-700 text-center"
                    style={{ height: 43 }}
                  >
                    {data.checklistInfo[0]?.title}
                  </div>

                  <button
                    onClick={(e: any) => {
                      e.preventDefault();
                      setChecklistPopup(true);
                      setSelectedChecklist(data);
                    }}
                    className="card_check_btn font-10 mb-8"
                  >
                    상세 기록
                  </button>
                  <button
                    onClick={(e) => handleEasyCheck(data, e)}
                    className="card_check_btn font-10 background-red-btn white"
                  >
                    {t(`Check`)}
                  </button>
                </div>
              </div>
            );
          }
          if (data.type === "checklist" && data.state !== "") {
            return (
              <div key={index} className="display-f pl-10 pr-10 position-r">
                <div
                  onClick={() => {
                    setChecklistPopup(true);
                    setSelectedChecklist(data);
                    // console.log(data);
                  }}
                  className="card project_in_student background-gray direction-column"
                >
                  <div
                    className="pr-15 pl-15 mb-15 text-overflow font-15 color-dark font-700 text-center"
                    style={{ height: 43 }}
                  >
                    {data.checklistInfo[0]?.title}
                  </div>
                  {data.state !== "complete" && (
                    <img
                      src={check2}
                      style={{ width: 20, height: 20, position: "absolute", left: 18, top: 6 }}
                    />
                  )}
                  {data.state !== "" && data.state !== "complete" && (
                    <div className="pl-15 pr-15 word-break">
                      <p className="color-red font-30 font-700">{data.state}</p>
                    </div>
                  )}

                  {data.state === "complete" && (
                    <img src={check2} style={{ width: 55, height: 55 }} />
                  )}
                </div>
              </div>
            );
          }
        })}
      </div>

      {/* 숨김과제 읽기전용 팝업 */}
      {hiddenPopup && (
        <HiddenProjectView
          selectedProjectTitle={selectedProjectTitle}
          rejectReason={rejectReason}
          score={score}
          feedback={feedback}
          cookieSelected={cookieSelected}
          confirmType={confirmType}
          handleAlbum={handleAlbum}
          handleClickOutside={() => setHiddenPopup(false)}
          projectOfStudentData={projectOfStudentData}
        />
      )}

      {/* confirm project popup */}
      {confirmProjectPopup && (
        <div className="popup">
          <div className="modal-overlay" onClick={(e: any) => handleClickOutside(e)}></div>
          <div ref={popupRef} className="projectOfStudentPopup scroll color-light-black">
            <p className="font-20 color-light-black mb-10 text-center">{selectedProjectTitle}</p>

            <div>
              {projectOfStudentData?.fileUrls?.map((aFile: any, index: number) => (
                <>
                  {(aFile.fileType === "image" ||
                    aFile.fileType === "video" ||
                    !aFile.fileType) && (
                    <div
                      key={index}
                      style={{
                        width: "49%",
                        verticalAlign: "top",
                        display: "inline-block",
                        marginRight: index % 2 === 0 ? 10 : 0,
                        marginBottom: 10,
                      }}
                    >
                      {!aFile.isVideo && (
                        <img
                          className="cursor"
                          onClick={() => handleAlbum(aFile)}
                          src={aFile.uri}
                          style={{ width: "100%", height: "auto", verticalAlign: "top" }}
                        />
                      )}
                      {aFile.isVideo && (
                        <video
                          controls
                          style={{ verticalAlign: "top" }}
                          onClick={() => handleAlbum(aFile)}
                          src={aFile.uri}
                          className="background-img student_submitted_image mb-10 cursor"
                          autoPlay={true}
                        ></video>
                      )}
                      {/* <p className="txt_line">{aFile.fileName}</p> */}
                      <p
                        onClick={() => window.open(aFile.uri)}
                        className="download_text cursor"
                        style={{ textAlign: "center" }}
                      >
                        다운받기
                      </p>
                    </div>
                  )}
                </>
              ))}
              {projectOfStudentData?.fileUrls?.filter(
                (el: any, i: number) =>
                  el.fileType !== "image" && el.fileType !== "video" && el.fileType,
              ).length !== 0 && (
                <div style={{ marginBottom: 10 }}>
                  <p style={{ fontWeight: 500, marginBottom: 10 }}>
                    첨부파일
                    <span style={{ fontSize: 12 }}>(아래 파일명을 클릭하면 다운로드됩니다.)</span>
                  </p>
                </div>
              )}

              {projectOfStudentData?.fileUrls?.map((aFile: any, index: number) => (
                <>
                  {aFile.fileType !== "image" && aFile.fileType !== "video" && aFile.fileType && (
                    <div
                      onClick={() => window.open(aFile.uri)}
                      key={index}
                      className="file_ex_container cursor"
                    >
                      <img src={file_icon} style={{ width: 25, height: "auto", marginRight: 5 }} />
                      <p>{aFile.fileName}</p>
                    </div>
                  )}
                </>
              ))}
            </div>

            {/* {studentBodyFalse && ( */}
            <div className="project_list mb-7" style={{ borderRadius: 10 }}>
              <p>
                {projectOfStudentData.studentBody && (
                  <div
                    className="cursor"
                    dangerouslySetInnerHTML={replace(projectOfStudentData.studentBody)}
                  ></div>
                )}
              </p>
            </div>

            {projectOfStudentData?.submissionTime && (
              <p className="mb-7 font-bold">
                {timeFormat11(projectOfStudentData.submissionTime)} 제출
              </p>
            )}
            {/* )} */}

            {confirmType !== "reject" && (
              <div className="display-f  align-center mb-20">
                <p className="font-15 font-500 mr-33">피드백</p>
                <textarea
                  value={feedback}
                  onChange={(e: any) => setFeedback(e.target.value)}
                  className="feedback__text"
                  name="feedback"
                  readOnly={confirmType === "reject" && true}
                  cols={20}
                ></textarea>
              </div>
            )}
            <div className="display-f justify-between align-center mb-28">
              <button
                onClick={() => handleConfirmTypeChange(1)}
                className={`focus project__sign__btn light-black ${
                  confirmType === "cookie" && "confirm_selected"
                }`}
              >
                쿠키
              </button>
              <button
                onClick={() => handleConfirmTypeChange(2)}
                className={`focus project__sign__btn light-black ${
                  confirmType === "score" && "confirm_selected"
                }`}
              >
                성적
              </button>
              <button
                onClick={() => handleConfirmTypeChange(3)}
                className={`focus project__sign__btn light-black project_reject_btn ${
                  confirmType === "reject" && "confirm_reject_selected"
                }`}
              >
                반려
              </button>
            </div>
            <div className="mb-40">
              {confirmType === "cookie" && (
                <div className="display-f align-center">
                  <button
                    onClick={() => setCookieSelected(1)}
                    className={`${
                      cookieSelected !== 1 && "cookie_border_none"
                    } cookie_circle cursor display-f-center mr-17`}
                  >
                    {cookieSelected === 1 ? (
                      <img className="cookie1" src={cookie1S} alt="cookie1S" />
                    ) : (
                      <img className="cookie1" src={cookie1G} alt="cookie1G" />
                    )}
                  </button>
                  <button
                    onClick={() => setCookieSelected(2)}
                    className={`${
                      cookieSelected !== 2 && "cookie_border_none"
                    } cookie_circle cursor display-f-center mr-17`}
                  >
                    {cookieSelected === 2 ? (
                      <img className="cookie2" src={cookie2S} alt="cookie2S" />
                    ) : (
                      <img className="cookie2" src={cookie2G} alt="cookie2G" />
                    )}
                  </button>
                  <button
                    onClick={() => setCookieSelected(3)}
                    className={`${
                      cookieSelected !== 3 && "cookie_border_none"
                    } cookie_circle cursor display-f-center`}
                  >
                    {cookieSelected === 3 ? (
                      <img className="cookie3" src={cookie3S} alt="cookie3S" />
                    ) : (
                      <img className="cookie3" src={cookie3G} alt="cookie3G" />
                    )}
                  </button>
                </div>
              )}
              {confirmType === "score" && (
                <div className="display-f align-center">
                  <p className="mr-23 font-500 font-15">성적 입력</p>
                  <input
                    value={score}
                    maxLength={4}
                    onChange={(e: any) => setScore(e.target.value)}
                    className="score_input"
                    type="text"
                  />
                </div>
              )}
              {confirmType === "reject" && (
                <>
                  <div className="display-f align-center justify-between">
                    <p className="font-500 font-15">반려 사유</p>
                    <textarea
                      value={rejectReason}
                      onChange={(e: any) => setRejectReason(e.target.value)}
                      className="reject__reason__text"
                      name="rejectReason"
                    />
                  </div>
                </>
              )}
            </div>
            <div className="display-f-center">
              <button onClick={handleConfirmProject} className="popup__bottom__btn mr-14 ">
                {t(`Confirm`)}
              </button>
              <button onClick={handleCancelConfirmPopup} className="popup__bottom__btn">
                {t(`Cancel`)}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* checklist popup */}
      {checklistPopup && (
        <div className="popup">
          <div className="modal-overlay" onClick={(e: any) => handleClickOutside(e)}></div>
          <div
            ref={checklistPopupRef}
            className="project_add_popup scroll popup_column_sb"
            style={{ backgroundColor: "rgba(246, 248, 250)" }}
          >
            <div>
              <h2 className="text-center">{selectedChecklist.checklistInfo[0]?.title}</h2>
              <div
                style={{
                  paddingTop: 20,
                  padding: "20px 0 20px 10px",
                  backgroundColor: "#fff",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedChecklist.checklistInfo[0]?.description,
                  }}
                />
                {selectedChecklist.checklistInfo[0]?.file.length !== 0 && (
                  <img src={selectedChecklist.checklistInfo[0]?.file[0]} style={{ width: "40%" }} />
                )}
              </div>
              <div>
                <div className="display-f align-center">
                  <h3>상세 기록</h3>
                  <button onClick={handleSave} className="checklist-save-btn ml-15">
                    기록 저장
                  </button>
                  {save && (
                    <p className="ml-7" style={{ color: "red", fontSize: 10 }}>
                      기록이 저장되었습니다.
                    </p>
                  )}
                  {!save && (
                    <p className="ml-7" style={{ color: "#979797", fontSize: 10 }}>
                      기록 저장을 누르지 않으면, 상세 기록이 저장되지 않습니다.
                    </p>
                  )}
                </div>
                <textarea
                  value={selectedChecklist.description}
                  onChange={(e) => {
                    setSelectedChecklist((prev: any) => {
                      return {
                        ...prev,
                        description: e.target.value,
                      };
                    });
                  }}
                  rows={16}
                  className="popup-checklist-textarea"
                />
              </div>
            </div>

            <div>
              <div className="pt-20 pb-20 display-f align-center">
                <p className="mr-20">간단 메모</p>
                <input
                  value={selectedChecklist.state === "complete" ? "" : selectedChecklist.state}
                  className="popup-scrore-input"
                  onChange={(e) => {
                    setSelectedChecklist((prev: any) => {
                      return {
                        ...prev,
                        state: e.target.value,
                      };
                    });
                  }}
                />
              </div>

              <div className="display-f justify-center mt-20 mb-10">
                <button
                  onClick={handleSubmitChecklist}
                  className="project__sign__btn background-red-btn mr-10 color-white"
                >
                  {t(`Check`)}
                </button>
                <button
                  onClick={handleUnSubmit}
                  className="project__sign__btn background-green-btn color-white"
                >
                  {t(`Cancel Check`)}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {studentAddPopup && (
        <div className="popup">
          <div className="modal-overlay" onClick={(e: any) => handleClickOutside(e)}></div>
          <div
            onClick={(e: any) => handleClickOutside(e)}
            ref={studentAddPopupRef}
            className="main_popup scroll"
          >
            <p className="text-center font-23 mb-26">학생 수정</p>
            <div className="pl-36 pr-36 font-15">
              <div className="mb-30">
                <p className="color-light-black mb-8">학생이름 (*)</p>
                <input
                  value={popupStudentNameInput}
                  onChange={(e: any) => setPopupStudentNameInput(e.target.value)}
                  className="mb-25 popup_input_default"
                  type="text"
                />
                <div className="display-f mb-25">
                  <div className="mr-23">
                    <p className="color-light-black mb-8">학생번호</p>
                    <input
                      value={popupStudentNumberInput}
                      onChange={(e: any) => setPopupStudentNumberInput(e.target.value)}
                      className="popup_input_default popup_student"
                      type="text"
                    />
                  </div>
                  {/* <div>
                    <p className="color-light-black mb-8">성별</p>
                    <div className="position-r display-f">
                      <input
                        className="popup_input_default popup_student cursor-default"
                        value={gender}
                        readOnly
                        type="text"
                      />
                      <img
                        onClick={() => setGenderDropped((prev) => !prev)}
                        className="popup_down position-a cursor"
                        src={down}
                        alt="down"
                      />
                      {genderDropped && (
                        <div ref={popupGenderRef} className="position-a popup_gender_dropdown">
                          {genderDropdown.map((data: string, index: number) => (
                            <p
                              onClick={() => handleGenderSelect(index)}
                              className="popup_gender_dropdown_contents text-center cursor"
                              key={index}
                            >
                              {data}
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
                <p className="color-light-black mb-8">휴대폰 번호</p>
                <div className="position-r display-f">
                  <input
                    placeholder={`입력하지 않아도 됨. 입력시 '-' 제외`}
                    style={{ width: "100%" }}
                    className="popup_input_default popup_student"
                    value={phone}
                    onChange={(e: any) => setPhone(e.target.value)}
                    // readOnly
                    type="text"
                  />
                </div>
                {/* <div className="display-f align-center mb-8">
                  <p className="color-light-black mr-5">전학일</p>
                  <div
                    onClick={() => setCheckboxChecked((prev) => !prev)}
                    className="popup_checkbox position-r cursor"
                  >
                    {checkboxChecked && <div className="square_check position-a"></div>}
                  </div>
                </div>
                <input
                  value={popupTransterInput}
                  onChange={(e: any) => setPopupTransferInput(e.target.value)}
                  className="popup_input_default mb-8"
                  disabled={!checkboxChecked}
                  type="date"
                />
                <p className="font-12">* 전학생일 때만 설정해 주세요.</p> */}
              </div>
              <div className="popup__bottom text-center mb-20">
                <button onClick={handleEditStudent} className="focus popup__bottom__btn mr-14">
                  {t(`Confirm`)}
                </button>
                <button
                  onClick={() => setStudentAddPopup(false)}
                  className="focus popup__bottom__btn"
                >
                  {t(`Cancel`)}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {useCookiePopup && (
        <div onClick={(e: any) => handleClickOutside(e)} className="popup">
          <div
            onClick={(e: any) => handleClickOutside(e)}
            ref={useCookiePopupRef}
            className="main_popup_flex_cookie scroll"
          >
            <div style={{ position: "relative" }}>
              {/* <p className="text-center font-23 mb-26">{`${
                studentData[0].num ? `(${studentData[0].num})번` : ""
              } ${studentData[0].name}`}</p> */}

              <p className="text-center font-18 mb-10">
                {t(`received cookies`)} {studentData.cookie}
                {t(`pcs.(Pieces)`)}
              </p>
              <div
                className="font-300"
                style={{ position: "absolute", fontSize: 30, top: 26, left: 50 }}
              >
                -
              </div>
              <p className="text-center font-18 mb-10">
                쓴 쿠키 {studentData.usedCookie ? studentData.usedCookie : 0}
                {t(`pcs.(Pieces)`)}
              </p>
              <div
                className="font-300"
                style={{ position: "absolute", fontSize: 30, top: 62, left: 50 }}
              >
                -
              </div>
              <p className="text-center font-18 mb-20 font-500">
                쿠키 더 쓰기
                <input
                  className="use_cookie_input"
                  value={usingCookie}
                  // type="number"
                  onChange={handleUseCookie}
                />
                {t(`pcs.(Pieces)`)}
              </p>
              <div className="rest_section" />

              <p className="text-center font-18 mt-10 font-500">
                {/* 남은 쿠키 calcCookie 개 */}
                {t(`remaining cookies`)} {calcCookie()}
                {t(`pcs.(Pieces)`)}
              </p>

              <div style={{ padding: "0 20px" }}>
                <div className="font-16 mt-30">쿠키 사용 설명</div>

                <div className="flex-1">
                  <input
                    value={reportBody}
                    onChange={(e: any) => setReportBody(e.target.value)}
                    className="border-b p4 input-default"
                  />
                </div>
              </div>
            </div>

            <div className="popup__btns text-center">
              <p style={{ fontSize: 12, color: "#979797" }}>
                {/* '쿠키 쓰기'를 되돌리고 싶으면, 숫자 앞에 -를 붙여 쓰세요 */}
                {t(`When restoring 'Spent Cookies', attach '-' to the input number.`)}
              </p>
              <div style={{ marginTop: 8 }}>
                <button
                  onClick={handleUsingCookies}
                  className="focus popup__bottom__btn__brown mr-14"
                >
                  {t(`Confirm`)}
                </button>
                <button
                  onClick={() => {
                    setUseCookiePopup(false);
                    setUsingCookie("");
                  }}
                  className="focus popup__bottom__btn__gray"
                >
                  {t(`Cancel`)}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Student;
