import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  Link,
  NavLink,
  useLocation,
} from "react-router-dom";
import { MainContext } from "./common/Context";
import { IMainContext } from "./interface/Interface";
import Login from "./pages/Login";
import Hidden from "./pages/Hidden";
import Main from "./pages/Main";
import Projects from "./pages/Projects";
import Students from "./pages/Students";
import nav1 from "./images/nav1.png";
import nav2 from "./images/nav2.png";
import nav3 from "./images/nav3.png";
import nav4 from "./images/nav4.png";
import nav_notice from "./images/nav_notice.png";
import cookie1 from "./images/1.png";
import cookie2 from "./images/2.png";
import cookie3 from "./images/3.png";
import cookie4 from "./images/4.png";
import cookie5 from "./images/5.png";
import cookie6 from "./images/6.png";
import cookie7 from "./images/7.png";
import cookie8 from "./images/8.png";
import cookie9 from "./images/9.png";
import cookie10 from "./images/10.png";
import cookie11 from "./images/11.png";
import cookie12 from "./images/12.png";
import cookie13 from "./images/13.png";
import cookie14 from "./images/14.png";
import cookie15 from "./images/15.png";
import badge from "./images/badge.png";
import Project from "./pages/Project";
import Student from "./pages/Student";
import SignUp from "./pages/SignUp";
import FindIdPw from "./pages/FindIdPw";
import ChangePw from "./pages/ChangePw";
import {
  getClassInfo,
  getStudents,
  postStackRecord,
  putClassCookies,
  putModifyClassTargetAction,
} from "./common/Action";
import Kakao from "./pages/Kakao";
import Badges from "./pages/Badges";
import CheckList from "./pages/CheckList";
import KakaoApp from "./pages/KakaoApp";
import ChecklistDetail from "./pages/ChecklistDetail";
import Notice from "./pages/Notice";
import NoticeDetail from "./pages/NoticeDetail";
import RepeatDetail from "./pages/RepeatDetail";
import { consoleLog, getTotalCookies } from "./common/Method";
import Toggle from "./component/Toggle";
import FrameWeb from "./pages/FrameWeb";
import HiddenNotice from "./pages/HiddenNotice";
import HiddenChecklist from "./pages/HiddenChecklist";
import ClassReport from "./pages/ClassReport";
import CheckListFolder from "./pages/ChecklistFolder";
import Modal from "./component/Modal";
import StudentReport from "./pages/StudentReport";
import OAuthSignin from "./pages/OAuthSignin";
import KakaoSingout from "./pages/KakaoSingout";
import PayPlan from "./pages/PayPlan";
import AuthEdit from "./pages/AuthEdit";
import PayPlanMain from "./pages/PayPlanMain";
import PayPlanOrganization from "./pages/PayPlanOrganization";
import MainHeader from "./pages/MainHeader";
import Payment from "./pages/Payment";
import Buy from "./pages/Buy";
import { t } from "i18next";
import moment from "moment";

const Navigation: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={MainHeader} />

        <Route path="/hiddenclass" exact component={MainHeader} />
        <Route path="/auth/kakaoapp" component={KakaoApp} />
        <Route path="/auth/kakao" component={Kakao} />
        <Route path="/auth/kakaosignout" component={KakaoSingout} />
        <Route path="/signup" exact component={SignUp} />
        <Route path="/find-id-pw" exact component={FindIdPw} />
        <Route path="/auth/changepw/:token" component={ChangePw} />
        <Route path="/login" component={Login} />

        {/* 결제페이지 */}
        <Route path="/payment" exact component={MainHeader} />
        <Route path="/payplan" exact component={MainHeader} />
        <Route path="/plan/organization" exact component={MainHeader} />
        <Route path="/plan/private" exact component={MainHeader} />
        <Route path="/plan/addserialnum" exact component={MainHeader} />

        <Route path="/oauth/signin" component={OAuthSignin} />
        <Route path="/oauth/edit" component={MainHeader} />
        <Route path="/buy/:trackingid/:uid" component={Buy} />
        <Route path="/page" component={Sidebar} />
      </Switch>
    </Router>
  );
};

const Sidebar: React.FC = () => {
  let match = useRouteMatch();
  const location = useLocation();
  const context = useContext<IMainContext>(MainContext);
  const [classId, setClassId] = useState<string>("");
  const [totalCookies, setTotalCookies] = useState<any>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [targetCount, setTargetCount] = useState<string>("");
  const [targetAction, setTargetAction] = useState<string>("");
  const [imgNumber, setImgNumber] = useState<any>(1);
  const [hide, setHide] = useState<string>("true");
  const inputRef = useRef<HTMLInputElement>(null);
  const [warning, setWarning] = useState<boolean>(false);
  const [classData, setClassData] = useState<any>({});
  const [classCookieUsingPopup, setClassCookieUsingPopup] = useState<boolean>(false);
  const [usingCookie, setUsingCookie] = useState<number>(0);
  const [reportBody, setReportBody] = useState<string>("");

  useLayoutEffect((): void => {
    init();
  }, [context.class]);

  useEffect(() => {
    if (!location.pathname.includes("student") || !location.pathname.includes("project")) {
      context.handleStateChange("easyCookieEdit", false);
      context.handleStateChange("easyReport", false);
      context.handleStateChange("easyCookieUse", false);
      context.handleStateChange("easyConfirmPos", false);
    }
  }, [location.pathname]);

  const init = async (): Promise<any> => {
    let classId = "";
    const pathname = location.pathname.split("/");
    for (let i in pathname) {
      if (
        pathname[i] !== "checklistdetail" &&
        pathname[i] !== "studentreport" &&
        pathname[i] !== "noticedetail" &&
        pathname[i].length > 10
      ) {
        classId = pathname[i];
        break;
      }
    }

    const classDataResult: any = await getClassInfo(classId);
    context.handleStateChange("class", classDataResult?.title);
    setClassId(classId);
    setClassData(classDataResult);
    const studentList: any = await getStudents(classId);
    context.handleStateChange("students", studentList);

    setTotalCookies(classDataResult?.cookies);
    if (classDataResult?.targetCookies) {
      setTargetCount(classDataResult.targetCookies);
      setTargetAction(classDataResult.targetAction);

      const restCookies =
        classDataResult.cookies - (classDataResult.usedCookie ? classDataResult.usedCookie : 0);

      const targetPercent = (Number(restCookies) / Number(classDataResult.targetCookies)) * 100;

      let count = 1;
      for (let i = 1; i <= 15; i++) {
        if (i !== 1 && i !== 15 && targetPercent <= i * 6.7 && targetPercent > (i - 1) * 6.7) {
          count = i;
        }
        if (i === 15 && targetPercent < 100 && targetPercent > 14 * 6.7) {
          count = 14;
        }
        if (i === 15 && (targetPercent >= 15 * 6.7 || targetPercent === 100)) {
          count = 15;
        }
      }

      setImgNumber(count);
    }

    if (classDataResult?.cookieHidden === "false") {
      setHide("false");
    } else {
      setHide("true");
    }
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [editMode]);

  const handleEditMode = async (): Promise<any> => {
    let classId = "";
    const pathname = location.pathname.split("/");
    for (let i in pathname) {
      if (pathname[i].length > 10) {
        classId = pathname[i];
        break;
      }
    }

    setEditMode((prev: any) => !prev);

    if (editMode) {
      context.handleStateChange("class", "");

      await putModifyClassTargetAction({
        id: classId,
        targetCookies: targetCount,
        targetAction,
        cookieHidden: hide,
      });
    }
    setWarning(false);
  };

  const handleHide = () => {
    if (hide === "true") {
      setHide("false");
      setWarning(false);
    } else {
      setHide("true");
      setWarning(true);
    }
  };

  const handleUseCookie = (e: any): void => {
    const numParse = parseInt(e.nativeEvent.data);
    if (e.nativeEvent.data === null || e.nativeEvent.data === "-") {
      setUsingCookie(e.target.value);
    }

    if (
      !isNaN(numParse) &&
      totalCookies -
        parseInt(classData.usedCookie ? classData.usedCookie : 0) -
        parseInt(e.target.value) >=
        0 &&
      (classData.usedCookie ? classData.usedCookie : 0) + parseInt(e.target.value) >= 0
    ) {
      setUsingCookie(e.target.value);
    }
  };

  const calcCookie = (): number => {
    const n =
      Number(totalCookies) -
      (classData.usedCookie ? classData.usedCookie : 0) -
      (Number(usingCookie) ? Number(usingCookie) : 0);
    // if (n < 0) {
    //   return "남은 쿠키가 없습니다.";
    // } else {
    return n;
    // }
  };

  const handleUseClassCookie = async (): Promise<void> => {
    // 우리반 쿠키 변경
    const cookieMore = isNaN(Number(usingCookie)) ? 0 : Number(usingCookie);
    const putCookiesStackResult: any = await postStackRecord({
      studentsCode: [],
      body: {
        reportBody,
        tags: [],
        classId,
        reportDate: moment().valueOf(),
        increaseCookie: cookieMore > 0 ? -cookieMore : cookieMore,
        studentsCode: [],
        type: "useCookies",
        classCookie: true,
        useCookie: cookieMore > 0 ? true : false,
      },

      useCookie: cookieMore > 0 ? true : false,
      increaseCookie: cookieMore > 0 ? -cookieMore : cookieMore,
    });

    if (Object.keys(putCookiesStackResult).length !== 0) {
      setClassCookieUsingPopup(false);
      setUsingCookie(0);
      init();
    }
  };

  const handleCheckNotice = (e: any) => {
    if (context.noticeBookBody !== "") {
      // const confirm = window.confirm("수정사항이 있습니다.\n저장하지 않고 나가시겠습니까?");
      const confirm = window.confirm(
        t(`There are corrections. Are you sure you want to leave without saving`),
      );
      if (confirm) {
        context.handleStateChange("noticeBookBody", "");
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    } else {
      return;
    }
  };

  if (!context.isUser) return <Redirect to="/login" />;

  return (
    <>
      {classCookieUsingPopup && (
        <Modal
          show={classCookieUsingPopup}
          onClose={() => {
            setClassCookieUsingPopup(false);
          }}
        >
          <div className="stack_report_container sidebar_cookie_popup">
            <h2 className="text-center">{t(`Our class cookie jar`)}</h2>

            <div style={{ position: "relative" }}>
              {/* <p className="text-center font-23 mb-26">{`${
                studentData[0].num ? `(${studentData[0].num})번` : ""
              } ${studentData[0].name}`}</p> */}

              <p className="text-center font-18 mb-10">
                {t(`cumulative cookies received`)} {totalCookies ? totalCookies : 0}
                {t(`pcs.(Pieces)`)}
              </p>
              <div
                className="font-300"
                style={{ position: "absolute", fontSize: 30, top: 26, left: 50 }}
              >
                -
              </div>
              <p className="text-center font-18 mb-10">
                {t(`cookies have been spent`)} {classData.usedCookie ? classData.usedCookie : 0}
                {t(`pcs.(Pieces)`)}
              </p>
              <div
                className="font-300"
                style={{ position: "absolute", fontSize: 30, top: 62, left: 50 }}
              >
                -
              </div>
              <p className="text-center font-18 mb-20 font-500">
                {t(`Spend cookies`)}
                <input
                  style={{ backgroundColor: "#fff", textAlign: "center" }}
                  className="use_cookie_input"
                  // type="number"
                  value={usingCookie}
                  // type="number"
                  onChange={handleUseCookie}
                />
                {t(`pcs.(Pieces)`)}
              </p>
              <div className="rest_section" />

              <p className="text-center font-18 mt-10 font-500">
                {/* 남은 쿠키 calcCookie 개*/}
                {t(`remaining cookies`)} {calcCookie()} {t(`pcs.(Pieces)`)}
              </p>

              <div className="font-16 mt-30">쿠키 사용 설명</div>

              <div className="flex-1">
                <input
                  value={reportBody}
                  onChange={(e: any) => setReportBody(e.target.value)}
                  className="border-b p4"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="popup__btns text-center mt-20">
              <p style={{ fontSize: 12, color: "#979797" }}>
                {t(`When restoring 'Spent Cookies', attach '-' to the input number.`)}
              </p>
              <div style={{ marginTop: 8 }}>
                <button
                  onClick={handleUseClassCookie}
                  className="focus popup__bottom__btn__brown mr-14"
                >
                  {t(`Use cookies`)}
                </button>
                <button
                  className="focus popup__bottom__btn__gray"
                  onClick={() => setClassCookieUsingPopup(false)}
                >
                  {t(`Cancel`)}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <div className="bodyContainerWrapper">
        {/* mobile */}
        <div className="sidebar-top-m">
          <div style={{ width: "20%" }}>
            <Link to={`${match.url}/badges/${classId}`}>
              <img
                className="cursor"
                src={badge}
                alt="badge"
                style={{ width: 60, height: "auto" }}
              />
            </Link>
          </div>

          <div
            className="text-center"
            style={{ fontWeight: "bold", fontSize: 18, flex: 1, width: "60%" }}
          >
            <p>{context.class}</p>
          </div>

          <div style={{ width: "20%", textAlign: "right" }}>
            <Link to="/">
              <p className="font-13 font-300 black cursor display-ib" style={{ flex: 1 }}>
                {t(`Home (Return to My Class list)`)}
              </p>
            </Link>
          </div>
        </div>

        <div className="bodyContainer" style={{ display: "flex" }}>
          <div className="sidebarBox sidebar" style={{ width: "20%" }}>
            {(context.easyCookieEdit || context.easyReport || context.easyCookieUse) && (
              <div className="sidebar_overlay" />
            )}
            {/* nav content */}
            <div>
              {/* pc */}
              <div className="sidebar-top">
                <div
                  className="display-f direction-column justify-center"
                  style={{ padding: "20px 0" }}
                >
                  <p className="font-18 font-500 mb-7">{context.class}</p>
                  <Link to="/">
                    <p
                      onClick={(e: any) => handleCheckNotice(e)}
                      className="font-13 font-300 black cursor display-ib"
                    >
                      {t(`Home (Return to My Class list)`)}
                    </p>
                  </Link>
                </div>
                <Link to={`${match.url}/badges/${classId}`}>
                  <img
                    onClick={(e: any) => handleCheckNotice(e)}
                    className="cursor"
                    alt="cursor"
                    src={badge}
                    style={{ width: 100, height: 100 }}
                  />
                </Link>
              </div>

              {/* push notification toggle */}
              {/* <div className="sidebar-top2">
              <div
                style={{
                  width: "100%",
                  textAlign: "right",
                  display: "flex",
                }}
              >
                <Toggle />
              </div>
            </div> */}

              <div className="nav_left_container">
                {/* <div> */}
                <NavLink
                  className={`${
                    window.location.pathname.includes("student") &&
                    !window.location.pathname.includes("studentreport") &&
                    // window.location.pathname.includes("report")) &&
                    "active"
                  }`}
                  to={`${match.url}/students/${classId}`}
                >
                  <div
                    onClick={(e: any) => handleCheckNotice(e)}
                    className="sidebar_nav text-left pl-40 cursor display-f align-center"
                  >
                    <img className="nav2 mr-15" src={nav2} alt="nav2" />
                    <p className="font-15 sidebar_contents">{t(`View by Student`)}</p>
                  </div>
                </NavLink>

                <NavLink
                  className={`${
                    (window.location.pathname.includes("project") ||
                      (window.location.pathname.includes("hidden") &&
                        !window.location.pathname.includes("notice") &&
                        window.location.pathname.includes("hidden") &&
                        !window.location.pathname.includes("checklist"))) &&
                    "active"
                  }`}
                  to={`${match.url}/projects/${classId}`}
                >
                  <div
                    onClick={(e: any) => handleCheckNotice(e)}
                    className="sidebar_nav text-left pl-40 cursor display-f align-center"
                  >
                    <img className="nav1 mr-15" src={nav1} alt="nav1" />
                    <p className="font-15 sidebar_contents">{t(`View by Assignment`)}</p>
                  </div>
                </NavLink>

                <NavLink
                  className={`${window.location.pathname.includes("notice") && "active"}`}
                  to={`${match.url}/notice/${classId}`}
                >
                  <div
                    onClick={(e: any) => handleCheckNotice(e)}
                    className="sidebar_nav text-left pl-40 cursor display-f align-center"
                  >
                    <img className="nav_notice mr-15" src={nav_notice} alt="nav_notice" />
                    {/* 알림장 */}
                    <p className="font-15 sidebar_contents">{t(`Notice`)}</p>
                  </div>
                </NavLink>

                <NavLink
                  className={`${window.location.pathname.includes("checklist") && "active"}`}
                  to={`${match.url}/checklist/${classId}`}
                >
                  <div
                    onClick={(e: any) => handleCheckNotice(e)}
                    className="sidebar_nav text-left pl-40 cursor display-f align-center"
                  >
                    <img className="nav_checklist mr-15" src={nav4} alt="nav4" />
                    {/* 체크리스트 */}
                    <p className="font-15 sidebar_contents">{t(`Checklist`)}</p>
                  </div>
                </NavLink>

                {/* <NavLink
                className={`${window.location.pathname.includes("hidden") && "active"}`}
                to={`${match.url}/hiddens/${classId}`}
              >
                <div className="sidebar_nav text-left pl-40 cursor display-f align-center">
                  <img className="nav3 mr-16" src={nav3} alt="nav3" />
                  <p className="font-15 sidebar_contents">숨긴 과제 보관함</p>
                </div>
              </NavLink> */}

                <NavLink
                  // className={`${window.location.pathname.includes("homeworks") && "active"}`}
                  className={`${
                    window.location.pathname.includes("report") &&
                    !window.location.pathname.includes("studentreport") &&
                    "active"
                  }`}
                  to={`${match.url}/report/${classId}/tab1`}
                >
                  <div
                    onClick={(e: any) => handleCheckNotice(e)}
                    className="sidebar_nav text-left pl-40 cursor display-f align-center"
                  >
                    <img className="nav3 mr-16" src={nav3} alt="nav3" />
                    <p
                      className="font-15 sidebar_contents"
                      style={{ color: "#3cb3e2", fontWeight: 500 }}
                    >
                      {t(`My Class Report`)}
                    </p>
                  </div>
                </NavLink>
              </div>
            </div>

            <div className="text-center position-r cookie_container_p">
              {hide === "true" && <div></div>}
              {hide === "false" && (
                <div>
                  {/* 우리반 쿠키 총 */}
                  <p className="font-18 font-500 mb-5">
                    {t(`Total of Class Cookies`)} {calcCookie()}
                    {t(`pcs.(Pieces)`)}
                  </p>
                  {!editMode && (
                    <>
                      <p className="font-16 mb-5">
                        <span className="font-500" style={{ color: "#9B7651" }}>
                          {/* targetCount 개 */}
                          {targetCount === "" ? "n" : targetCount}
                          {t(`pcs.(Pieces)`)}{" "}
                        </span>
                        {/* 모으면 */}
                        <span style={{ fontSize: 13 }}>{t(`If we reach`)}</span>
                      </p>
                      <p className="font-16 font-500" style={{ color: "#9B7651" }}>
                        {/* 공약 문구를 정해 주세요! */}
                        {targetAction === ""
                          ? t(`Please decide on the pledge phrase`)
                          : targetAction}
                      </p>
                    </>
                  )}

                  {editMode && (
                    <>
                      <p className="font-16 mb-5">
                        <input
                          value={targetCount}
                          onChange={(e) => setTargetCount(e.target.value)}
                          ref={inputRef}
                          className="cookieInput text-center"
                        />
                        {/* ~개 모으면*/}
                        {t(`pcs.(Pieces)`)} <span style={{ fontSize: 13 }}>{t(`If we reach`)}</span>
                      </p>
                      <input
                        value={targetAction}
                        onChange={(e) => setTargetAction(e.target.value)}
                        className="text-center targetActionInput"
                        // 공약 문구를 입력해 주세요.
                        placeholder={t(`Please enter the pledge phrase`)}
                      />
                    </>
                  )}
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    {(context.easyCookieEdit || context.easyReport) && (
                      <span
                        onClick={() =>
                          context.handleStateChange("selectClassCookie", !context.selectClassCookie)
                        }
                        className="position-a check cursor"
                        style={{ left: 30 }}
                      >
                        {context.selectClassCookie && (
                          <span className={`check__inside position-a bg-checked`} />
                        )}
                      </span>
                    )}

                    {imgNumber === 1 && (
                      <img
                        src={cookie1}
                        alt="cookie1"
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    )}
                    {imgNumber === 2 && (
                      <img
                        src={cookie2}
                        alt="cookie2"
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    )}
                    {imgNumber === 3 && (
                      <img
                        src={cookie3}
                        alt="cookie3"
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    )}
                    {imgNumber === 4 && (
                      <img
                        src={cookie4}
                        alt="cookie4"
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    )}
                    {imgNumber === 5 && (
                      <img
                        src={cookie5}
                        alt="cookie5"
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    )}
                    {imgNumber === 6 && (
                      <img
                        src={cookie6}
                        alt="cookie6"
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    )}
                    {imgNumber === 7 && (
                      <img
                        src={cookie7}
                        alt="cookie7"
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    )}
                    {imgNumber === 8 && (
                      <img
                        src={cookie8}
                        alt="cookie8"
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    )}
                    {imgNumber === 9 && (
                      <img
                        src={cookie9}
                        alt="cookie9"
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    )}
                    {imgNumber === 10 && (
                      <img
                        src={cookie10}
                        alt="cookie10"
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    )}
                    {imgNumber === 11 && (
                      <img
                        src={cookie11}
                        alt="cookie11"
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    )}
                    {imgNumber === 12 && (
                      <img
                        src={cookie12}
                        alt="cookie12"
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    )}
                    {imgNumber === 13 && (
                      <img
                        src={cookie13}
                        alt="cookie13"
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    )}
                    {imgNumber === 14 && (
                      <img
                        src={cookie14}
                        alt="cookie14"
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    )}
                    {imgNumber === 15 && (
                      <img
                        src={cookie15}
                        alt="cookie15"
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    )}
                  </div>
                </div>
              )}

              <div
                className="display-f justify-between"
                style={{
                  padding: "0 10px 10px 10px",
                }}
              >
                <div style={{ flex: 1 }}>
                  {editMode && (
                    <button onClick={handleHide} className="text-right hideBtn">
                      {hide === "false" ? t(`Hide`) : t(`Show`)}
                    </button>
                  )}
                </div>

                <div className={`hs ${warning ? "go" : ""}`} onClick={() => setWarning(true)}>
                  '다했어요!'앱에서 쿠키함이 보이지 않게 됩니다.
                </div>

                {editMode && (
                  <div className="text-center" style={{ flex: 2 }}>
                    <button
                      onClick={() => setClassCookieUsingPopup(true)}
                      style={{ backgroundColor: "#cc9966" }}
                      className="editBtn"
                    >
                      {t(`Empty the Cookie Jar`)}
                    </button>
                  </div>
                )}

                <div style={{ flex: 1 }} className="text-right">
                  <button onClick={handleEditMode} className="editBtn">
                    {editMode ? t(`Confirm`) : t(`Edit`)}
                  </button>
                </div>
              </div>
            </div>

            {/* 쿠키함 mobile */}
            <div className="cookie_container_m">
              <p>쿠키함</p>
            </div>
          </div>
          <div
            className="col-md-10"
            style={{
              minHeight: "100vh",
              overflowY: "scroll",
              width: "100%",
              backgroundColor:
                context.easyCookieEdit ||
                context.easyReport ||
                context.easyCookieUse ||
                context.easyConfirmPos
                  ? "rgba(0,0,0,0.5)"
                  : location.pathname.includes("hidden")
                  ? "rgba(0,0,0,0.2)"
                  : "",
            }}
          >
            <Switch>
              <Route path={`${match.url}/projects/:classId`} component={Projects} />
              <Route exact path={`${match.url}/project/:classId/:id`} component={Project} />
              <Route
                exact
                path={`${match.url}/project/:classId/:id/:posId`}
                component={RepeatDetail}
              />
              <Route
                exact
                path={`${match.url}/hiddens/project/:classId/:id/:posId`}
                component={RepeatDetail}
              />
              <Route path={`${match.url}/students/:classId`} component={Students} />
              <Route path={`${match.url}/student/:classId/:studentId`} component={Student} />
              <Route exact path={`${match.url}/hiddens/project/:classId`} component={Hidden} />
              <Route exact path={`${match.url}/homeworks`} component={FrameWeb} />
              <Route exact path={`${match.url}/hiddens/project/:classId/:id`} component={Project} />
              <Route exact path={`${match.url}/hiddens/notice/:classId`} component={HiddenNotice} />
              <Route
                exact
                path={`${match.url}/hiddens/checklist/:classId`}
                component={HiddenChecklist}
              />
              <Route
                exact
                path={`${match.url}/studentreport/:classId/:studentId/:type`}
                component={StudentReport}
              />
              <Route exact path={`${match.url}/report/:classId/:type`} component={ClassReport} />
              <Route path={`${match.url}/badges/:classId`} component={Badges} />
              <Route path={`${match.url}/checklist/:classId`} component={CheckList} />
              <Route
                exact
                path={`${match.url}/folder/checklist/:classId/:folderId`}
                component={CheckListFolder}
              />
              <Route
                exact
                path={`${match.url}/hiddens/folder/checklist/:classId/:folderId`}
                component={CheckListFolder}
              />
              <Route
                path={`${match.url}/checklistdetail/:classId/:checklistId`}
                component={ChecklistDetail}
              />
              <Route
                path={`${match.url}/hiddens/checklistdetail/:classId/:checklistId`}
                component={ChecklistDetail}
              />
              <Route path={`${match.url}/notice/:classId`} component={Notice} />
              {/* <Route
                path={`${match.url}/noticedetail/:classId/:noticeId`}
                component={NoticeDetail}
              /> */}
              <Route
                path={`${match.url}/noticedetail/:classId/:noticeId/:tempSave`}
                component={NoticeDetail}
              />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
