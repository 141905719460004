import OutsideClickHandler from "react-outside-click-handler";
import React, { useState, useEffect, useContext, useRef } from "react";
import { Redirect, Link, Route, useHistory, useLocation } from "react-router-dom";
import { MainContext } from "../common/Context";
import { ICardProps, IClass, IMainContext } from "../interface/Interface";
import search from "../images/search.png";
import down_b from "../images/down_b.png";
import close from "../images/close.png";
import bin from "../images/bin.png";
import down from "../images/down.png";

import {
  deletelist,
  getAdminNotice,
  getAllClasssPerStudent,
  getCalculateClassFileSize,
  getClassesInfo,
  postAddProject2,
  postInsertClass,
  postNotificationAll,
  postUploadFile,
  putClassesSorting,
  putHideClass,
  putModifyClasses,
} from "../common/Action";
import AuthEdit from "./AuthEdit";
import CardContainer from "../component/CardContainer";
import ReactQuill from "react-quill";
import { formatStyle, quillStyle } from "../common/QuillStyle";
import Dropzone from "react-dropzone";
import file_icon from "../images/logo-top.png";
import {
  consoleLog,
  fileTypeValidate,
  fileViewImage,
  getExtension,
  getFileNameAndExtension,
  getUnitSizeUtil,
  sortedImageFileArr,
} from "../common/Method";
import CalendarC from "../component/CalendarC";
import { includes } from "lodash";
import MainHeader from "./MainHeader";
import { t } from "i18next";

interface IfileInfo {
  fileUrl: string;
  fileType: string;
  fileName: string;
  file: File | null;
}

const Main: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const askRef = useRef<HTMLDivElement>(null);
  const logoutRef = useRef<HTMLDivElement>(null);
  const classAddPopupRef = useRef<HTMLDivElement>(null);
  const classNameChangeRef = useRef<HTMLDivElement>(null);
  const context = useContext<IMainContext>(MainContext);
  const [editInformation, setEditInformation] = useState<boolean>(false);
  const [cardData, setCardData] = useState<IClass[]>([]);
  const [originalData, setOriginalData] = useState<IClass[]>([]);
  const [askPopup, setAskPopup] = useState<boolean>(false);
  const [logout, setLogout] = useState<boolean>(false);
  const [classAddPopup, setClassAddPopup] = useState<boolean>(false);
  const [classAddInput, setClassAddInput] = useState<string>("");
  const [classNameChangeInput, setClassNameChangeInput] = useState<string>("");
  const [classEdit, setClassEdit] = useState<boolean>(false);
  const [classNameChange, setClassNameChange] = useState<boolean>(false);
  const [selectedClassId, setSelectedClassId] = useState<string>("");
  const [notice, setNotice] = useState<any>({});
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // version5 -> add project popup 추가
  const [addProjectPopup, setAddProjectPopup] = useState<boolean>(false);
  // 학생 선택 팝업창
  const [studentsList, setStudentsList] = useState<any>(false);
  // 학급, 학생 데이터 셋
  const [classStudents, setClassStudents] = useState<any>([]);

  const [allCheck, setAllCheck] = useState<any>({
    all: false,
    students: false,
  });

  const [students, setStudents] = useState<any>([]);
  const [originalStudents, setOriginalStudents] = useState<any>([]);
  const [classList, setClassList] = useState<any[]>([]);

  const [studentSearch, setStudentSearch] = useState<string>("");

  const [projectNameInput, setProjectNameInput] = useState<string>("");
  const [repeatCheck, setRepeatCheck] = useState<boolean>(false);
  const [repeatProjectType, setRepeatProjectType] = useState<string>("");
  const [repeatSelectOpenC, setRepeatSelectOpenC] = useState<boolean>(false);
  const [selectRepeatCount, setSelectRepeatCount] = useState<number>(2);
  const [dates, setDates] = useState<any[]>([]);
  const [hiddenClass, setHiddenClass] = useState<boolean>(false);

  const popupRef = useRef<HTMLDivElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const dropRef = useRef<any>(null);
  const [text, setText] = useState<string>("");
  const [files, setFiles] = useState<any>([]);

  // 정렬, 서치
  const [searchInput, setSearchInput] = useState<string>("");
  const [filter, setFilter] = useState<string[]>([t(`Oldest to Newest`), t(`Newest to Oldest`)]); //생성 오래된 순, 최신 순
  const [filterSelected, setFilterSelected] = useState<string>(t(`Oldest to Newest`)); // 생성 오래된 순
  const [filterPopup, setFilterPopup] = useState<boolean>(false);

  // 파일사이즈
  const [sizeLoading, setSizeLoading] = useState<boolean>(false);
  const [totalFileSize, setTotalFileSize] = useState<any>({
    size: 0,
    unit: "",
    maxSize: 0,
  });
  const [fileSizeP, setFileSizeP] = useState<any>({
    percent: 0,
    color: "yellow",
    realPercent: 0,
  });

  useEffect((): void => {
    init();
  }, [location.pathname]);

  useEffect(() => {
    if (!repeatCheck) {
      setRepeatProjectType("");
    }
    if (repeatCheck && repeatProjectType === "") {
      setRepeatProjectType("횟수형");
    }
  }, [repeatCheck]);

  useEffect(() => {
    if (addProjectPopup) {
      // 뒤로가기 방지
      const preventGoBack = () => {
        // change start
        window.history.pushState(null, "", window.location.href);
        // change end
      };

      window.history.pushState(null, "", window.location.href);
      window.addEventListener("popstate", preventGoBack);

      return () => window.removeEventListener("popstate", preventGoBack);
    }
  }, [addProjectPopup]);

  const getAllClassAndStudents = async () => {
    const { result }: any = await getAllClasssPerStudent();

    let tempStudents: any = [];
    for (let i in result) {
      result[i].checked = false;
      result[i].backgroundYellow = "";
      for (let k in result[i].students) {
        tempStudents.push({
          title: result[i].title,
          show: true,
          classId: result[i]._id,
          ...result[i].students[k],
        });
      }
    }

    setClassList(result ? result : []);
    setOriginalStudents(tempStudents);
    setStudents(tempStudents);
  };

  const init = async () => {
    setLoading(true);

    const fileSizeRes: any = await getCalculateClassFileSize();
    // 체킹이 안되어있으면 filesize Checking이라는 응답이 온댜.
    // updating filesize complete -> 파일사이즈에 새로운 변화가 있기 때문에 새로운 측정을 시작함…
    // updating filesize -> 파일사이즈를 측정중임으로 중복해서 서버에 측정 부하를 남기지 않기 위해서 측정을 거부함

    const result: any = await getClassesInfo();
    // 과제 일괄생성으로 인한 데이터 GET
    await getAllClassAndStudents();
    // console.log(fileSizeRes);
    if (fileSizeRes.message === "updating filesize") {
      setSizeLoading(true);
      context.handleStateChange("sizeLoading", true);
    }

    let showClasses = [];

    if (result) {
      if (location.pathname === "/") {
        showClasses = result?.filter((classItem: IClass, i: number) => !classItem.hide);
      } else {
        showClasses = result?.filter((classItem: IClass, i: number) => classItem.hide);
      }

      let countTotalFileSize: number = 0;
      for (let i in result) {
        try {
          if (result[i].totalFilesize) {
            countTotalFileSize += result[i].totalFilesize;
          }
        } catch {}
      }

      getUnitSize(countTotalFileSize);
      setSizeLoading(false);

      setOriginalData(showClasses);
      setCardData(showClasses);
    }

    // 팝업
    if (location.pathname === "/") {
      const popupToday = localStorage.getItem("popup");
      if (popupToday !== new Date().toLocaleDateString()) {
        const notice: any = await getAdminNotice();
        setNotice(notice);
        if (notice.show) setModal(true);
      }
    } else setModal(false);

    setLoading(false);
  };

  const getUnitSize = (fileSize: number) => {
    let data: any = {
      size: 0,
      unit: "",
    };

    if (fileSize / 1000 > 1 && fileSize / 1000 / 1000 < 1) {
      data = {
        size: fileSize / 1000,
        unit: "KB",
      };
    }

    if (fileSize / 1000 / 1000 > 1 && fileSize / 1000 / 1000 / 1000 < 1) {
      data = {
        size: fileSize / 1000 / 1000,
        unit: "MB",
      };
    }

    if (fileSize / 1000 / 1000 / 1000 > 1 && fileSize / 1000 / 1000 / 1000 / 1000 < 1) {
      data = {
        size: fileSize / 1000 / 1000 / 1000,
        unit: "GB",
      };
    }

    setTotalFileSize({
      size: ceil(data.size),
      unit: data.unit,
    });

    // context에 들어갈 파일사이즈 정보
    // context.userInfo?.fileSizeLimit과 context.fileSize.originalFileSize 비교해야함~
    context.handleStateChange("fileSize", {
      size: ceil(data.size),
      unit: data.unit,
      sizeToBite: fileSize,
      originalFileSize: fileSize,
    });
  };

  function ceil(number: number) {
    return (Math.floor(number * 1000) / 1000).toFixed(3);
  }

  // const initialClassData = async () => {
  //   let allClasses = [...originalData2];

  //   const filtered = allClasses.filter((classItem: IClass, i: number) =>
  //     hiddenClass ? classItem.hide : !classItem.hide,
  //   );

  //   console.log(filtered);
  //   setOriginalData(filtered);
  //   setCardData(filtered);
  // };

  const handleClickOutside = (e: React.ChangeEvent<HTMLDivElement>): void => {
    if (askRef.current !== null) {
      if (!askRef.current.contains(e.target)) setAskPopup(false);
    }
    if (logoutRef.current !== null) {
      if (!logoutRef.current.contains(e.target)) setLogout(false);
    }
    if (classAddPopupRef.current !== null) {
      if (!classAddPopupRef.current.contains(e.target)) setClassAddPopup(false);
    }
    if (classNameChangeRef.current !== null) {
      if (!classNameChangeRef.current.contains(e.target)) setClassNameChange(false);
    }
  };

  const handleClassAdd = async (): Promise<void> => {
    await postInsertClass({
      checked: false,
      title: classAddInput,
    });

    setClassAddInput("");
    setClassAddPopup(false);
    init();
  };

  const handleClassNameChange = async (): Promise<void> => {
    if (classNameChangeInput === "") {
      // return alert("학급명을 입력해주세요!");
      return alert(t(`Please enter a class name`));
    }

    let temp = {
      id: selectedClassId,
      title: classNameChangeInput,
    };
    const result: any = await putModifyClasses(temp);
    if (result) {
      // alert("학급명이 변경되었습니다.");
      alert(t(`The class name has been changed`));
      setClassNameChange(false);
      setClassEdit(false);
      init();
    }
  };

  const handleDelete = async (): Promise<void> => {
    const confirm = window.confirm(
      // "⛔️정말 학급을 삭제하시겠습니까?⛔️ \n 학급 삭제 시 해당 학급의 학생이 보냈던 모든 자료와 피드백, 그리고 해당 과제로 부과되었던 🍪'쿠키'기록 등 모든 자료가 영구히 사라집니다.\n(절대 복구 불가) 진행하시겠습니까?",
      t(`Are you sure you want to delete the class...`),
    );
    if (confirm) {
      let idList = [];
      for (let i in cardData) {
        if (cardData[i].checked) {
          idList.push(cardData[i]._id);
        }
      }

      await deletelist("classes", idList);
      // alert("삭제되었습니다.");
      alert(t(`Deleted complete`));
    }
    init();
    setClassEdit(false);
  };

  const handleSubmit = async (): Promise<void> => {
    // 학급 순서 변경
    const classList = [];

    for (let i = 0; i < cardData.length; i++) {
      classList.push({ _id: cardData[i]._id, sort: i });
    }

    await putClassesSorting(classList);
    setClassEdit(false);
    init();
  };

  const handleLogout = async () => {
    if (!context.userInfo.kakaoId || context.userInfo.kakaoId === "") {
      context.handleUserDestroy();
    }

    // const unlink: any = await postUnlinkKakao({
    //   target_id_type: "user_id",
    //   target_id: context.userInfo.kakaoId,
    // });
    // console.log(unlink);
  };

  const handleCheckCard = (index: number): void => {
    const temp = [...cardData];
    temp[index].checked = !temp[index].checked;
    let virtualFileSize = 0;

    if (location.pathname === "/hiddenclass") {
      if (temp[index].checked) {
        virtualFileSize = context.fileSize.sizeToBite - temp[index].totalFilesize!;
      }
      if (!temp[index].checked) {
        virtualFileSize = context.fileSize.sizeToBite + temp[index].totalFilesize!;
      }

      const fileSizeEdit = getUnitSizeUtil(virtualFileSize);
      context.handleStateChange("fileSize", {
        ...context.fileSize,
        ...fileSizeEdit,
      });
    }

    setCardData(temp);
  };

  const handleSort = (index: number, which: string): void => {
    if (index === 0 && which === "left") return;
    if (index === cardData.length - 1 && which === "right") return;
    const tempClassCard = [...cardData];

    if (which === "left") {
      tempClassCard[index - 1] = cardData[index];
      tempClassCard[index] = cardData[index - 1];
    } else {
      tempClassCard[index + 1] = cardData[index];
      tempClassCard[index] = cardData[index + 1];
    }

    setCardData(tempClassCard);
  };

  const cardProps = {
    setClassNameChange: setClassNameChange,
    setClassNameChangeInput: setClassNameChangeInput,
    setSelectedClassId: setSelectedClassId,
    handleCheckCard: handleCheckCard,
    handleSort: handleSort,
    setCardData: setCardData,
    type: location.pathname === "/" ? "general" : "onlyCheck",
  };

  const handleClickOutsidePopup = (e: any) => {
    if (popupRef.current !== null) {
      if (!popupRef.current.contains(e.target)) {
        addProjectPopupReset();
        setAddProjectPopup(false);
      }
    }
  };

  const addProjectPopupReset = async () => {
    getAllClassAndStudents();
    setAddProjectPopup(false);
    setProjectNameInput("");
    setText("");
    setRepeatCheck(false);
    setRepeatProjectType("");
    setRepeatSelectOpenC(false);
    setSelectRepeatCount(2);
    setDates([]);
    setFiles([]);
  };

  const handleChageText = (e: any) => {
    setText(e);
  };

  const onDrop = async (acceptedFiles: any) => {
    if (acceptedFiles) {
      let fileTemp = [...files];
      // let fileUrlTemp = [...fileUrls];
      for (let i in Object.keys(acceptedFiles)) {
        if (fileTypeValidate(acceptedFiles[i])) {
          fileTemp.push({
            fileUrl: fileViewImage(acceptedFiles[i]),
            file: acceptedFiles[i],
            fileType: getExtension(acceptedFiles[i]),
            fileName: getFileNameAndExtension(acceptedFiles[i]).fileName,
          });

          // fileUrlTemp.push(fileViewImage(e.target.files[i]));
        } else {
          // alert("첨부 가능한 파일만 첨부해주세요.");
          alert(t(`Please attach only the file that can be attached`));
        }
      }

      const sorted = sortedImageFileArr(fileTemp);
      setFiles(sorted);
    } else {
      return;
    }
  };

  const countStudentSelected = () => {
    let count = 0;
    for (let i in originalStudents) {
      if (originalStudents[i].checked) count++;
    }

    return count;
  };

  const getCount = () => {
    let temp = [];
    for (let i = 2; i <= 21; i++) {
      temp.push(i);
    }
    return temp;
  };

  const handleSelectDate = (day: any) => {
    const tempDates: any = [];
    let exist: boolean = false;
    for (let i in dates) {
      if (dates[i] === day.valueOf()) {
        exist = true;
      } else {
        tempDates.push(dates[i]);
      }
    }
    if (!exist) tempDates.push(day.valueOf());
    if (tempDates.length === 22) {
      // return alert("과제는 21개 이상 등록하실 수 없습니다.");
      return alert(t(`Unable to register more than 21 assignments`));
    }

    setDates(tempDates);
  };

  const handleDeleteImg = (data: any, index: number) => {
    let tempFiles = [...files];
    tempFiles.splice(index, 1);

    setFiles(tempFiles);
  };

  const handleSubmitProject = async () => {
    // return alert("서비스 준비중입니다.");
    if (projectNameInput === "") {
      // return alert("과제 이름을 입력해 주세요.");
      return alert(t(`Please enter a assignment title`));
    }
    if (loading) {
      // return alert("과제 생성중입니다...");
      return alert(t(`Creating Assignment`));
    }
    setLoading(true);
    const checkedStudentsArr: any = students.filter((el: any, i: number) => el.checked);

    let fetchArr: any[] = [];
    let tempUrls: string[] = [];
    let tempFileTypes: string[] = [];
    let tempFileNames: string[] = [];
    if (files.length !== 0) {
      for (let i in files) {
        const formData = new FormData();
        formData.append("file", files[i].file);
        fetchArr.push(postUploadFile(formData));
        // let fileUrl: string | boolean = "";
        // const formData = new FormData();
        // formData.append("file", files[i].file);
        // if (files[i].file.type.split("/")[0] === "image") {
        //   fileUrl = (await postUploadImage(formData)) as string;
        // } else {
        //   fileUrl = (await postUploadFile(formData)) as string;
        // }

        tempFileTypes.push(files[i].fileType);
        tempFileNames.push(files[i].fileName);
        // tempUrls.push(fileUrl);
      }
    }

    const fileArrResult: any[] = await Promise.all(fetchArr);
    // console.log(fileArrResult);

    let checkedStudents: string[] = [];
    let pushTokenList: any[] = [];
    for (let i in students) {
      if (students[i].checked) {
        checkedStudents.push(students[i].code);
        pushTokenList.push(students[i]?.deviceToken);
      }
    }

    // console.log(checkedStudentsArr);
    let data: any = {
      title: projectNameInput,
      description: text,
      // file: tempUrls,
      file: fileArrResult,
      fileTypes: tempFileTypes,
      fileNames: tempFileNames,
      // classId: params.classId,
      hide: false,
      newProjectAlert: true,
      repeat: repeatCheck,
      repeatType: repeatCheck ? (repeatProjectType === "횟수형" ? "count" : "date") : "",
      repeatCount:
        repeatProjectType === "횟수형"
          ? selectRepeatCount
          : repeatProjectType === "날짜형"
          ? dates.length
          : 0,
      studentCodeList: [],
      students: checkedStudentsArr,
    };
    if (repeatProjectType === "날짜형") {
      data = { ...data, repeatDate: dates };
    }

    const projectInsertResult: any = await postAddProject2(data);
    if (projectInsertResult.acknowledged) {
      let pushArr: any = [];
      let filerStudents = students.filter((el: any, i: number) => el.checked && el.deviceToken2);
      filerStudents.map((el: any, i: number) => {
        if (el.deviceToken2) {
          pushArr.push({
            token: el.deviceToken2,
            notification: {
              title: `${projectNameInput}💌`,
              // body: "새로운 과제가 도착했어요!",
              body: t(`A new assignment has arrived`),
            },
            data: {
              code: el.code,
              type: "project",
            },
          });
        }
      });
      postNotificationAll(pushArr);
    }
    setLoading(false);
    setAddProjectPopup(false);
    setRepeatCheck(false);
    setRepeatProjectType("");
    setDates([]);
    init();
  };

  const handleCheckBoxDetect = () => {
    let tempArr = [...classStudents];

    for (let i = 0; i < tempArr?.length; i++) {
      let count = 0;
      for (let k = 0; k < tempArr[i].students?.length; k++) {
        if (tempArr[i].students[k].checked) {
          count++;
        }
      }

      tempArr[i].checkCount = count;
    }

    for (let i = 0; i < tempArr.length; i++) {
      if (tempArr[i].students.length === tempArr[i].checkCount) {
        tempArr[i].checked = true;
      } else {
        tempArr[i].checked = false;
      }
    }

    setClassStudents(tempArr);
  };

  const handleSearchStudents = async (value: string) => {
    // console.log(value);
    // 텍스는가 1개 이상일때
    // 아무것도 체크가 안되어있으면 전체학생에서 includes 로 검색
    // 체크가 하나라도 되어있으면 체크중에서 includes 로 검색
    const findIndex = classList.findIndex((e: any) => e.backgroundYellow === "background-yellow");

    if (findIndex === -1) {
      const tempStudents = [...students];
      for (let i in tempStudents) {
        if (tempStudents[i].name.includes(value)) {
          tempStudents[i].show = true;
        } else {
          tempStudents[i].show = false;
        }
      }
      setStudents(tempStudents);
    } else {
      const yellewCheckIndex = classList[findIndex]._id;
      const tempStudents = [...students];
      for (let i in tempStudents) {
        if (tempStudents[i].name.includes(value) && tempStudents[i].classId === yellewCheckIndex) {
          tempStudents[i].show = true;
        } else {
          tempStudents[i].show = false;
        }
      }
      setStudents(tempStudents);
    }

    setStudentSearch(value);
  };

  // 학급 텍스트 눌렀을 때
  const handleClassSelect = (classId: string) => {
    const temp = [...originalStudents];
    const tempClassList = [...classList];
    for (let i in tempClassList) {
      if (classId === tempClassList[i]._id) {
        tempClassList[i].backgroundYellow =
          tempClassList[i].backgroundYellow === "background-yellow" ? "" : "background-yellow";
      }
    }
    for (let i in tempClassList) {
      if (tempClassList[i]._id !== classId) tempClassList[i].backgroundYellow = "";
    }

    for (let i in temp) {
      if (temp[i].classId === classId && temp[i].name.includes(studentSearch)) {
        temp[i].show = true;
      } else {
        temp[i].show = false;
      }
    }

    let checkCount: number = 0;
    for (let i in tempClassList) {
      if (tempClassList[i].backgroundYellow === "background-yellow") checkCount++;
    }
    if (checkCount === 0) {
      for (let i in temp) temp[i].show = true;
    }

    setClassList(tempClassList);
    setStudents(temp);
  };

  const handleCheckClass = (classId: string) => {
    if (studentSearch !== "") return;
    const tempClass = [...classList];
    let doCheck: boolean = true;
    for (let i in tempClass) {
      if (tempClass[i]._id === classId && tempClass[i].checked) {
        doCheck = false;
        tempClass[i].checked = false;
        break;
      }
      if (tempClass[i]._id === classId && !tempClass[i].checked) {
        doCheck = true;
        tempClass[i].checked = true;
        break;
      }
    }

    const tempStudents = [...students];
    for (let i in tempStudents) {
      if (tempStudents[i].classId === classId) {
        tempStudents[i].checked = doCheck;
      }
    }

    setStudents(tempStudents);
    setClassList(tempClass);
  };

  const handleCheckStudent = (student: any) => {
    let tempStudents = [...students];
    const targetIdx = tempStudents.findIndex((item, i) => item._id === student._id);
    tempStudents[targetIdx].checked = !tempStudents[targetIdx].checked;
    setStudents(tempStudents);
  };

  useEffect(() => {
    let tempClassList: any = {};

    if (students.length > 0) {
      for (let i in students) {
        tempClassList[students[i].classId] = !tempClassList[students[i].classId]
          ? [students[i]]
          : [...tempClassList[students[i].classId], students[i]];
      }

      const classArr = Object.keys(tempClassList);

      for (let i = 0; i < classArr?.length; i++) {
        let checkCount = 0;
        for (let k = 0; k < tempClassList[classArr[i]]?.length; k++) {
          if (tempClassList[classArr[i]][k].checked) {
            checkCount++;
          }
        }

        tempClassList[classArr[i]] = {};
        tempClassList[classArr[i]].checkCount = checkCount;
      }

      let tempClassList2 = [...classList];

      for (let i in tempClassList2) {
        for (let k in classArr) {
          if (tempClassList2[i]._id === classArr[k]) {
            if (
              tempClassList2[i].students.length !== 0 &&
              tempClassList2[i].students.length === tempClassList[classArr[k]].checkCount
            ) {
              tempClassList2[i].checked = true;
            } else {
              tempClassList2[i].checked = false;
            }
          }
        }
      }

      setClassList(tempClassList2);

      let showStudents = students.filter((aStudent: any, e: number) => aStudent.show);
      let showStudentsChecked = 0;
      for (let i in showStudents) {
        if (showStudents[i].checked) showStudentsChecked++;
      }
      if (showStudents.length !== 0 && showStudents.length === showStudentsChecked) {
        setAllCheck((prev: any) => {
          return {
            ...prev,
            students: true,
          };
        });
      } else {
        setAllCheck((prev: any) => {
          return {
            ...prev,
            students: false,
          };
        });
      }

      let classCheck = 0;
      for (let i in tempClassList2) {
        if (tempClassList2[i].checked) classCheck++;
      }

      if (tempClassList2.length !== 0 && classCheck === tempClassList2.length) {
        setAllCheck((prev: any) => {
          return {
            ...prev,
            all: true,
          };
        });
      } else {
        setAllCheck((prev: any) => {
          return {
            ...prev,
            all: false,
          };
        });
      }
    }
  }, [students]);

  const handleCheckPackage = (type: string) => {
    let tempStudents = [...students].filter((aS: any, i: number) => aS.show);
    let tempClasses = [...classList];
    let tempAllStudents = [...students];
    let copyAllStudents = [...originalStudents];
    if (type === "students") {
      for (let i in tempStudents) {
        tempStudents[i].checked = allCheck.students ? false : true;
      }
      setAllCheck((prev: any) => {
        return {
          ...prev,
          students: !prev.students,
        };
      });
      setStudents(tempStudents);
    }
    if (type === "all") {
      for (let i in tempClasses) {
        tempClasses[i].checked = allCheck.all ? false : true;
      }
      for (let i in tempAllStudents) {
        tempAllStudents[i].checked = allCheck.all ? false : true;
      }
      for (let i in copyAllStudents) {
        copyAllStudents[i].checked = allCheck.all ? false : true;
      }
      setAllCheck((prev: any) => {
        return {
          ...prev,
          all: !prev.all,
        };
      });
      setOriginalStudents(copyAllStudents);
      setStudents(tempAllStudents);
    }
    setClassList(tempClasses);
  };

  const handleCountCheckedPerClass = (classId: any) => {
    let studentsPerClass = originalStudents.filter((aS: any, i: number) => classId === aS.classId);
    let count = 0;
    for (let i in studentsPerClass) {
      if (studentsPerClass[i].checked) count++;
    }
    return count;
  };

  const handleHideClass = async () => {
    let idList = [];
    for (let i in cardData) {
      if (cardData[i].checked) {
        idList.push(cardData[i]._id);
      }
    }

    await putHideClass({ ids: idList, hide: location.pathname === "/" ? true : false });
    if (location.pathname === "/") {
      // alert(
      //   "숨긴 학급 보관함으로 이동되었습니다! 숨긴 학급은 학생의 휴대폰에서 보이지 않게 됩니다. 단, 삭제 된 것이 아니라 언제든지 보관함으로 가셔셔 복구가 가능합니다!",
      // );
      alert(t(`You have been moved to the Archived classes...`));
    } else {
      // alert("학급이 복구되었습니다.");
      alert(t(`Your class has been restored`));
    }

    setClassEdit(false);
    init();
  };

  const handleSearch = (e: any): void => {
    if (e.target.value === "") {
      const temp = [...originalData];
      for (let i in temp) {
        temp[i].searchHide = false;
      }
      setOriginalData(temp);
    } else {
      let temp: any = [...originalData];
      for (let i in temp) {
        if (temp[i].title.includes(e.target.value)) {
          temp[i].searchHide = false;
        } else {
          temp[i].searchHide = true;
        }
      }
      setOriginalData(temp);
    }
    setSearchInput(e.target.value);
  };

  const handleFilterSelect = async (index: number): Promise<void> => {
    let temp = "";
    temp = filter[index];
    setFilterSelected(temp);
    let filterData: any = [...cardData];

    if (filter[index] === t(`Oldest to Newest`)) {
      // 생성 오래된 순
      filterData = filterData.sort((a: any, b: any) => a.created - b.created);
    }
    if (filter[index] === t(`Newest to Oldest`)) {
      // 최신 순
      filterData = filterData.sort((a: any, b: any) => b.created - a.created);
    }

    setOriginalData(filterData);
    setFilterPopup(false);
  };

  const handleCancelClassEdit = () => {
    setClassEdit(false);
    const temp = [...cardData];
    for (let i = 0; i < temp.length; i++) {
      temp[i].checked = false;
    }

    getUnitSize(context.fileSize.originalFileSize);
    setCardData(temp);
  };

  const handleAddProject = () => {
    let fileLimit = 0;
    if (context.userInfo.fileSizeLimit === 0 || !context.userInfo.fileSizeLimit) {
      fileLimit = 100000000;
    } else {
      fileLimit = context.userInfo?.fileSizeLimit;
    }
    if (fileLimit < context.fileSize.originalFileSize) {
      return alert(
        // "제한 용량을 초과하셨습니다. '과제 삭제' 또는 '학급 삭제'를 통해 저장 용량을 조절하시거나, '결제 관리' 페이지에서 저장 용량 업그레이드 후 이용해 주세요.",
        t(`You have exceeded the limit...`),
      );
    }
    setAddProjectPopup(true);
  };

  if (!context.isUser) return <Redirect to="/login" />;

  return (
    <div onClick={(e: any) => handleClickOutside(e)} className="main_background">
      {/* <MainHeader /> */}
      {/* 메인 헤더 pc */}
      {/* <div className="main_header display-f">
        <div className="container" style={{ display: "flex", alignItems: "center" }}>
          <div className="col-md-4 display-f align-center font-15">
            <div className="display-f mr-20">
              <p className="font-700 color-dark-blue">?</p>&nbsp;
              <p onClick={() => setAskPopup((prevState) => !prevState)} className="cursor">
                문의하기
              </p>
              {askPopup && (
                <div onClick={(e: any) => handleClickOutside(e)} className="popup-main">
                  <div ref={askRef} className="main_popup scroll">
                    <p className="text-center font-23 mb-26">문의하기</p>
                    <div className="qr_box margin-auto mb-10">
                      <img src={qr} alt="qr-code" className="qr" />
                    </div>
                    <a href="https://open.kakao.com/me/dahandin" target="blank">
                      <p className="text-center font-16 mb-26 cursor">바로가기</p>
                    </a>
                    <div style={{ padding: "0 20px" }}>
                      <p className="notice-text">
                        &nbsp;저 역시 현직 교사이므로 오전 수업시간에는 답변이 어려우니 이 점
                        양해부탁드립니다.
                        <br />
                        &nbsp;자주 연락주시는 부분과 그에 대한 답변을 아래에 정리하여 두었습니다.{" "}
                        <br />
                        &nbsp;'문의하기'로 카톡을 보내주시기 전에, 아래 글을 먼저 확인 부탁드립니다.{" "}
                        <br /> <br />
                      </p>
                      <p className="text-center font-500 font-16 mb-8"> 🔻🔍자주 하시는 QnA🔍🔻</p>
                      <a target="_blank" href="https://blog.naver.com/dahandin/222660614302">
                        <p className="text-center font-16 mb-26 cursor">QnA 바로가기</p>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <a
              target="_blank"
              href={"https://blog.naver.com/dahandin/222881483249"}
              className="display-f align-center text-deco-none"
            >
              <div style={{ marginTop: -4 }}>
                <p className="font-700 color-dark-blue" style={{ fontSize: 21 }}>
                  ℹ
                </p>
              </div>
              &nbsp;
              <p className="cursor text-deco-none">튜토리얼</p>
            </a>
          </div>

          <div className="col-md-4 display-f justify-center">
            <img className="main_logo" src={logo} alt="logo" />
          </div>
          <div className="col-md-4 display-f align-center justify-end">
            <div>
              <div
                className="display-f align-center"
                style={{ marginRight: 8, position: "relative" }}
              >
                <div style={{ marginRight: 4 }}>
                  {!sizeLoading && (
                    <p style={{ fontSize: 12, fontWeight: "bold" }}>전체 사용 용량</p>
                  )}
                  <p style={{ fontSize: 11 }}>{sizeLoading && "용량 측정중..."}</p>
                  <p style={{ fontSize: 10 }}>(베타테스트입니다.)</p>
                </div>
                <div>
                  <div className="text-right">
                    <p style={{ fontSize: 11 }}>2GB</p>
                  </div>
                  <div
                    style={{
                      width: 160,
                      height: 16,
                      border: "1px solid #979797",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className={`filesize-animation ${fileSizeP.color}`}
                      style={{
                        width: `${fileSizeP.percent}%`,
                        height: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          width: `${virtualFileSizeP}%`,
                          height: "100%",
                          backgroundColor: "#ececec",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="text-center">
                    <p style={{ fontSize: 11 }}>
                      {totalFileSize.size === 0 ? "?" : totalFileSize.size}
                      {totalFileSize.unit}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <img className="main_user mr-6" src={user} alt="user" />
            <div onClick={() => setLogout((prevState) => !prevState)}>
              <p className="font-15 cursor">{context.userName}</p>
              <p className="cursor" style={{ fontSize: 10 }}>
                선생님
              </p>
            </div>
            {logout && (
              <div ref={logoutRef} className="logout text-center">
                <p
                  onClick={() => {
                    setEditInformation(true);
                    setLogout(false);
                  }}
                  className="cursor logout__text light-black"
                >
                  정보수정
                </p>

                {context.userInfo.kakaoId && context.userInfo.kakaoId !== "" && (
                  <a
                    className="link_signout"
                    href={`https://kauth.kakao.com/oauth/logout?client_id=6cadd7e874b389249dbed4eb27fbf71f&logout_redirect_uri=http://localhost:3000/auth/kakaosignout`}
                  >
                    <p onClick={handleLogout} className="cursor logout__text light-black">
                      로그아웃
                    </p>
                  </a>
                )}
                {!context.userInfo.kakaoId && (
                  <p onClick={handleLogout} className="cursor logout__text light-black">
                    로그아웃
                  </p>
                )}
                <p className="cursor logout__text light-black">
                  <a target="blank" href="https://blog.naver.com/dahandin/223171578706">
                    개인정보 처리방침
                  </a>
                </p>

                <div
                  onClick={() => history.push("/payplan")}
                  className="font-blue cursor"
                  style={{ padding: "6px 0", borderTop: "1px dashed #ececec" }}
                >
                  결제관리
                </div>
              </div>
            )}
          </div>
        </div>
      </div> */}

      {/* 메인 헤더 모바일 */}
      {/* <div className="main_header_m">
        <p
          onClick={() => setAskPopup((prevState) => !prevState)}
          className="font-700 color-dark-blue"
          style={{ fontSize: 20, flex: 1 }}
        >
          ?
        </p>
        &nbsp;
        {askPopup && (
          <div onClick={(e: any) => handleClickOutside(e)} className="popup-main">
            <div ref={askRef} className="main_popup scroll">
              <p className="text-center font-23 mb-26">문의하기</p>
              <div className="qr_box margin-auto mb-10">
                <img src={qr} alt="qr-code" className="qr" />
              </div>
              <a href="https://open.kakao.com/me/dahandin" target="blank">
                <p className="text-center font-16 mb-26 cursor">바로가기</p>
              </a>
              <div style={{ padding: "0 20px" }}>
                <p className="notice-text">
                  &nbsp;저 역시 현직 교사이므로 오전 수업시간에는 답변이 어려우니 이 점
                  양해부탁드립니다.
                  <br />
                  &nbsp;자주 연락주시는 부분과 그에 대한 답변을 아래에 정리하여 두었습니다. <br />
                  &nbsp;'문의하기'로 카톡을 보내주시기 전에, 아래 글을 먼저 확인 부탁드립니다.{" "}
                  <br /> <br />
                </p>
                <p className="text-center font-500 font-16 mb-8"> 🔻🔍자주 하시는 QnA🔍🔻</p>
                <a href="https://blog.naver.com/dahandin/222660614302">
                  <p className="text-center font-16 mb-26 cursor">QnA 바로가기</p>
                </a>
              </div>
            </div>
          </div>
        )}
        <img className="main_logo" src={logo} alt="logo" />
        <div className="display-f align-center justify-end position-r" style={{ flex: 1 }}>
          <img
            onClick={() => setLogout((prevState) => !prevState)}
            className="main_user"
            src={user}
            alt="user"
          />

          {logout && (
            <div ref={logoutRef} className="logout" style={{ zIndex: 100 }}>
              <p
                onClick={() => {
                  setEditInformation(true);
                  setLogout(false);
                }}
                className="cursor logout__text light-black"
              >
                정보수정
              </p>
              <p onClick={handleLogout} className="cursor logout__text light-black">
                로그아웃
              </p>
            </div>
          )}
        </div>
      </div> */}

      {location.pathname === "/" && (
        <div className="main_background_show pt-40">
          <div className="container padding-0">
            {editInformation && <AuthEdit />}
            {!editInformation && (
              <div className="text-center display-f justify-center">
                <button
                  onClick={handleAddProject}
                  style={{
                    border: "1px solid #979797",
                    backgroundColor: "#fff",
                    fontWeight: 500,
                  }}
                  className="cursor select_btn display-f align-center"
                >
                  <p className="mr-5">📚</p>
                  {/* 과제 생성 */}
                  <p className="font-15 sidebar_contents">{t("Create Assignment")}</p>
                </button>
              </div>
            )}

            {!editInformation && (
              <div className="row" style={{ margin: 0 }}>
                <div className="col-md-8 col-md-offset-2 padding-0 ">
                  {classEdit ? (
                    <div
                      style={{ flexWrap: "wrap" }}
                      className="mb-24 display-f justify-between align-center pl-10 pr-10"
                    >
                      {/* 학급명을 변경하시려면 학급 카드를 클릭해주세요. */}
                      <p>❊ {t("To change the class name click on the class card")}</p>
                      <div className="classlist_card_edit">
                        {/* <button onClick={handleDelete} className="card_handler_btn mr-10">
                          삭제
                        </button> */}
                        <button onClick={handleHideClass} className="card_handler_btn mr-10">
                          {/* 숨기기 */}
                          {t("hide")}
                        </button>
                        <button onClick={handleSubmit} className="card_handler_submit white mr-10">
                          {/* 확인 */}
                          {t("Confirm")}
                        </button>
                        <button
                          onClick={handleCancelClassEdit}
                          className="card_handler_cancel color-dark-blue "
                        >
                          {/* 취소 */}
                          {t("Cancel")}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="display-f justify-between align-center mb-24 pl-10 pr-10 main_card_header mt-40">
                      {/* 내 학급 목록 */}
                      <p className="font-18 font-500">{t("My Class List")}</p>

                      <div className="display-f align-center">
                        <button
                          // onClick={() => alert("서비스 준비중입니다.")}
                          onClick={() => history.push("/hiddenclass")}
                          style={{
                            border: "1px solid #ececec",
                            backgroundColor: "#f7f7f7",
                            fontWeight: 500,
                          }}
                          className="text-left mr-10 cursor display-f align-center select_btn"
                        >
                          <img
                            style={{ width: 16, height: "auto", marginRight: 5 }}
                            src={bin}
                            alt="bin"
                          />
                          {/* 숨긴 학급함 */}
                          <p className="font-15 sidebar_contents">{t("Archived Classes")}</p>
                        </button>
                        <div className="display-f align-center pr-10 mt-10 mb-10">
                          <button
                            onClick={() => setClassEdit(true)}
                            className="card_handler_cancel color-dark-blue"
                          >
                            {/* 수정 */}
                            {t("Edit")}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {classEdit ? (
                    <>
                      <CardContainer cardData={cardData} cardProps={cardProps} />
                    </>
                  ) : (
                    <>
                      {!loading &&
                        originalData?.map((data: any, index: number) => {
                          if (originalData.length === 0) return <></>;
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                history.push(`/page/students/${data._id}`);
                                context.handleStateChange("classId", data._id);
                              }}
                              className="col-md-3 pl-10 pr-10"
                            >
                              <div className={`card ${data.status}`} style={{ padding: "0 15px" }}>
                                <p className="font-18">{data.title}</p>
                              </div>
                            </div>
                          );
                        })}
                      {loading && (
                        <div
                          style={{
                            height: 130,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="loading_dots">
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {!classEdit && !loading && (
                    <div className="col-md-3 pl-10 pr-10">
                      <div
                        onClick={() => {
                          setClassAddPopup(true);
                          setClassAddInput("");
                        }}
                        className="card_plus"
                      >
                        <p className="font-40 plus-color">+</p>
                      </div>
                    </div>
                  )}

                  {classAddPopup && (
                    <div onClick={(e: any) => handleClickOutside(e)} className="popup-main">
                      <div ref={classAddPopupRef} className="main_popup scroll">
                        {/* 학급 추가 */}
                        <p className="text-center font-23 mb-26">{t(`Create class`)}</p>
                        <div className="pl-36 pr-36">
                          {/* 학급 생성 */}
                          <p className="mb-8 font-15">{t(`Class name`)}</p>
                          <input
                            value={classAddInput}
                            onChange={(e: any) => setClassAddInput(e.target.value)}
                            type="text"
                            className="class__add__input mb-25 pl-20 pr-20"
                          />
                          <div className="popup__bottom mb-20">
                            <button
                              onClick={handleClassAdd}
                              className="focus popup__bottom__btn mr-14"
                            >
                              {/* 확인 */}
                              {t(`Confirm`)}
                            </button>
                            <button
                              onClick={() => setClassAddPopup(false)}
                              className="focus popup__bottom__btn"
                            >
                              {/* 취소 */}
                              {t(`Cancel`)}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {classNameChange && (
                    <div onClick={(e: any) => handleClickOutside(e)} className="popup-main">
                      <div ref={classNameChangeRef} className="main_popup scroll">
                        <p className="text-center font-23 mb-26">
                          {/* 학급명 변경 */}
                          {t(`Change of class name`)}
                        </p>
                        <div className="pl-36 pr-36">
                          <p className="mb-8 font-15">
                            {/* 학급 이름 */}
                            {t(`Class name`)}
                          </p>
                          <input
                            value={classNameChangeInput}
                            onChange={(e: any) => setClassNameChangeInput(e.target.value)}
                            type="text"
                            className="class__add__input mb-25 pl-20 pr-20"
                          />
                          <div className="popup__bottom mb-20">
                            <button
                              onClick={handleClassNameChange}
                              className="focus popup__bottom__btn mr-14"
                            >
                              {/* 확인 */}
                              {t(`Confirm`)}
                            </button>
                            <button
                              onClick={() => setClassNameChange(false)}
                              className="focus popup__bottom__btn"
                            >
                              {/* 취소 */}
                              {t(`Cancel`)}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* 숨긴 학급함 */}
      {location.pathname === "/hiddenclass" && (
        <div className="main_background_hidden pt-40">
          <div className="container padding-0">
            {editInformation && <AuthEdit />}

            {!editInformation && (
              <div className="row" style={{ margin: 0 }}>
                <div className="col-md-8 col-md-offset-2 padding-0 ">
                  {classEdit ? (
                    <div
                      style={{ flexWrap: "wrap" }}
                      className="mb-24 display-f justify-between align-center pl-10 pr-10"
                    >
                      <div className="classlist_card_edit">
                        <button onClick={handleDelete} className="card_handler_submit_delete mr-10">
                          {/* 영구 삭제 */}
                          {t(`(Permenantly)Delete`)}
                        </button>
                      </div>
                      <div className="classlist_card_edit">
                        <button
                          onClick={handleHideClass}
                          className="card_handler_submit2 white mr-10"
                        >
                          {/* 학급 복구 */}
                          {t(`Class Restore`)}
                        </button>
                        <button
                          onClick={handleCancelClassEdit}
                          className="card_handler_cancel color-dark-blue "
                        >
                          {/* 취소 */}
                          {t(`Cancel`)}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      {/* <span
                        onClick={() => history.goBack()}
                        className="pl-10 font-bold cursor"
                        style={{ color: "rgba(0,0,0,0.35)", textDecoration: "underline" }}
                      >
                        {"<"} 이전으로
                      </span> */}

                      <div className="display-f justify-between flex-wrap ml-10">
                        <button
                          onClick={() => history.push("/")}
                          className="cursor select_btn display-f align-center home_btn"
                        >
                          <p className="font-15 sidebar_contents mr-5">{"<"}</p>
                          <p className="mr-5">🏠</p>
                          {/* 홈 화면으로 */}
                          <p className="font-15 sidebar_contents">{t(`Home`)}</p>
                        </button>

                        <div className="display-f align-center pr-10">
                          <button
                            onClick={() => setClassEdit(true)}
                            className="card_handler_cancel color-dark-blue"
                          >
                            {/* 수정 */}
                            {t(`Edit`)}
                          </button>
                        </div>
                      </div>

                      <div className="display-f justify-between align-center pl-10 pr-10 main_card_header mt-15 flex-wrap mb-20">
                        {/* 숨긴 학급 보관함 (임시 휴지통) */}
                        <p className="font-18 font-600">{t(`Archived Classes(Temporary)`)}</p>

                        <div className="display-f flex-wrap">
                          <div className="display-f position-r flex-wrap">
                            <form className="mr-10 position-r">
                              <img
                                className="search_icon position-a cursor"
                                src={search}
                                alt="search"
                              />
                              <input
                                value={searchInput}
                                onChange={handleSearch}
                                type="text"
                                className="category_searchInput pl-50 pr-10"
                              />
                            </form>

                            <OutsideClickHandler
                              onOutsideClick={() => {
                                setFilterPopup(false);
                              }}
                            >
                              <button
                                onClick={() => setFilterPopup((prev) => !prev)}
                                className="filter_btn text-left pl-17 pr-12 display-f justify-between align-center"
                              >
                                {filterSelected}
                                <img className="filter_icon" src={down} alt="down" />
                              </button>
                              {filterPopup && (
                                <div className="position-a filter_contents">
                                  {filter.map((data: string, index: number) => (
                                    <div
                                      onClick={() => handleFilterSelect(index)}
                                      className="filter_inner cursor"
                                      key={index}
                                    >
                                      <p className="pl-17 pt-11 pb-11 font-14">{data}</p>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </OutsideClickHandler>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {classEdit ? (
                    <>
                      <CardContainer cardData={cardData} cardProps={cardProps} />
                    </>
                  ) : (
                    <>
                      {!loading &&
                        originalData?.map((data: any, index: number) => {
                          if (originalData.length === 0) return <></>;
                          if (!data.searchHide) {
                            return (
                              <div
                                key={index}
                                onClick={() => {
                                  // alert("숨김 상태에서는 열람이 불가합니다. 복원해서 열람 하세요.");
                                  alert(t(`It cannot be viewed in a hidden state...`));
                                }}
                                className="col-md-3 pl-10 pr-10"
                              >
                                <div
                                  className={`card ${data.status}`}
                                  style={{ padding: "0 15px" }}
                                >
                                  <p className="font-18">{data.title}</p>
                                </div>
                              </div>
                            );
                          }
                        })}
                      {loading && (
                        <div
                          style={{
                            height: 130,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="loading_dots">
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {classNameChange && (
                    <div onClick={(e: any) => handleClickOutside(e)} className="popup-main">
                      <div ref={classNameChangeRef} className="main_popup scroll">
                        {/* 학급명 변경 */}
                        <p className="text-center font-23 mb-26">{t(`Change of class name`)}</p>
                        <div className="pl-36 pr-36">
                          {/* 학급 이름 */}
                          <p className="mb-8 font-15">{t(`Class name`)}</p>
                          <input
                            value={classNameChangeInput}
                            onChange={(e: any) => setClassNameChangeInput(e.target.value)}
                            type="text"
                            className="class__add__input mb-25 pl-20 pr-20"
                          />
                          <div className="popup__bottom mb-20">
                            <button
                              onClick={handleClassNameChange}
                              className="focus popup__bottom__btn mr-14"
                            >
                              {/* 확인 */}
                              {t(`Confirm`)}
                            </button>
                            <button
                              onClick={() => setClassNameChange(false)}
                              className="focus popup__bottom__btn"
                            >
                              {/* 취소 */}
                              {t(`Cancel`)}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* popup */}
      {addProjectPopup && (
        <div className="popup">
          <div className="modal-overlay" onClick={(e: any) => handleClickOutsidePopup(e)}></div>
          <div ref={popupRef} className="project_add_popup scroll">
            <p className="font-23 text-center font-bold mb-20">
              {/* 과제 일괄 생성 */}
              {t(`Batch Creation Assignments`)}
            </p>

            <p className="color-red font-12 mb-10">
              {/* 과제 일괄 생성은 생성 후에 한꺼번에 수정할 수 없습니다 */}*{" "}
              {t(`Batch Creation Assignments cannot be modified all at once after creation`)}
              <br />
              {/* 생성 후에는 각 학급에 입장하여 수정해 주세요. */}
              {t(`After you create it, please select each class and modify it`)}
            </p>

            <p className="color-light-black mb-8 font-15 font-600">{t(`Assignment title`)}</p>
            <div className="display-f align-center mb-22">
              <input
                value={projectNameInput}
                onChange={(e: any) => setProjectNameInput(e.target.value)}
                className="popup_input mr-18 pl-12 pr-12"
                type="text"
                maxLength={31}
              />
            </div>

            {/* <p className="color-light-black mb-8 font-15">분류</p> */}
            {/* {tags?.filter((item: any, i: number) => item.tagName).length === 0 && (
                <p style={{ fontSize: 11, color: "#979797" }}>
                  아직 등록된 분류 기준이 없습니다. 분류 기준을 추가해 주세요!
                </p>
              )} */}

            {/* <div style={{ width: "65%", marginBottom: 20 }}>
                {tags
                  .filter((item: any, i: number) => item.tagName)
                  .map((tag: any, index: number) => (
                    <div
                      key={index}
                      className={`modal_in_tag ${index % 5 !== 4 && "mr-10"} ${
                        index > 4 && "mt-5"
                      }`}
                      style={{ display: "inline-block" }}
                    >
                      <button
                        onClick={() => handleSelectTag("add", tag, index, "")}
                        style={{ width: "100%" }}
                        className={`modal_tag_btn ${tag.tagSelected && "tag_btn_selected"}`}
                      >
                        {tag.tagName}
                      </button>
                    </div>
                  ))}
              </div> */}

            {/* 반복과제 */}
            <div style={{ marginBottom: 20 }} className="position-r">
              <div className="display-f">
                <input
                  onChange={() => setRepeatCheck((prev: boolean) => !prev)}
                  // checked={repeatCheck}
                  type="checkbox"
                  id={JSON.stringify("repeat")}
                  className="display-n"
                />
                <div>
                  <label
                    className="target_label2 cursor"
                    htmlFor={JSON.stringify("repeat")}
                    style={{ marginRight: 5 }}
                  >
                    {repeatCheck && <span className="studentList_check"></span>}
                  </label>
                </div>
                <p
                  onClick={() => setRepeatCheck((prev: boolean) => !prev)}
                  className="color-light-black font-15 mb-8 cursor"
                >
                  {/* 반복과제 설정 */}
                  {t(`Repeat`)}
                </p>
              </div>

              <button
                onClick={() => {
                  setRepeatCheck(true);
                  setRepeatProjectType("횟수형");
                }}
                className="repeat-type-btn"
                style={{
                  marginLeft: 25,
                  marginRight: 10,
                  backgroundColor: repeatProjectType === "횟수형" ? "#FFD37E" : "",
                  color: repeatProjectType === "횟수형" ? "#000" : "#979797",
                }}
              >
                {/* 횟수형 */}
                {t(`By Number of times`)}
              </button>
              <button
                onClick={() => {
                  // return alert("서비스 준비중입니다.");
                  setRepeatCheck(true);
                  setRepeatProjectType("날짜형");
                }}
                className="repeat-type-btn"
                style={{
                  backgroundColor: repeatProjectType === "날짜형" ? "#FFD37E" : "",
                  color: repeatProjectType === "날짜형" ? "#000" : "#979797",
                }}
              >
                {/* 날짜형 */}
                {t(`By Date Selection`)}
              </button>

              {repeatProjectType === "횟수형" && (
                <>
                  <div
                    style={{ marginLeft: 25, alignItems: "center", marginTop: 10 }}
                    className="display-f"
                  >
                    <div
                      onClick={() => setRepeatSelectOpenC((prev: boolean) => !prev)}
                      className="repeat-selectbar cursor"
                    >
                      <p>{selectRepeatCount}</p>
                      <img src={down_b} style={{ width: 18, height: "auto" }} />
                    </div>
                    {/* ~ 회 */}
                    <p>{t(`Times`)}</p>
                  </div>

                  {repeatSelectOpenC && (
                    <div className="repeat-selectbar-open">
                      {getCount().map((numberItem: any, i: number) => (
                        <div
                          onClick={() => {
                            setSelectRepeatCount(numberItem);
                            setRepeatSelectOpenC(false);
                          }}
                          className="repeat-select-item-n cursor"
                          style={{
                            fontWeight: numberItem === selectRepeatCount ? "bold" : "normal",
                          }}
                        >
                          {numberItem}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}

              {repeatProjectType === "날짜형" && (
                <>
                  <div style={{ marginTop: 10, marginLeft: 25 }}>
                    <CalendarC
                      selectDates={dates}
                      setSelectDates={setDates}
                      handleSelectDate={handleSelectDate}
                    />
                  </div>
                </>
              )}
            </div>
            {/* 반복과제 */}

            <p className="color-light-black font-15 mb-8 font-600">
              {/* 과제설명 */}
              {t(`Instructions`)}
            </p>
            <ReactQuill
              value={text}
              onChange={(e) => handleChageText(e)}
              className="project-quill"
              style={{ marginBottom: 50 }}
              modules={quillStyle}
              formats={formatStyle}
            />

            <div>
              <Dropzone ref={dropRef} onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {!isDragActive ? (
                      <>
                        <div className="main-popup-draggable">
                          {/* 이 곳에 파일을 추가해 주세요. */}+ {t(`Please add the file here`)}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="main-popup-draggable color-gray">
                          {/* 이 곳에 파일을 추가해 주세요. */}+ {t(`Please add the file here`)}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </Dropzone>

              {/* <div className="main-file-wrapper">
                {files.map((aFile: any, i: number) => (
                  <div key={i} className="mt-10" style={{ width: "50%", paddingRight: 10 }}>
                    <img src={aFile.url} style={{ width: "100%", height: "auto" }} />
                  </div>
                ))}
              </div> */}

              <div className="mt-10">
                {files.map((data: IfileInfo, index: number) => (
                  <>
                    {data.fileType === "image" && (
                      <div
                        className="img-add-wrapper"
                        key={index}
                        style={{ marginRight: index % 3 === 2 ? 0 : 10, display: "inline-block" }}
                      >
                        <img
                          onClick={() => handleDeleteImg(data, index)}
                          src={close}
                          style={{ width: 17, height: 17 }}
                          className="img-delete"
                        />

                        <img src={data.fileUrl} className="img-add mb-10" alt="file" />
                        {/* <p className="txt_line" style={{ fontSize: 13 }}>
                          {data.fileName}
                        </p> */}
                      </div>
                    )}

                    {data.fileType !== "image" && (
                      <div key={index} className="mt-10 display-f align-center">
                        <img
                          src={close}
                          onClick={() => handleDeleteImg(data, index)}
                          style={{ width: 10, height: 10 }}
                          className="cursor mr-5"
                        />
                        <img
                          src={file_icon}
                          style={{ width: 30, height: "auto", marginRight: 3 }}
                          alt="file"
                        />
                        <p style={{ fontSize: 13 }}>{data.fileName}</p>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>

            <div className="text-center">
              <button
                onClick={() => setStudentsList(true)}
                className={`${
                  countStudentSelected() === 0 ? "main-border-red" : "main-border-yellow"
                } focus main-target-student-btn font-13 font-500 mt-10 color-black`}
              >
                {/* {`대상 학급/학생 선택(${countStudentSelected()}명)`} */}
                {/*  TODO:: 고유번역 */}
                {`${t(`Select Recipient Class/Student`)}(${countStudentSelected()}${
                  localStorage.getItem("language") === "ko" ? "명" : ""
                })`}
              </button>
            </div>

            {studentsList && (
              <div className="studentsList__popup">
                <div className="studentsList__contents">
                  <div className="overflow-h scroll mb-30" style={{ padding: "0 20px" }}>
                    <p className="font-23 mb-26 text-center font-bold mt-20">
                      {/* 대상 학급/학생 지정 */}
                      {t(`Select Recipient Class/Student`)}
                    </p>

                    <div className="display-f align-center">
                      <div
                        onClick={() => handleCheckPackage("all")}
                        className="mr-6 main-check-wrapper"
                      >
                        {allCheck.all && <div className="main-check-checked"></div>}
                      </div>
                      {/* <input type="checkbox" style={{ marginRight: 6 }} /> */}
                      <p className="cursor" onClick={() => handleCheckPackage("all")}>
                        {/* 전체 선택 및 해제 */}
                        {t(`Select & Deselect All`)}({countStudentSelected()}/
                        {originalStudents.length}
                        {/*  TODO:: 고유번역 */}
                        {localStorage.getItem("language") === "ko" ? "명" : ""})
                      </p>
                    </div>

                    <div className="display-f align-center mt-10" style={{ height: "70%" }}>
                      <div className="main-select-students-header-wrapper">
                        <div className="main-select-class-header">
                          {/* 학급 선택 */}
                          {t(`Select Class`)}
                        </div>

                        <div className="main-select-class-content">
                          {classList.map((aClass: any, i: number) => (
                            <div key={i} className="display-f align-center mt-10">
                              <div
                                onClick={() => handleCheckClass(aClass._id)}
                                className="mr-6 main-check-wrapper"
                              >
                                {aClass.checked && <div className="main-check-checked" />}
                              </div>
                              <p
                                onClick={() => handleClassSelect(aClass._id)}
                                className={`cursor 
                                ${aClass.backgroundYellow}
                                `}
                                style={{
                                  fontWeight: 400,
                                }}
                              >
                                {aClass.title} ({handleCountCheckedPerClass(aClass._id)}/
                                {aClass.students.length}
                                {/*  TODO:: 고유번역 */}
                                {localStorage.getItem("language") === "ko" ? "명" : ""})
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="main-student-list-wrapper">
                        <div className="main-student-contents-wrapper">
                          <div
                            className="display-f align-center"
                            style={{
                              width: "100%",
                              borderBottom: "1px solid lightgray",
                              paddingRight: 6,
                            }}
                          >
                            <input
                              value={studentSearch}
                              onChange={(e: any) => handleSearchStudents(e.target.value)}
                              // placeholder="학생 검색"
                              placeholder={t(`Search for a Student`)}
                              className="studnet-search-input"
                            />
                            <img src={search} style={{ width: 16, height: "auto" }} />
                          </div>
                        </div>

                        <div
                          className="display-f align-center justify-end"
                          style={{ padding: "10px 10px 0 10px" }}
                        >
                          <div
                            onClick={() => handleCheckPackage("students")}
                            className="mr-6 main-check-wrapper"
                          >
                            {allCheck.students && <div className="main-check-checked"></div>}
                          </div>
                          <p className="cursor" onClick={() => handleCheckPackage("students")}>
                            {/* 전체 */}
                            {t(`All`)}
                          </p>
                        </div>

                        <div
                          className={`student_list_grid_container main-students-contents
                          `}
                          style={{ paddingTop: 10 }}
                        >
                          {students?.map((aStudent: any, i: number) => (
                            <>
                              {aStudent.show && (
                                <div
                                  onClick={() => {
                                    handleCheckStudent(aStudent);
                                    // handleClickStudent(aStudent, i);
                                    handleCheckBoxDetect();
                                  }}
                                  key={i}
                                  className="student_list_item"
                                  style={{
                                    backgroundColor: aStudent.checked ? "#ffd37e" : "#ececec",
                                  }}
                                >
                                  <div style={{ textAlign: "center" }}>
                                    <p style={{ fontWeight: 500 }} className="txt_line2">
                                      {aStudent.num}
                                      {aStudent.num ? "." : ""} {aStudent.name}
                                    </p>
                                    <p style={{ fontSize: 12 }}>{aStudent.title}</p>
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                          {/* 
                          {targetClass.targetIdx === -1 &&
                            searchStudents.map((aStudent: any, i: number) => (
                              <>
                                {aStudent.show && (
                                  <div
                                    onClick={() => {
                                      // handleClickStudent(aStudent, i);
                                      // handleCheckBoxDetect();
                                    }}
                                    key={i}
                                    className="student_list_item"
                                    style={{
                                      backgroundColor: aStudent.checked ? "#ffd37e" : "#ececec",
                                    }}
                                  >
                                    <div style={{ textAlign: "center" }}>
                                      <p style={{ fontWeight: 500 }} className="txt_line2">
                                        {aStudent.num}
                                        {aStudent.num ? "." : ""} {aStudent.name}
                                      </p>
                                      <p style={{ fontSize: 12 }}>{aStudent.title}</p>
                                    </div>
                                  </div>
                                )}
                              </>
                            ))} */}
                        </div>
                      </div>
                    </div>

                    <div
                      className="mt-10 align-center"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 10px",
                      }}
                    >
                      <p className="text-red font-12 font-600">
                        {/* 숨긴 학급은 뜨지 않습니다. */}*
                        {t(`Classes that you hide are not shown`)}
                      </p>

                      <div className="display-f align-center">
                        <p className="mr-20 font-600">
                          {/* 총 수신 대상 */}
                          {t(`Total number of recipients`)}
                        </p>
                        <p className="color-blue" style={{ fontSize: 20 }}>
                          <span className=" font-600">{countStudentSelected()}</span>{" "}
                          <span className="black">
                            {/*  TODO:: 고유번역 */}
                            {localStorage.getItem("language") === "ko" ? "명" : ""}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div>
                      <div className="text-center mt-20 mb-20 pb-20 text-center">
                        <button
                          onClick={() => setStudentsList(false)}
                          className="focus main-student-check-btn"
                        >
                          {/* 확인 */}
                          {t(`Confirm`)}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="text-center mb-20 mt-20">
              <button
                style={{ padding: "0 20px" }}
                className="focus popup__bottom__btn2 background-yellow border-none font-500 mr-14"
                onClick={handleSubmitProject}
              >
                {/* {loading ? "과제 생성중..." : "과제 생성"} */}
                {loading ? t(`Creating Assignment`) : t(`Create Assignment`)}
              </button>
              <button
                onClick={addProjectPopupReset}
                // onClick={() => {
                //   setPopup(false);
                //   setRepeatProjectType("");
                //   setRepeatCheck(false);
                //   setDates([]);
                // }}
                className="focus popup__bottom__btn  "
              >
                {/* 취소 */}
                {t(`Cancel`)}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Main;
